import api from "@rosfines/vue-common/common/api/osagoApi";
import swalHelper from "@/util/helpers/swal.helper";
import {statService} from "@/services/statService";

const notifications = {
  namespaced: true,
  state () {
    return {
      settings: {},
      notifications: {},
      disabled: false,
      showError: false,
      isNotificationsLoading: false,
    }
  },
  getters: {
    getSettings(state) {
      return state.settings;
    },
    getNotifications(state) {
      return state.notifications;
    },
    getDisabledStatus(state) {
      return state.disabled;
    },
    getShowErrorStatus(state) {
      return state.showError;
    },
    getIsAllNotificationsShows(state) {
      let allNotificationsOn;
      state.notifications.notifications.insuranceOsago.forEach(el => {
        el.data.forEach(data => {
          if (data.category === 'all-notifications') {
            allNotificationsOn = data.value;
          }
        })
      })
      return allNotificationsOn;
    },
    getAllNotificationsObj(state) {
      let allNotificationsObj;
      state.notifications.notifications.insuranceOsago.forEach(el => {
        allNotificationsObj = el.data.find(data => data.category === 'all-notifications');
      })
      return allNotificationsObj;
    }
  },
  mutations: {
    setSettingsData(state, value) {
      state.settings = value;
    },
    drawNotifications(state) {
      state.notifications = JSON.parse(JSON.stringify(state.settings));
      // на бэке есть сложный прикол, суть которого в том, что настройка в true отключает получение уведомлений
      // это значит, что настройка инвертирована описанию и здравому смыслу, поэтому value нужно также инвертировать
      state.notifications.notifications.common.forEach((commonItem) => {
        commonItem.data.forEach((item) => {
          item.value = !item.value;
        });
      });
      state.notifications.notifications.cars.forEach((carsItem) => {
        carsItem.settings.forEach((settingsItem) => {
          settingsItem.data.forEach((item) => {
            item.value = !item.value;
          });
        });
      });
      state.notifications.notifications.insuranceOsago.forEach((insuranceOsagoItem) => {
        insuranceOsagoItem.data.forEach((item) => {
          item.value = !item.value;
        });
      });
    },
    setDisabled(state, value) {
      state.disabled = value;
    },
    setShowError(state, value) {
      state.showError = value;
    },
    setNotificationsLoading(state, value) {
      state.isNotificationsLoading = value;
    }
  },
  actions: {
    async loadNotificationsStatus({ commit }) {
      commit('setNotificationsLoading', true);
      const response = await api.get("/notifications").catch((e) => {
        swalHelper.showResponseError(e);
      });
      if (response && api.isSuccess(response)) {
        commit("setSettingsData", response.data);
        commit("drawNotifications");

        commit("setShowError", false);
      } else {
        commit("setShowError", true);
        swalHelper.showResponseError();
      }
      commit('setNotificationsLoading', false);
    },
    async saveNotificationsSettings({ commit }, data) {
      commit("setDisabled", true);
      const response = await api.patch("/notifications", data.data).catch((e) => {
        swalHelper.showResponseError(e);
      });
      if (api.isSuccess(response)) {
        commit("setSettingsData", response.data);
        if (data.data.value === true && data.data.key === 'disable-all-insurance-osago-notifications') {
          statService.logEvent(`[Ситуация] (Фронт) Юзер отключил все уведомления по ОСАГО ${data.place}`);
        } else if (data.data.key === 'disable-all-insurance-osago-notifications') {
          statService.logEvent(`[Ситуация] (Фронт) Юзер включил все уведомления по ОСАГО ${data.place}`);
        }
      } else {
        commit("drawNotifications");
      }
      commit("setDisabled", false);
    },
  }
}

export default notifications;
