import keyLayoutRu from 'convert-layout/ru';

export const FIELD_TYPES = new Set([
    'text',
    'password',
    'email',
    'number',
    'url',
    'tel',
    'search',
    'range',
    'color',
    'date',
    'time',
    'datetime',
    'datetime-local',
    'month',
    'week'
]);

export default {
    emits: [
      "onModelValueChange",
    ],
    props: {
        value: {
            //required: true
        },
        type: {
            type: String,
            default: 'text',
            validator: type => FIELD_TYPES.has(type)
        },
        id: String,
        name: String,
        label: String,
        placeholder: String,
        rules: {
            default: null
        },
        mask: {
            default: null
        },
        mode: {
            type: String,
            default: 'eager'
        },
        inputClass: {
            type: String,
            default: ''
        },
        maxlength: {
            type: String,
            default: ''
        },
        readonly: Boolean,
        disabled: Boolean,
        uppercase: {
            default: null
        },
        keyTo: {
            default: null
        },
        textVariant: String,
        backgroundVariant: String,
        modal: {
            type: String,
            default: null
        },
    },

    watch: {
        modelValue(val) {
          this.$emit("onModelValueChange", val);
        },
    },

    methods: {
        keyToLang(value) {
            try {
                if (this.keyTo === 'ru') {
                    return keyLayoutRu.fromEn(value);
                } else if (this.keyTo === 'en') {
                    return keyLayoutRu.toEn(value);
                }
                return value;
            } catch (e) {
                return value;
            }
        }
    },
};
