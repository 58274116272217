import config from "@/config";
import {statService} from "@/services/statService";

export const getV3Token = () => {
  return new Promise((resolve, reject) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(config.RECAPTCHA_V3_SITE_KEY, {action: 'submit'}).then((token) => {
          resolve(token);
        }).catch(() => {
          let eventData = {};
          statService.setStatServiceDataParam({query: {}}, eventData);
          statService.logEvent('[Ситуация] Ошибка recaptcha v3', eventData);
          resolve("recaptcha_v3_failed");
        });
      });
    } else {
      reject();
    }
  });
}
export const getV2Token = async () => {
  let resolve, reject;
  let recaptchaPromise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  const script = document.createElement( "script" );
  script.src = "https://www.google.com/recaptcha/api.js";
  document.head.appendChild( script );
  if (window.grecaptcha) {
    window.grecaptcha.ready(async () => {
      const wrapper = document.createElement("div")
      wrapper.id = "recaptcha-wrapper";
      document.getElementById("recaptcha-wrapper").replaceWith(wrapper);
      window.grecaptcha.render(
        "recaptcha-wrapper",
        {
          sitekey: config.RECAPTCHA_V2_SITE_KEY,
          theme: "light",
          callback: (token) => {
            window.$hideModal("modal-recaptcha");
            statService.logEvent('[Ситауция] RecaptchaV2 решена', {});
            resolve(token);
          },
          'expired-callback': () => {
            window.$hideModal("modal-recaptcha");
            statService.logEvent('[Ситауция] RecaptchaV2 протухла', {});
            reject();
          },
          'error-callback': () => {
            window.$hideModal("modal-recaptcha");
            statService.logEvent('[Ситауция] RecaptchaV2 не решена', {});
            reject();
          },
        }
      );

      window.$showModal("modal-recaptcha");

      statService.logEvent('[Ситауция] RecaptchaV2 показана', {});
    });
  } else {
    reject();
  }
  return recaptchaPromise;
}
