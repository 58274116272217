<template>

  <section
    :data-field="id"
    :class="{
      'loading': loading,
      'readonly': readonly
    }"
  >
    <Field
      ref="validationField"
      v-slot="{errorMessage}"
      :name="id"
      :rules="rules"
      :value="modelValue"
    >
      <div class="fly-label">

        <input
          :id="id"
          ref="input"
          :name="name || id"
          :placeholder="placeholder"
          :aria-label="label"
          :value="displayVal"
          class="form-control form-control-lg textfield-shifted"
          :class="{
            ...classes,
            'textfield-shifted': inputShifted,
            'textfield-hidden': !inputShifted,
            failed: !!errorMessage,
          }"
          autocomplete="off"
        >

        <select
          ref="select"
          v-model="optionId"
          @click="onClick"
        >
          <option
            v-for="mapOption in mapOptions"
            :key="mapOption.id"
            :value="mapOption.id"
          >
            {{ mapOption.name }}
          </option>
        </select>

        <i
          class="chevron"
        />

        <label
          :for="id"
          class="required placeholder-label"
          :class="{'placeholder-shifted': inputShifted}"
        >
          {{ label }}
        </label>
      </div>
      <div v-if="serverValidation" class="invalid-feedback--vue js_invalid_feedback">{{ serverValidation && serverValidation[0] || serverValidation }}</div>
      <ErrorMessage
        v-else-if="!!errorMessage"
        as="div"
        :name="id"
        class="invalid-feedback--vue js_invalid_feedback"
      />
    </Field>
  </section>
</template>

<script>
import appField from "@/mixins/appField";
import {statService} from "@/services/statService";
import {Field, ErrorMessage, useField} from "vee-validate";


export default {
  components: {
    Field,
    ErrorMessage,
  },
  mixins: [
    appField,
  ],

  props: {
    mapOptions: {
      type: Object,
      required: true
    },
    serverValidation: {
      type: Array,
      default: null,
    },
    classes: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    autoLoadOldVal: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'hit'
  ],

  data() {
    return {
      optionId: null,
      errorMessage: null,
      isFocused: false,
      displayVal: "",
    };
  },

  computed: {
    inputShifted() {
      return (!this.readonly && this.isFocused) || this.displayVal || this.placeholder;
    },
  },

  watch: {
    optionId(newVal, oldVal) {
      if (oldVal !== null || !this.modelValue) {
        if (typeof newVal !== "undefined" && newVal !== null) {
          // this.$emit('update:modelValue', newVal);
          const filteredItems = Object.values(this.mapOptions).filter((item) => item.id === newVal)
          if (filteredItems && filteredItems[0]) {
            const item = filteredItems[0];
            this.displayVal = item.name;
            this.$emit('hit', item);
          } else {
            this.displayVal = "";
            let eventData = {};
            statService.setStatServiceDataParam({ query: {} }, eventData);
            statService.logEvent(`[Действие] Выбрано несуществующее значение в dropdown select ${this.label}`, eventData);
          }
        } else {
          this.displayVal = "";
        }
        let eventData = {};
        statService.setStatServiceDataParam({ query: {} }, eventData);
        statService.logEvent(`[Действие] Выбрано значение в dropdown select ${this.label}`, eventData);
      }
    },
    mapOptions(newVal) {
      if(Object.keys(newVal).length === 0) {
        this.optionId = null;
      } else if (this.modelValue) {
        const filteredItems = Object.values(newVal).filter((item) => item.name === this.modelValue);
        if (filteredItems && filteredItems[0]) {
          const item = filteredItems[0];
          this.optionId = item.id;
        }
      }
    },
    errorMessage(newVal) {
      if (newVal) {
        let eventData = {
          fieldValue: this.$refs.input.value,
        };
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent(`[Ситуация] Показана ошибка валидации. Поле [${this.id}]`, eventData);
      }
    },
    displayVal(newVal) {
      this.$emit('update:modelValue', newVal);
    }
  },

  mounted() {
    this.errorMessage = useField(this.id).errorMessage;
    this.displayVal = this.modelValue;
    this.$refs.select.addEventListener("extenal-change", this.externalSelectChange, false);
    if (this.autoLoadOldVal) {
      this.optionId = this.modelValue;
    }
  },

  methods: {
    externalSelectChange(e) {
      this.optionId = e.detail;
    },
    onClick() {
      let eventData = {};
      statService.setStatServiceDataParam({query: {}}, eventData);
      statService.logEvent(`[Действие] Кликнул в dropdown select ${this.label}`, eventData);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

select {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
  min-height: 100%;
  cursor: pointer;
}
.chevron {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 14px;
  right: 16px;
  background: url(@/assets/img/icons/chevron.svg) no-repeat;
  .loading & {
    background: url(@/assets/img/loader-main.svg) center center / contain no-repeat;
  }
}
.readonly {
  opacity: 0.4;
}
</style>
