import * as VueRouter from 'vue-router'

import {
  clearModalBackground,
} from "@/util/common.helper";
import {store} from '@/store';
import urlHelper from "@/util/helpers/url.helper";

import Start from '@/pages/funnel/Start';
import ChooseCar from "@/pages/funnel/ChooseCar";
import swalHelper from '@/util/helpers/swal.helper';
import { statService } from '@/services/statService';
import {FORM_STEPS_ORDER, FORM_STEPS_ORDER_LEGAL_ENTITY, PAGES_NAMES} from '@/constants/flowMap';

const EntryPage = () => import('./pages/EntryPage');

const CarData = () => import('@/pages/funnel/CarData');
const Drivers = () => import('@/pages/funnel/Drivers');
const Owner = () => import('@/pages/funnel/Owner');

const PaymentRedirect = () => import('@/pages/PaymentRedirect');
const CalculationsABTest = () => import('@/pages/funnel/CalculationsABTest');
const Offers = () => import('@/pages/funnel/Offers');
const Policy = () => import("@/pages/Policy");
const AdditionalServices = () => import("@/pages/funnel/AdditionalServices");
const AdditionalFunnel = () => import("@/pages/additionalFunnel/AdditionalFunnel");

const CloseApp = () => import('@/pages/CloseApp');

const Notifications = () => import('@/pages/notifications/Notifications');

const IncorrectData = () => import('@/pages/incorrectData/IncorrectData');
const AddPolicy = () => import('@/pages/incorrectData/AddPolicy');

const RemoveCar = () => import('@/pages/carStatus/RemoveCar');

const fixServerError = () => import('@/pages/service/FixServerError');

const startMeta = {accessWithoutAuth: true};

const SharedPolicy = () => import("@/pages/SharedPolicy");

const Landing = {
  render: () => {
  },
  async created() {
    location.href = "/"
  }
};
const getStartComponent = {
  render: () => {},
  async created() {
    // Проверяем также, не было ли перехода из виджета stories (парсинг url)
    let queryParams = urlHelper.getQueryParams();

    if (store.getters["form/hasActiveCar"]) {
      delete queryParams.vp;
      await this.$router.replace({
        name: "formContinue",
        query: queryParams
      });
      return;
    } else if (store.getters["cars/getCarsList"].length > 0) {
      await this.$router.replace({
        name: "chooseCar",
        query: queryParams
      });
      return;
    } else {
      await this.$router.replace({
        name: "startNumber",
        query: queryParams
      });
      return;
    }
  }
};

const getFirstNotConfirmedStep = {
  render: () => {},
  async created() {
    store.commit("setCommonLoading", true);
    const confirmedSteps = store.getters["form/confirmedSteps"] || false;
    const isCorp = store.getters["form/getActiveCarData"].isCorp;
    const currentStepsOrder = isCorp ? FORM_STEPS_ORDER_LEGAL_ENTITY : FORM_STEPS_ORDER;
    if (confirmedSteps) {
      const firstNotFilledStep = currentStepsOrder.find(step => !store.getters['form/stepConfirmed'](step));
      if (confirmedSteps && Object.values(confirmedSteps).filter((item) => !item).length === 0) {
        // расчеты
        await this.$router.replace({
          name: PAGES_NAMES.CALCULATIONS
        });
      } else if (firstNotFilledStep) {
        await this.$router.replace({
          name: firstNotFilledStep
        });
      } else {
        await this.$router.replace({
          name: "start"
        });
      }
    } else {
      await this.$router.replace({
        name: "start"
      });
    }
  }
};
const getNextFormStep = (to) => {
  if (to && to.params && to.params.backParam){
    return {
      name: to.params.backParam
    }
  }
  const policyholder = store.getters["form/getActiveCarData"].policyholder;
  const formStepsOrder = policyholder.isCorp
    ? FORM_STEPS_ORDER_LEGAL_ENTITY
    : FORM_STEPS_ORDER;
  const index = formStepsOrder.indexOf(router.currentRoute.value.name);
  if (formStepsOrder[index] === 'owner' && !policyholder.isBusinessman) {
    return {
      name: PAGES_NAMES.CALCULATIONS
    }
  }
  if(index === -1) {
    return {
      name: formStepsOrder[0]
    };
  } else if (index === formStepsOrder.length - 1) {
    return {
      name: PAGES_NAMES.CALCULATIONS
    };
  } else {
    return {
      name: formStepsOrder[index + 1]
    };
  }
};

const router = VueRouter.createRouter({
  mode: 'history',
  history: VueRouter.createWebHistory(),
  base: '/',
  routes: [
    {path: '/entry', component: EntryPage, name: 'entry'},

    {path: '/form-continue', component: getFirstNotConfirmedStep, name: 'formContinue'},
    {path: '/form-next', redirect: getNextFormStep, name: 'formNext'},
    {path: '/fix-server-error', component: fixServerError, name: 'fixServerError'},

    {path: '/start', component: getStartComponent, name: 'start', meta: {...startMeta, title: "Оформить ОСАГО"}},
    {path: '/choose-car', component: ChooseCar, name: 'chooseCar', meta: {...startMeta, title: "Оформить ОСАГО"}},
    {path: '/start-number', component: Start, name: 'startNumber', meta: {...startMeta, title: "Оформить ОСАГО"}},
    {path: '/landing', component: Landing, name: 'landing'},
    {path: '/car-data', component: CarData, name: 'carData'},
    {path: '/drivers', component: Drivers, name: 'drivers'},
    {path: '/owner', component: Owner, name: 'owner'},
    {path: '/additional-services', component: AdditionalServices, name: PAGES_NAMES.ADDITIONAL_SERVICES},
    {path: '/additional-funnel', component: AdditionalFunnel, name: PAGES_NAMES.ADDITIONAL_FUNNEL},

    {path: '/calculations', component: CalculationsABTest, name: PAGES_NAMES.CALCULATIONS},
    {path: '/offers', component: Offers, name: PAGES_NAMES.OFFERS},

    {path: '/policy', component: Policy, name: 'policy'},

    {path: '/pay', component: PaymentRedirect, name: 'paymentRedirect'},

    {path: '/incorrect-data', component: IncorrectData, name: 'incorrectData'},
    {path: '/add-policy', component: AddPolicy, name: 'addPolicy'},

    {path: '/remove-car', component: RemoveCar, name: 'removeCar'},

    {path: '/close-android-app', component: CloseApp, name: 'closeAndroidApp'},
    {path: '/close-ios-app', component: CloseApp, name: 'closeIosApp'},

    {path: '/notifications', component: Notifications, name: 'notifications'},
    {path: '/shared-policy', component: SharedPolicy, name: 'SharedPolicy'},

    {path: '/sold-car/sold', component: () => import("@/pages/soldCar/SoldCar"), name: 'soldCar'},
    {path: '/sold-car/confirmed', component: () => import("@/pages/soldCar/SellConfirmed"), name: 'sellConfirmed'},
    {path: '/sold-car/didnt-sell', component: () => import("@/pages/soldCar/DidntSell.vue"), name: 'didntSell'},
    {path: '/add-car-to-user', component: () => import("@/pages/AddCarToUser"), name: 'addCarToUser'},

    // redirects for old flow
    {path: '/step0', redirect: '/start'},
    {path: '/step1', redirect: 'carData'},
    {path: '/step2', redirect: 'drivers'},
    {path: '/step3', redirect: 'owner'},
    {path: '/agent/start', redirect: 'start-number'},
    {path: '/step5', redirect: PAGES_NAMES.CALCULATIONS},
    {path: '/redirect', redirect: PAGES_NAMES.CALCULATIONS},
    {path: '/notifications/disable', redirect: 'notifications'},
    {path: '/notifications/enable', redirect: 'notifications'},
    {path: '/offer', redirect: PAGES_NAMES.OFFERS},
    // /redirects for old flow

    {path: '/:pathMatch(.*)*', redirect: 'start'}
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("form/waitLoadingCarData");
  clearModalBackground();
  window.scrollTo(0,0);
  document.body.setAttribute("data-osago-page", to.name);
  // // TODO: store.getters['form/carStatus'] !== 2 заменить на ... === 1
  // if (!store.state.form.activeCarId || store.getters['form/carStatus'] !== 2 || ["landing", "chooseCar", "start", "startNumber"].indexOf(to.name) !== -1 ) {
  next();
  // } else {
  //   next({ name: 'chooseCar' });
  // }
});

router.afterEach(() => {
  store.commit("setCommonLoading", false);
});

router.onError(async () => {
  statService.logEvent(`[Ситуация] Ошибка загрузки чанка`);
  await swalHelper.showErrorBox();
  location.reload();
});

router.hasBackParam = () => {
    return !!router.getBackParam();
};
router.getBackParam = () => {
    const query = router.currentRoute.value.query;
    return query.backParam;
};

router.formStepsOrder = FORM_STEPS_ORDER;
router.formStepsOrderLegalEntity = FORM_STEPS_ORDER_LEGAL_ENTITY;

export default router;
