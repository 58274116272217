<template>
  <div
    class="warning-block"
    :class="{
      'warning-block_no-margin': noMargin,
    }"
  >
    <div class="warning-block__ico">
      <img
        v-if="ico === 'question'"
        src="@/assets/img/icons/question-white.svg"
        alt=""
      >
      <img
        v-else
        src="@/assets/img/icons/warning-white.svg"
        alt=""
      >
    </div>
    <div class="warning-block__text">
      <div
        v-if="title"
        class="warning-block__title"
      >
        {{ title }}
      </div>
      <div
        class="warning-block__desc"
        v-html="desc"
      />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "WarningBlock",
  props: {
    ico: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    desc: {
      type: String,
      required: true,
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";
.warning-block {
  border-radius: 8px;
  background: #FFF9EE;
  display: flex;
  padding: 12px;
  margin: 0 0 16px;
  &_no-margin {
    margin: 0;
  }
  &__ico {
    margin: 0 8px 0 0;
    img {
      width: 32px;
      height: 32px;
    }
  }
  &__title {
    color: #FFBA2B;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.24px;
    margin: 0 0 4px;
  }
  &__desc {
    color: $color-gray;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.08px;
  }
}
</style>
