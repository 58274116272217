export default {
  mask: [
    {
      mask: "a000aa00R",
      definitions: {
        'a': /[етуоранкхсвмЕТУОРАНКХСВМ]/,
        'R': /^\d?$/
      },
    },
    {
      mask: "0000aa00R",
      definitions: {
        'a': /[етуоранкхсвмЕТУОРАНКХСВМ]/,
        'R': /^\d?$/
      },
    },
  ],
}
