<template>
  <app-modal
    id="modal-add-vehicle-plate"
    :title="modalTexts.title"
    bottom-button-title="Сохранить"
  >
    <template #fullCustom>
      <VeeForm
        ref="addPlateValidationObserver"
        v-slot="{handleSubmit}"
        class="popup-content-block content-slot"
      >
        <form
          id="addPlateForm"
          @submit="handleSubmit($event, addPlate)"
        >
          <p>
            {{ modalTexts.desc }}
          </p>
          <div class="container px-0">
            <div class="row">
              <div class="col-12 col-lg-5">
                <app-input
                  v-if="this.isEditingMoto"
                  id="addVehiclePlate"
                  ref="addVehiclePlate"
                  label="Госномер"
                  rules="required|vehiclePlateLength|vehiclePlateMoto"
                  mask="vehiclePlateMoto"
                  uppercase="true"
                />
                <app-input
                  v-else
                  id="addVehiclePlate"
                  ref="addVehiclePlate"
                  label="Госномер"
                  rules="required|vehiclePlateLength|vehiclePlate"
                  mask="vehiclePlate"
                  uppercase="true"
                />
                <div
                  class="inform_text_notice"
                >
                  <div class="el__content">
                    <p
                      class="el_text"
                      style="margin-bottom: 0"
                    >
                      {{ modalTexts.inform }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </VeeForm>
      <div class="popup-control-block">
        <div class="container px-0">
          <div class="row">
            <div class="col-lg-5">
              <button
                type="submit"
                class="c_btn_full btn-primary_new font-17 font-weight-semi"
                form="addPlateForm"
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
  <loading
    v-if="loading"
    :active="true"
  />
</template>

<script>
import AppModal from "@/components/modals/AppModal.vue";
import { Form as VeeForm } from "vee-validate";
import swalHelper from "@/util/helpers/swal.helper";
import {mapActions, mapGetters} from "vuex";
import Loading from "vue-loading-overlay";

export default {
  name: "AddPlateModal",
  components: {
    Loading,
    AppModal,
    VeeForm
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      getEditableCarId: "form/getEditableCarId",
      getEditableVehicleType: "form/getEditableVehicleType",
    }),
    isEditingMoto() {
      return this.getEditableVehicleType === 'moto'
    },
    modalTexts() {
      return {
        title: this.isEditingMoto ? 'Добавить номер для мото' : 'Добавить номер для авто',
        desc: this.isEditingMoto ? 'Если вы получили номер для вашего мотоцикла, то можете его добавить' : 'Если вы получили номер для вашего авто, то можете его добавить',
        inform: this.isEditingMoto ? 'Например, 0000АА00' : 'Например, А839АА177'
      }
    }
  },
  methods: {
    ...mapActions({
      addVehiclePlate: "cars/addVehiclePlate",
      setEditableCarId: "form/setEditableCarId",
      setEditableVehicleType: "form/setEditableVehicleType",
    }),
    async addPlate(e) {
      this.loading = true;
      const validate = await this.$refs.addPlateValidationObserver.validate();
      if (validate && validate.valid) {
        await this.addVehiclePlate({
            carId: this.getEditableCarId,
            vehiclePlate: e.addVehiclePlate.toUpperCase()
        }).catch((error) => {
          console.log('error', error);
          swalHelper.showResponseError(error);
        });
        await this.setEditableCarId(0);
        await this.setEditableVehicleType('car');
        this.$hideModal('modal-add-vehicle-plate');
      } else {
        console.log(validate.errors)
      }
      this.loading = false;
    },
  }
}
</script>
