import productsApi from "@rosfines/vue-common/common/api/productsApi";
import {ADDITIONAL_INSURANCE_DATA, ADDITIONAL_INSURANCE_PREMIUM} from "@/constants/additionalInsuranceData";
import osagoApi from "@rosfines/vue-common/common/api/osagoApi";


let resolveWaitLoadingUserProducts;
let rejectWaitLoadingUserProducts;
let loadingUserProducts;
(() => {
  loadingUserProducts = new Promise((resolve, reject) => {
    resolveWaitLoadingUserProducts = resolve;
    rejectWaitLoadingUserProducts = reject;
  });
})()


const products = {
  namespaced: true,
  state () {
    return {
      products: [],
      userProducts: [],
      isPremiumSupportSelected: true,
      loading: false,
      loaded: false,
    }
  },
  getters: {
    getByCode: (state) => (code, isStatic = false) => {
      const list = (isStatic) ? ADDITIONAL_INSURANCE_DATA : state.products;
      return list.find((item) => (item.code === code)) || [];
    },
    isActive: (state) => (code) => {
      return state.userProducts.includes(code);
    },
    getUserProducts(state) {
      return state.userProducts;
    }
  },
  mutations: {
    saveProducts(state, {list, premiumSupportFeature}) {
      if (premiumSupportFeature.isActive) {
        state.products = [...ADDITIONAL_INSURANCE_PREMIUM, ...list];
      } else {
        state.products = list;
      }
      // list.map((item) => {
      //   if (!state.products.find((product) => product.code === item.code)) {
      //     state.products.push(item);
      //   }
      // });
    },
    saveUserProducts(state, {list, premiumSupportFeature}) {
      if (state.isPremiumSupportSelected && premiumSupportFeature.isActive) {
        state.userProducts = ['premium-support', ...list];
      } else {
        state.userProducts = list;
      }
    },
    addUserProduct(state, productCode) {
      state.userProducts.push(productCode);
    },
    removeUserProduct(state, productCode) {
      state.userProducts.splice(state.userProducts.indexOf(productCode), 1);
    },
    setLoading(state, value) {
      state.loading = !!value;
    },
    setLoaded(state, value) {
      state.loaded = !!value;
    },
  },
  actions: {
    async waitLoadingUserProducts () {
      return loadingUserProducts;
    },
    async loadProducts({state, commit, rootGetters}) {
      if (state.loading || state.loaded) {
        return;
      }
      const response = await productsApi.get("/product/list").catch((e) => {
        console.error(e);
        throw new Error("products loading failed. code #1");
      });
      if (productsApi.isSuccess(response)) {
        const list = response.data.items;
        const premiumSupportFeature = rootGetters["GBFeatures/premiumSupportFeature"];
        commit("saveProducts", {list, premiumSupportFeature});
      } else {
        throw new Error("products loading failed. code #2");
      }
    },
    // async loadUserProducts({commit, dispatch, rootGetters}) {
    //   const response = await productsApi.get("/product-user/list").catch((e) => {
    //     console.error(e);
    //     rejectWaitLoadingUserProducts();
    //     throw new Error("user products loading failed. code #1");
    //   });
    //   if (productsApi.isSuccess(response)) {
    //     const list = response.data;
    //     const premiumSupportFeature = rootGetters["GBFeatures/premiumSupportFeature"];
    //
    //     if (premiumSupportFeature.isActive) {
    //       await dispatch('loadPremiumSupportStatus');
    //     }
    //     commit("saveUserProducts", {list, premiumSupportFeature});
    //     resolveWaitLoadingUserProducts()
    //   } else {
    //     rejectWaitLoadingUserProducts();
    //     throw new Error("user products loading failed. code #2");
    //   }
    // },
    async loadProductsByCar({commit, dispatch, rootGetters}, carId) {
      const response = await productsApi.get(`product-insurance-car/${carId}/list`).catch((e) => {
        console.error(e);
        rejectWaitLoadingUserProducts();
        throw new Error("user products loading failed. code #1");
      });
      if (productsApi.isSuccess(response)) {
        const list = response.data;
        const premiumSupportFeature = rootGetters["GBFeatures/premiumSupportFeature"];

        if (premiumSupportFeature.isActive) {
          await dispatch('loadPremiumSupportStatus');
        }
        commit("saveUserProducts", {list, premiumSupportFeature});
        resolveWaitLoadingUserProducts()
      } else {
        rejectWaitLoadingUserProducts();
        throw new Error("user products loading failed. code #2");
      }
    },
    // async activateProduct({commit, dispatch}, productCode) {
    //   if (productCode === 'premium-support') {
    //     await dispatch('togglePremiumSupport', true);
    //     return
    //   }
    //   const response = await productsApi.post("/product-user", {
    //     productCode
    //   }).catch((e) => {
    //     console.error(e);
    //     throw new Error("user products loading failed. code #1");
    //   });
    //   if (productsApi.isSuccess(response) && response.status === 200) {
    //     commit("addUserProduct", productCode);
    //     dispatch("productsOffers/getProductOffers", productCode, {root: true});
    //   } else {
    //     throw new Error("user products loading failed. code #2");
    //   }
    // },
    async activateProductByCar({commit, dispatch}, {productCode, carId}) {
      if (productCode === 'premium-support') {
        await dispatch('togglePremiumSupport', true);
        return
      }
      const response = await productsApi.post(`product-insurance-car/${carId}`, {
        productCode
      }).catch((e) => {
        console.error(e);
        throw new Error("user products loading failed. code #1");
      });
      if (productsApi.isSuccess(response) && response.status === 200) {
        commit("addUserProduct", productCode);
        dispatch("productsOffers/getProductOffers", productCode, {root: true});
      } else {
        throw new Error("user products loading failed. code #2");
      }
    },
    // async deactivateProduct({commit, dispatch}, productCode) {
    //   if (productCode === 'premium-support') {
    //     await dispatch('togglePremiumSupport', false);
    //     return
    //   }
    //   const response = await productsApi.delete(`/product-user/${productCode}`).catch((e) => {
    //     console.error(e);
    //     throw new Error("user products loading failed. code #1");
    //   });
    //   if (productsApi.isSuccess(response) && response.status === 200) {
    //     commit("removeUserProduct", productCode);
    //   } else {
    //     throw new Error("user products loading failed. code #2");
    //   }
    // },
    async deactivateProductByCar({commit, dispatch}, {productCode, carId}) {
      if (productCode === 'premium-support') {
        await dispatch('togglePremiumSupport', false);
        return
      }
      const response = await productsApi.delete(`product-insurance-car/${carId}/${productCode}`).catch((e) => {
        console.error(e);
        throw new Error("user products loading failed. code #1");
      });
      if (productsApi.isSuccess(response) && response.status === 200) {
        commit("removeUserProduct", productCode);
      } else {
        throw new Error("user products loading failed. code #2");
      }
    },
    async togglePremiumSupport(_, isActive) {
      const response = await osagoApi.post(`additional-service/fakedoor/premium-support`, {
        status: isActive ? "on" : "off"
      }).catch((e) => {
        console.error(e);
        throw new Error("Не получилось поменять состояние фейкдора");
      });
      if (osagoApi.isSuccess(response) && response.status === 200) {
        /* empty */
      } else {
        throw new Error("Не получилось поменять состояние фейкдора");
      }
    },
    async loadPremiumSupportStatus({state}) {
      const response = await osagoApi.get(`additional-service/fakedoor/premium-support/status`).catch((e) => {
        console.error(e);
        throw new Error("Не получилось получить состояние фейкдора");
      });
      if (osagoApi.isSuccess(response) && response.status === 200) {
        state.isPremiumSupportSelected = response.data.status !== 'off';
      } else {
        throw new Error("Не получилось получить состояние фейкдора");
      }
    }
  },
}

export default products;
