import api from "@rosfines/vue-common/common/api/osagoApi";
import DeviceStorage from "@/util/helpers/device-storage";
import moment from "moment";
import sleep from "@/util/helpers/sleep";
import {FRONT_DATE_FORMAT, BACK_DATE_FORMAT} from '@/constants/dateFormat';
import { backDate2FrontDate } from "@/util/helpers/frontDate2BackDate";
import { getFormattedCarData, getFormattedDriversData, getFormattedOwnerData } from '@/util/helpers/formFormatters';
import { statService } from '@/services/statService';
import {BUSINESS_TYPES_ARR, PERSON_TYPES} from "@/constants/personTypes";

let resolveWaitLoadingCarData;
let rejectWaitLoadingCarData;
let formLoad;

function hasNonNullValue(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value !== null && !['personType', 'isRegistrationCertificateMissing'].includes(key)) {
      // Если это объект и он не является массивом, запустить проверку рекурсивно
      if (typeof value === 'object' && !Array.isArray(value)) {
        // Если рекурсивный вызов находит не-null, возвращаем true
        if (hasNonNullValue(value)) {
          return true;
        }
      } else {
        // Найдено не-null значение
        return true;
      }
    }
  }
  // Не найдено не-null значений
  return false;
}

function reInitFormLoad () {
  rejectWaitLoadingCarData && rejectWaitLoadingCarData();
  formLoad = new Promise((resolve, reject) => {
    resolveWaitLoadingCarData = resolve;
    rejectWaitLoadingCarData = reject;
  });
}
reInitFormLoad();

const formStepsConfirmed = {
  'carData': (f) => f.car,
  'drivers': (f) => f.drivers,
  'owner': (f) => f.owner,
};

const form = {
  namespaced: true,
  state () {
    return {
      activeCarDataLoaded: false,
      activeCarId: 0,
      kbmList: [],
      activeCarData: {},
      dateWasChanged: false,
      dateIsActual: false,
      userChangedDate: false,
      isHasFormAlready: false,
      isCarJustAdded: false,
      allowHideStsNumber: true,
      editableCarId: 0,
      editableVehicleType: 'car',
    }
  },
  getters: {
    hasActiveCar(state) {
      return state.activeCarId !== 0;
    },
    getActiveCar(state) {
      return state.activeCarId && state.activeCarDataLoaded && state.activeCarData.car
    },
    getActiveCarData(state) {
      return state.activeCarId && state.activeCarDataLoaded && state.activeCarData
    },
    getActiveCarId(state) {
      return state.activeCarId;
    },
    getEditableCarId(state) {
      return state.editableCarId;
    },
    getEditableVehicleType(state) {
      return state.editableVehicleType;
    },
    getActiveCarVP(state, getters) {
      return getters.getActiveCar?.vehiclePlate;
    },
    getActiveCarVin(state, getters) {
      return getters.getActiveCar?.vin;
    },
    getActiveCarCategory(state, getters) {
      return getters.getActiveCar?.category;
    },
    confirmedSteps(state) {
      return state.activeCarData.confirmedStep;
    },
    stepConfirmed(state, getters) {
      if (state.activeCarDataLoaded && state.activeCarData.id) {
        return (step) => {
          return formStepsConfirmed[step] && formStepsConfirmed[step](getters.confirmedSteps);
        }
      } else {
        return false;
      }
    },
    kbmList(state) {
      if (state.kbmList.length === 0) {
        return [];
      } else {
        const kbmMap = {}
        for (let i in state.kbmList) {
          kbmMap[`driver_${state.kbmList[i].driverId}`] = state.kbmList[i];
        }
        return state.activeCarData.drivers.filter((driver) => !!kbmMap[`driver_${driver.id}`].kbm).map((driver) => {
          return {
            ...kbmMap[`driver_${driver.id}`],
            firstName: driver.firstName,
            lastName: driver.lastName,
            secondName: driver.secondName,
          }
        });
      }
    },
    carStatus(state, getters, rootState, rootGetters) {
      return rootGetters["cars/getCarStatus"](state.activeCarId);
    },
  },
  mutations: {
    _chooseCar(state, carId) {
      state.activeCarId = carId;
      state.kbmList = [];
      DeviceStorage.activeCarId = state.activeCarId;
    },
    resetActiveCarId(state) {
      state.activeCarId = 0;
      // не сохраняй 0 в localstorage!
      // ---DeviceStorage.activeCarId = state.activeCarId;---
      // не сохраняй 0 в localstorage!
      state.kbmList = [];
    },
    hasNoCar(state) {
      state.activeCarDataLoaded = true;
      resolveWaitLoadingCarData();
    },
    setActiveCarDataLoaded(state, value) {
      value = !!value;
      if (value !== state.activeCarDataLoaded) {
        state.activeCarDataLoaded = value;
        if (value) {
          resolveWaitLoadingCarData();
        }
      }
    },
    restoreActiveCarIdIfMissing(state) {
      if (!state.activeCarId) {
        state.activeCarId = DeviceStorage.activeCarId;
      }
    },
    clearActiveCarData(state) {
      state.activeCarData = {};
      state.validationData = {isValid: true};
      state.kbmList = [];
    },
    setKbmList(state, list) {
      state.kbmList = list;
    },
    saveActiveCarData(state, carData) {
      if (!carData.confirmedStep.car && carData?.draftPolicyForm?.car) {
        carData.car.bodyNumber = carData.draftPolicyForm.car.bodyNumber;
        carData.car.carModification = carData.draftPolicyForm.car.carModification;
        carData.car.isLeftHandDrive = carData.draftPolicyForm.car.isLeftHandDrive;
        carData.car.productionYear = carData.draftPolicyForm.car.productionYear || "";
        carData.car.noVin = carData.draftPolicyForm.car.noVin;
        carData.car.vin = carData.draftPolicyForm.car.vin;
        carData.docType = carData.draftPolicyForm.car.docType || carData.docType;
        carData.car.sts.date = carData.draftPolicyForm.car.stsDate;
        carData.car.sts.number = carData.draftPolicyForm.car.stsNumber;
        carData.car.pts.date= carData.draftPolicyForm.car.ptsDate;
        carData.car.pts.number= carData.draftPolicyForm.car.ptsNumber;
        carData.car.epts.date = carData.draftPolicyForm.car.eptsDate;
        carData.car.epts.number = carData.draftPolicyForm.car.eptsNumber;
        state.allowHideStsNumber = false;
      } else {
        state.allowHideStsNumber = true;
      }

      if (!carData.confirmedStep.drivers && carData?.draftPolicyForm?.drivers) {
        if (carData.drivers.length === 0 && carData.draftPolicyForm.drivers.drivers.length > 0) {
          carData.drivers = carData.draftPolicyForm.drivers.drivers
        } else if (carData.drivers.length !== 0 && carData.drivers.length === carData.draftPolicyForm.drivers.drivers.length) {
          for (const i in carData.draftPolicyForm.drivers.drivers) {
            for (const j in carData.draftPolicyForm.drivers.drivers[i]) {
              carData.drivers[i][j] = carData.draftPolicyForm.drivers.drivers[i][j] || carData.drivers[i][j];
            }
          }
        }
        carData.isUnlimitedDrivers = carData.draftPolicyForm.drivers.isUnlimitedDrivers;
      }

      if (!carData.confirmedStep.owner && carData?.draftPolicyForm?.owner) {
        for (const i in carData.draftPolicyForm.owner.autoOwner) {
          // if (i === 'personType') {
          //   carData.autoOwner[i] = carData.draftPolicyForm.owner.autoOwner[i] || carData.autoOwner[i];
          // } else {
          //   carData.autoOwner[i] = carData.autoOwner[i] || carData.draftPolicyForm.owner.autoOwner[i];
          // }
          carData.autoOwner[i] = carData.draftPolicyForm.owner.autoOwner[i] || carData.autoOwner[i];
        }
        for (const i in carData.draftPolicyForm.owner.policyholder) {
          // if (i === 'personType') {
          //   carData.policyholder[i] = carData.draftPolicyForm.owner.policyholder[i] || carData.policyholder[i];
          // } else {
          //   carData.policyholder[i] = carData.policyholder[i] || carData.draftPolicyForm.owner.policyholder[i];
          // }
          carData.policyholder[i] = carData.draftPolicyForm.owner.policyholder[i] || carData.policyholder[i];
        }
        carData.phone = carData.draftPolicyForm.owner.phone;
        carData.email = carData.draftPolicyForm.owner.email;
        if (typeof carData.draftPolicyForm?.owner?.isOwnerPolicyholder === "boolean") {
          carData.isOwnerPolicyholder = carData.draftPolicyForm.owner.isOwnerPolicyholder;
        }
      }
      delete carData.draftPolicyForm;


      // костыли для дефолтных значений, надеюсь временные
      carData.isUnlimitedDrivers = !!carData.isUnlimitedDrivers;
      carData.docType = carData.docType || 1;
      carData.isOwnerPolicyholder = carData.isOwnerPolicyholder === null ? true : carData.isOwnerPolicyholder;
      carData.car.isLeftHandDrive = carData.car.isLeftHandDrive === null ? true : carData.car.isLeftHandDrive;
      carData.withTrailer = !!carData.withTrailer;
      carData.isTaxi = !!carData.isTaxi;
      // /костыли для дефолтных значений, надеюсь временные

      // null вместо пустой строки
      carData.car.productionYear = carData.car.productionYear || "";
      carData.car.carModification.power = carData.car.carModification.power || "";
      carData.drivers?.forEach((driver) => {
        driver.secondName = driver.secondName ? driver.secondName : "";
      });
      carData.autoOwner.secondName = carData.autoOwner?.secondName || "";
      carData.policyholder.secondName = carData.policyholder?.secondName || "";
      carData.policyholder.firstName = carData.policyholder?.firstName || "";
      carData.policyholder.lastName = carData.policyholder?.lastName || "";
      carData.policyholder.birthDate = carData.policyholder?.birthDate || "";
      carData.policyholder.passportNumber = carData.policyholder?.passportNumber || "";
      carData.policyholder.passportIssueDate = carData.policyholder?.passportIssueDate || "";
      carData.policyholder.registrationAddress = carData.policyholder?.registrationAddress || {};
      carData.policyholder.registrationAddress.address = carData.policyholder.registrationAddress.address ? carData.policyholder.registrationAddress.address : (carData.policyholder?.inputAddress || "");
      if (carData.policyholder.registrationAddress.address) {
        carData.policyholder.inputAddress = carData.policyholder.registrationAddress.address.replace(/, кв \d+$/, "");
      }
      if (carData.policyholder.registrationAddress.flatNumber) {
        carData.policyholder.inputFlatNumber = carData.policyholder.registrationAddress.flatNumber?.replace(/\D/mig, "");
      }
      // /null вместо пустой строки

      // удаление односимвольной фамилии
      carData.drivers?.forEach((driver) => {
        driver.lastName = driver?.lastName?.length === 1 ? "" : (driver.lastName || "");
      });
      carData.autoOwner.lastName = carData.autoOwner?.lastName?.length === 1 ? "" : carData.autoOwner?.lastName || "";
      carData.policyholder.lastName = carData.policyholder?.lastName?.length === 1 ? "" : carData.policyholder?.lastName || "";
      // /удаление односимвольной фамилии

      //конвертация формата дат
      carData.startDate = backDate2FrontDate(carData.startDate);
      carData.userStartDate = backDate2FrontDate(carData.userStartDate);
      carData.endDate = backDate2FrontDate(carData.endDate);
      carData.car.sts.date = backDate2FrontDate(carData.car.sts.date);
      carData.car.pts.date = backDate2FrontDate(carData.car.pts.date);
      carData.car.epts.date = backDate2FrontDate(carData.car.epts.date);
      carData.autoOwner.passportIssueDate = backDate2FrontDate(carData.autoOwner.passportIssueDate);
      carData.autoOwner.birthDate = backDate2FrontDate(carData.autoOwner.birthDate);
      carData.autoOwner.registrationCertificateIssueDate = backDate2FrontDate(carData.autoOwner.registrationCertificateIssueDate);
      carData.policyholder.passportIssueDate = backDate2FrontDate(carData.policyholder.passportIssueDate);
      carData.policyholder.birthDate = backDate2FrontDate(carData.policyholder.birthDate);
      carData.policyholder.registrationCertificateIssueDate = backDate2FrontDate(carData.policyholder.registrationCertificateIssueDate);
      carData.drivers?.forEach((driver) => {
        driver.birthDay = backDate2FrontDate(driver.birthDay);
        driver.experienceStartDate = backDate2FrontDate(driver.experienceStartDate);
        driver.licenceStartDate = backDate2FrontDate(driver.licenceStartDate);
        driver.oldLicenceStartDate = backDate2FrontDate(driver.oldLicenceStartDate);
      });
      // /конвертация формата дат

      // приведение к строке, тк инпуты не оперируют намберами
      if (typeof carData?.car?.productionYear === 'number') {
        carData.car.productionYear = String(carData.car.productionYear);
      }
      if (typeof carData?.car?.carModification?.power === 'number') {
        carData.car.carModification.power = String(carData.car.carModification.power);
      }
      carData.drivers?.forEach((driver) => {
        driver.experienceStartYear = String(driver.experienceStartYear || "");
      });
      // /приведение к строке, тк инпуты не оперируют намберами

      carData.car.isMoto = carData.car.category === 'A';


      // значения для флоу юр.лиц
      carData.autoOwner.isCorp = BUSINESS_TYPES_ARR.includes(carData.autoOwner?.personType);
      carData.autoOwner.ownerType = 'OWNER';
      carData.autoOwner.isBusinessman = carData.autoOwner?.personType === PERSON_TYPES.BUSINESSMAN;
      carData.policyholder.isCorp = BUSINESS_TYPES_ARR.includes(carData.policyholder?.personType);
      carData.policyholder.ownerType = 'POLICYHOLDER';
      carData.policyholder.isBusinessman = carData.policyholder?.personType === PERSON_TYPES.BUSINESSMAN;
      carData.isCorp = BUSINESS_TYPES_ARR.includes(carData.policyholder?.personType);
      // /значения для флоу юр.лиц

      state.activeCarData = carData;
      state.validationData = {isValid: true};
      state.kbmList = [];
      this.commit("form/setActiveCarDataLoaded", true);
    },
    dateWasChanged(state, value) {
      state.dateWasChanged = !!value;
    },
    dateIsActual(state, value) {
      state.dateIsActual = !!value;
    },
    userChangedDate(state, value) {
      state.userChangedDate = !!value;
    },
    saveIsHasFormAlready(state, value) {
      state.isHasFormAlready = !!value;
      state.isCarJustAdded = false;
    },
    carJustAdded(state) {
      state.isCarJustAdded = true;
    },
    changeEditableCarId(state, value) {
      state.editableCarId = value;
    },
    changeEditableVehicleType(state, value) {
      state.editableVehicleType = value;
    },
  },
  actions: {
    async waitLoadingCarData() {
      return formLoad;
    },
    async chooseCar({state, dispatch, commit}, carId) {
      if (state.activeCarId) {
        await dispatch("onChangeCar", undefined, {root: true});
        reInitFormLoad();
      }
      commit("_chooseCar", carId);
      await dispatch("loadActiveCarData");
    },
    async setDefaultCarIfMissing({state, dispatch}, id) {
      if (!state.activeCarId) {
        await dispatch("chooseCar", id);
      }
    },
    async loadActiveCarData({state, commit, dispatch}) {
      commit("setActiveCarDataLoaded", false);
      commit("restoreActiveCarIdIfMissing");
      if (state.activeCarId) {
        commit("clearActiveCarData");
        await dispatch("checkIfHasFormAlready", state.activeCarId);
        const response = await api.get(`/policy-form/by-car/${state.activeCarId}/v2`).catch(async (e) => {
          if (e && e.response) {
            if (e.response.status === 404) {
              await dispatch("chooseCar", 0);
              location.reload();
            } else if (e.response.status === 423) {
              await sleep(500);
              return dispatch("loadActiveCarData");
            } else {
              rejectWaitLoadingCarData();
              throw new Error('loading car data failed #2');
            }
          }
        });
        if (api.isSuccess(response)) {
          commit("saveActiveCarData", response.data);
          commit("setActiveCarDataLoaded", true);
          commit("dateWasChanged", false);
          commit("dateIsActual", false);
          commit("userChangedDate", false);
          if (this.hasModule("rsa")) {
            setTimeout(() => {
              dispatch("rsa/getRSAData", undefined, { root: true })
            }, 0);
          }
        } else {
          rejectWaitLoadingCarData();
          throw new Error('loading car data failed');
        }
        return response;
      }
    },

    async updateKbmInfo({state, commit, dispatch}) {
      statService.logEvent('[Ситуация] Запрошен КБМ');
      await dispatch("waitLoadingCarData");
      const response = await api.get(`policy-form/${state.activeCarData.id}/get-kbm`).catch((e) => {
        throw e;
      });
      if (api.isSuccess(response)) {
        commit("setKbmList", response.data);
      }
    },

    async updateStartDate({state, commit}, frontStartDate) {
      const userStartDate = moment(frontStartDate, FRONT_DATE_FORMAT).format(BACK_DATE_FORMAT);
      const mod = JSON.stringify({
        frontStartDate,
        frontStartDateMoment: moment(frontStartDate, FRONT_DATE_FORMAT).format(FRONT_DATE_FORMAT),
        backStartDate: userStartDate,
        formId: state.activeCarData.id,
      });
      statService.logEvent('[Ситуация] Юзер установил дату начала полиса', {mod});
      const response = await api.patch(`/policy-form/by-car/${state.activeCarId}`, {
        userStartDate
      });
      if (api.isSuccess(response)) {
        commit("saveActiveCarData", response.data);
        commit("userChangedDate", true);
      }
      return response
    },
    async autoUpdateStartDate({state, commit}) {
      let i = 0;
      let response;
      // пытаемся обновить дату 5 раз, если никак, то покажем модалку с ошибкой
      while (i<5 && !api.isSuccess(response)) {
        response = await api.post(`policy-form/by-car/${state.activeCarId}/update-start-date`);
        if (api.isSuccess(response)) {
          const currentStartDate = state.activeCarData.startDate;
          if (currentStartDate !== response.data.startDate) {
            commit("dateWasChanged", true);
          }
          commit("dateIsActual", true);
          commit("userChangedDate", false);
          commit("saveActiveCarData", response.data);
          return true;
        }
        await sleep(500);
      }
      throw "can't update date";
    },

    async saveCarDataDraft({state, getters}, formData) {
      const data = getFormattedCarData({ getters }, formData);
      delete data.carModificationId;
      return await api.post(`/policy-form/car/${state.activeCarId}/draft`, data);
    },

    async saveDriversDraft({state}, driversData) {
      return await api.post(`/policy-form/car/${state.activeCarId}/drivers/draft`, getFormattedDriversData({}, driversData));
    },

    async saveOwnersDraft({state}, formData) {
      return await api.post(`/policy-form/car/${state.activeCarId}/owner-policyholder/draft`, getFormattedOwnerData({}, formData));
    },

    async saveCarData({state, getters, commit}, formData) {
      const data = getFormattedCarData({ getters }, formData);
      delete data.carMarkId;
      delete data.carModelId;
      const response = await api.post(`/policy-form/car/${state.activeCarId}`, data);
      if (api.isSuccess(response)) {
        commit("saveActiveCarData", response.data);
        commit("validation/updateValidationData", "carData", {root: true});
        return true;
      } else {
        return response?.data?.message || false;
      }
    },

    async saveDrivers({state, commit}, driversData) {
      const response = await api.post(`/policy-form/car/${state.activeCarId}/drivers`, getFormattedDriversData({}, driversData));
      if (api.isSuccess(response)) {
        commit("saveActiveCarData", response.data);
        commit("validation/updateValidationData", "drivers", {root: true});
        return true;
      } else {
        return response?.data?.message || false;
      }
    },

    async saveOwners({state, commit}, formData) {
      const response = await api.post(`/policy-form/car/${state.activeCarId}/owner-policyholder`, getFormattedOwnerData({}, formData));
      if (api.isSuccess(response)) {
        commit("saveActiveCarData", response.data);
        commit("validation/updateValidationData", "owner", {root: true});
        return true;
      } else {
        return response?.data?.message || false;
      }
    },

    async checkIfHasFormAlready({ state, commit }) {
      if (state.isCarJustAdded) {
        commit("saveIsHasFormAlready", false);
      } else {
        const response = await api.get(`/policy-form/by-car/${state.activeCarId}/state`).catch(() => {
          commit("saveIsHasFormAlready", true);
        });
        if (api.isSuccess(response)) {
          const { isPolicyFormExists } = response.data;
          commit("saveIsHasFormAlready", isPolicyFormExists);
        } else {
          commit("saveIsHasFormAlready", true);
        }
      }
    },

    async sendCommMsg({state, dispatch}){
      await dispatch("waitLoadingCarData");
      if(state.activeCarId && state.activeCarData && state.activeCarData.id) {
        await api.post(`/communication/return-message/${state.activeCarData.id}`);
      }
    },

    setEditableCarId({commit}, id) {
      commit('changeEditableCarId', id);
    },
    setEditableVehicleType({commit}, vehicleType) {
      commit('changeEditableVehicleType', vehicleType);
    },

  }
}

export default form;
