<template>
  <section
    :data-field="id"
    @focusin="onFocus"
    @focusout="onBlur"
    :class="{
      readonly: readonly,
      loading: loading,
    }"
  >
    <Field
      ref="validationField"
      v-slot="{errorMessage}"
      :name="id"
      :rules="rules"
      :value="formattedValue || modelValue"
    >
      <div class="fly-label">
        <input
          :id="id"
          ref="input"
          v-imask="OMasks[mask]"
          :type="type"
          :name="name || id"
          :placeholder="placeholder"
          :uppercase="uppercase"
          :readonly="readonly"
          :aria-label="label"
          class="form-control form-control-lg textfield-shifted"
          :class="{
            ...classes,
            'textfield-shifted': inputShifted,
            'textfield-hidden': !inputShifted,
            'is-modal': modal,
            failed: !!errorMessage,
          }"
          autocomplete="off"
          @change="onChange"
          @click="onClick"
          @input="onInput"
          @accept="onAccept"
          @complete="onComplete"
        >

        <a
          v-if="modal"
          class="link-input-icon"
          href="javascript:;"
          @click="$showModal(modal)"
        >
          <i class="svgimg svg-help" />
        </a>

        <vue-bootstrap-typeahead-list
          v-if="isFocused && (modelValue && modelValue.length) >= minMatchingChars"
          ref="list"
          :data="formattedData"
          :local-data-filter="localDataFilter"
          :max-matches="maxMatches"
          :min-matching-chars="minMatchingChars"
          :query="inputValue"
          class="vbt-autcomplete-list"
          @hit="handleHit"
        />

        <label
          :for="id"
          class="required placeholder-label"
          :class="{'placeholder-shifted': inputShifted}"
        >{{ label }}</label>
      </div>
      <div v-if="serverValidation && !wasFocused" class="invalid-feedback--vue js_invalid_feedback">{{ serverValidation && serverValidation[0] || serverValidation }}</div>
      <ErrorMessage
        v-else-if="!!errorMessage"
        as="div"
        :name="id"
        class="invalid-feedback--vue js_invalid_feedback"
      />
    </Field>
  </section>
</template>

<script>
import VueBootstrapTypeaheadList from './AppTypeaheadList.vue';
import ResizeObserver from 'resize-observer-polyfill';
import appField from "@/mixins/appField";
import masksMixin from "@/mixins/masks";
import {statService} from "@/services/statService";
import {IMaskDirective} from "vue-imask";
import {Field, ErrorMessage, useField} from "vee-validate";


export default {
  components: {
    VueBootstrapTypeaheadList,
    Field,
    ErrorMessage,
  },
  directives: {
    imask: IMaskDirective
  },
  mixins: [
    appField,
    masksMixin
  ],

  props: {
    size: {
      type: String,
      default: null,
      validator: size => ['lg', 'sm'].indexOf(size) > -1
    },
    data: {
      type: Array,
      required: true,
      validator: d => d instanceof Array
    },
    serializer: {
      type: Function,
      default: d => d,
      validator: d => d instanceof Function
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    localDataFilter: {
      type: Boolean,
      default: false
    },
    maxMatches: {
      type: Number,
      default: 10
    },
    minMatchingChars: {
      type: Number,
      default: 1
    },
    mask: {
      type: String,
      default: ""
    },
    serverValidation: {
      type: Array,
      default: null,
    },
    prepend: String,
    append: String,
    classes: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [Number, String],
      default: "",
    }
  },
  emits: [
    'click',
    'change',
    'input',
    'update:modelValue',
    'hit'
  ],

  data() {
    return {
      errorMessage: null,
      isFocused: false,
      wasFocused: false,
      inputValue: "",
    };
  },

  computed: {
    sizeClasses() {
      return this.size
          ? `input-group input-group-${this.size}`
          : 'input-group';
    },

    formattedData() {
      if (!(this.data instanceof Array)) {
        return [];
      }
      return this.data.map((d, i) => {
        return {
          id: i,
          data: d,
          text: this.serializer(d)
        };
      });
    },
    inputShifted() {
      return (!this.readonly && this.isFocused) || this.modelValue || this.placeholder;
    },
  },

  watch: {
    modelValue(val) {
      this.$refs.input.value = val;
      this.inputValue = val;
    },
    errorMessage(newVal) {
      if (newVal) {
        let eventData = {
          fieldValue: this.$refs.input.value,
        };
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent(`[Ситуация] Показана ошибка валидации. Поле [${this.id}]`, eventData);
      }
    },
  },

  mounted() {
    if (this.modelValue === null && !this.mask){
      this.$emit('update:modelValue', "");
    }
    this.$refs.input.value = this.modelValue;
    this.inputValue = this.modelValue;
    this.$_ro = new ResizeObserver(() => {
      this.resizeList(this.$refs.input);
    });
    this.$_ro.observe(this.$refs.input);
    //this.$_ro.observe(this.$refs.list.$el);
    this.errorMessage = useField(this.id).errorMessage;
  },

  beforeUnmount() {
    this.$_ro.disconnect();
  },

  methods: {
    resizeList(/* el */) {
      /*const rect = el.getBoundingClientRect();
      const listStyle = this.$refs.list.$el.style;
      // Set the width of the list on resize
      listStyle.width = rect.width + "px";
      // Set the margin when the prepend prop or slot is populated
      // (setting the "left" CSS property doesn't work)
      if (this.$refs.prependDiv) {
          const prependRect = this.$refs.prependDiv.getBoundingClientRect();
          listStyle.marginLeft = prependRect.width + "px";
      }*/
    },

    handleHit(evt) {
      if (!this.mask) {
        this.$emit('update:modelValue', evt.text);
      }
      this.inputValue = evt.text;
      this.$emit('hit', evt.data);
      this.$refs.input.value = evt.text;
      this.isFocused = false;
      this.$nextTick(() => {
        this.$refs.validationField.handleChange(evt.text);
      });
      let eventData = {};
      statService.setStatServiceDataParam({query: {}}, eventData);
      statService.logEvent(`[Действие] Кликнул в dropdown typeahead ${this.label}`, eventData);
    },

    getValue() {
      return this.$refs.input.inputmask
        ? this.$refs.input.inputmask.unmaskedvalue()
        : this.$refs.input.value;
    },

    onFocus() {
      if(this.readonly) {
        return;
      }
      this.isFocused = true;
      this.wasFocused = true;
      let eventData = {};
      statService.setStatServiceDataParam({query: {}}, eventData);
      statService.logEvent(`[Действие] Кликнул в typeahead ${this.label}`, eventData);
    },

    onBlur(evt) {
      if(this.readonly) {
        return;
      }
      const value = this.getValue(evt);
      const tgt = evt.relatedTarget;
      if (tgt && tgt.classList.contains('vbst-item')) {
        return;
      }
      this.isFocused = false;
      let eventData = {};
      statService.setStatServiceDataParam({query: {}}, eventData);
      statService.logEvent(`[Действие] Ушел с typeahead ${this.label}`, eventData);

      this.$refs.validationField.handleChange(value);
    },

    onInput(event) {
      const value = this.getValue(event);
      if (typeof value !== 'undefined') {
        this.inputValue = value;
        if (!this.mask) {
          this.$emit('update:modelValue', this.keyToLang(value));
        }
        this.$emit('input', this.keyToLang(value));
      }
    },

    onChange(event) {
      const value = this.keyToLang(this.getValue(event));
      this.$emit('change', value);
      if (!this.mask) {
        this.$emit('update:modelValue', value);
      }
    },

    onClick() {
      if (!this.readonly) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

.vbt-autcomplete-list {
  padding-top: 5px;
  position: absolute;
  max-height: 350px;
  overflow-y: auto;
  z-index: 999;
  width: 100%;

  [uppercase] {
    input {
      text-transform: uppercase;
    }
  }
}
.readonly {
  input, label {
    opacity: 0.4;
  }
}
.loading {
  .fly-label {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 14px;
      right: 16px;
      background: url(@/assets/img/loader-main.svg) center center / contain no-repeat;
    }
    .link-input-icon {
      display: none;
    }
  }
}
</style>
