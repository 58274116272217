import DeviceStorage from "@/util/helpers/device-storage";
import {CancelToken} from 'axios';
import api from "@rosfines/vue-common/common/api/osagoApi";
import {statService} from "@/services/statService";
import sleep from "@/util/helpers/sleep";
import { SORT_FUNCS } from '@/util/helpers/sortCalcs';

let offersTimer;
let cancelLoadingOffers = [];
const nextRequestDelay = 3000;
const defaultOffersLoadingTimeout = 4*60*1000;

const offers = {
  namespaced: true,
  state () {
    return {
      offersLoadingTimeout: 0,
      offersTimerCreateTime: null,

      loadingOffers: false,
      /**
       * loadingOffersStatus:
       * 0 - ожидание
       * 10 - получение в процессе
       * 20 - процесс остановлен
       */
      loadingOffersStatus: 0,
      insuranceCompanyId: 0,
      calculation: {},
      offers: [],
      offersMap: {},
      statusesMap: {},

      savedChosenCompanyId: 0,

      gettingDraft: false,
      draftURL: "",
    }
  },
  getters: {
    loadingOffers(state, getters) {
      return state.loadingOffers && !getters.computedTargetOffer;
    },
    computedTargetOffer(state) {
      const offerId = state.offersMap[`company_${state.insuranceCompanyId}`];
      const offer = state.offers[offerId] || null;
      if (offer) {
        offer.oldPrice = state.calculation && state.calculation.price;
        offer.priceIsChanged = offer.price > state.calculation.price;
        offer.dateIsChanged = offer.dateFrom !== state.calculation.dateFrom;
        offer.oldStartDate = state.calculation.dateFrom;
        offer.isTaxiChanged = offer.isTaxi !== state.calculation.isTaxi;
        offer.withTrailerChanged = offer.withTrailer !== state.calculation.withTrailer;
      }
      return offer;
    },
    otherCompanies(state, getters, rootState) {
      return [...state.offers.filter((offer) => offer.insuranceCompanyId !== state.insuranceCompanyId)].sort((SORT_FUNCS[rootState.calculations.sortType])) || [];
    },
    showOtherCompanies(state, getters) {
      return !!(getters.otherCompanies.length && ((!getters.computedTargetOffer) || (getters.computedTargetOffer && (getters.computedTargetOffer.priceIsChanged || getters.computedTargetOffer.dateIsChanged))));
    },
    computedFailedOffer(state, getters) {
      const isTargetCompanyCompleted = state.statusesMap[`company_${state.insuranceCompanyId}`] === "completed";
      const loadingFinished = state.loadingOffersStatus === 20;
      const hasOtherOffers = getters.otherCompanies.length;
      if (!getters.computedTargetOffer && (loadingFinished || (hasOtherOffers && isTargetCompanyCompleted)) && state.calculation) {
        return state.calculation;
      } else {
        return false;
      }
    },
  },
  mutations: {
    setOffersTimerCreateTime(state, value) {
      state.offersTimerCreateTime = value;
    },
    setLoadingStatus(state, value) {
      state.loadingOffers = [0, 20].indexOf(value) === -1;
      state.loadingOffersStatus = value;
    },
    resetOffers(state) {
      state.offers = [];
      state.offersMap= {};
      state.statusesMap= {};
    },
    saveStatuses(state, statuses) {
      statuses.map((status) => {
        state.statusesMap[`company_${status.insuranceCompanyId}`] = status.status;
      });
    },
    pushOffers(state, offers) {
      offers.map((offer) => {
        if (typeof state.offersMap[`company_${offer.insuranceCompanyId}`] === "undefined") {
          state.offers.push(offer);
          state.offersMap[`company_${offer.insuranceCompanyId}`] = state.offers.length - 1;
          if (state.insuranceCompanyId === offer.insuranceCompanyId) {
            let eventData = {
              offerId: offer.offerId,
              mod: offer.offerId,
            };
            statService.setStatServiceDataParam({query: {}}, eventData);
            statService.logEvent('[Ситуация] Финальный оффер', eventData);
          } else {
            let eventData = {
              offerId: offer.offerId,
              mod: offer.offerId,
            };
            statService.setStatServiceDataParam({query: {}}, eventData);
            statService.logEvent('[Ситуация] Получен проброс на экране финального оффера', eventData);
          }
        }
      });
    },
    _setICId(state, {company, carId}) {
      state.insuranceCompanyId = company.insuranceCompanyId;
      state.calculation = company;
      DeviceStorage.chosenCalcs = {
        company,
        carId,
      };
    },
    _restoreICId(state, activeCarId) {
      if (!state.insuranceCompanyId) {
        const storedICIds = DeviceStorage.chosenCalcs || false;
        if (storedICIds && storedICIds[activeCarId]) {
          state.calculation = storedICIds[activeCarId];
          state.insuranceCompanyId = storedICIds[activeCarId].insuranceCompanyId;
        } else {
          state.insuranceCompanyId = 0;
          state.calculation = {};
        }
      }
    },
    resetInsuranceCompanyId(state, carId) {
      state.insuranceCompanyId = 0;
      DeviceStorage.chosenCalcs = {
        insuranceCompanyId: null,
        carId,
      }
    },
    setGettingDraft(state, value) {
      state.gettingDraft = value;
    },
    setDraftURL(state, value) {
      state.draftURL = value;
    },
    savedChosenCompanyId(state) {
      state.savedChosenCompanyId = DeviceStorage.savedChosenCompanyId;
    },
    setOffersLoadingTimeout(state, value) {
      state.offersLoadingTimeout = value || defaultOffersLoadingTimeout;
    }
  },
  actions: {
    async chooseInsuranceCompany({commit, rootState, dispatch}, {
      company,
      getOffers = true,
    }) {
      await dispatch("form/waitLoadingCarData", undefined, {root: true});
      const {
        bonus,
        dateFrom,
        dateTo,
        insuranceCompanyId,
        price,
        offerId,
        withTrailer,
        calculationId,
        isTaxi
      } = company;
      commit("savedChosenCompanyId");
      commit("_setICId", {
        company: {
          bonus,
          dateFrom,
          dateTo,
          insuranceCompanyId,
          price,
          withTrailer,
          offerId: offerId || calculationId,
          isTaxi,
        },
        carId: rootState.form.activeCarId,
      });
      await api.post(`/communication/save-selected-offer/${rootState.form.activeCarData.id}`, {
        insuranceCompanyId
      });
      if (getOffers) {
        dispatch("getOffers").catch((e) => {
          throw e;
        });
      }
    },
    async trackOffer(context, offerId) {
      await api.get(`/analytics/track_offer/${offerId}`);
    },
    async saveChosenCompanyId(context, offerId) {
      DeviceStorage.savedChosenCompanyId = offerId;
    },
    restoreICId({commit, rootState}) {
      commit("_restoreICId", rootState.form.activeCarId);
    },
    onChangeCar({commit, rootState}) {
      commit("resetInsuranceCompanyId", rootState.form.activeCarId);
    },

    async getOffers({state, dispatch, commit, rootState}, options) {
      const {offersLoadingTimeout} = options || {};
      if (!state.insuranceCompanyId) {
        await dispatch("restoreICId");
      }
      if (state.insuranceCompanyId) {
        const carId = rootState.form.activeCarId;
        if (carId) {
          await dispatch("stopLoadingOffers", false);

          commit("setOffersLoadingTimeout", offersLoadingTimeout || defaultOffersLoadingTimeout);
          commit("setLoadingStatus", 10);
          commit("resetOffers");
          commit("setDraftURL", "");
          commit("form/dateWasChanged", false, {root: true});
          if (!offersTimer) {
            commit("setOffersTimerCreateTime", new Date().getTime());
            if (!rootState.form.dateIsActual) {
              await dispatch("form/autoUpdateStartDate", undefined, {root: true}).catch((e) => {
                throw e;
              });
            }
            offersTimer = setTimeout(() => dispatch("stopLoadingOffers"), state.offersLoadingTimeout);
            if (!rootState.productsCalcs.calculations.length) {
              setTimeout(() => dispatch("productsCalcs/getCalculations", undefined, {root: true}), nextRequestDelay);
            }
            let localOffersTimer = offersTimer;
            while (offersTimer && offersTimer === localOffersTimer) {
              await dispatch("_loadOffers", carId);
              await sleep(nextRequestDelay);
            }
          }
        } else {
          throw new Error("{carId} missed #3");
        }
      } else {
        throw "{insuranceCompanyId} missed";
      }
    },

    async stopLoadingOffers({state, commit, dispatch, getters}, sendEvents = true) {
      if(state.loadingOffers && sendEvents) {
        if (!getters.computedTargetOffer) {
          let eventData = {};
          statService.setStatServiceDataParam({query: {}}, eventData);
          statService.logEvent('[Ситуация] Не получил заказ', eventData);
        }

        if (!getters.otherCompanies.length) {
          let eventData = {};
          statService.setStatServiceDataParam({query: {}}, eventData);
          statService.logEvent('[Ситуация] Не получил офферы-пробросы', eventData);
        }
      }
      if (state.loadingOffersStatus !== 0) {
        commit("setLoadingStatus", 20);
      }
      clearTimeout(offersTimer);
      offersTimer = undefined;
      commit("setOffersTimerCreateTime", null);
      dispatch('_cancelLoadingOffers');
    },

    async _loadOffers({state, commit, dispatch, getters, rootState}, carId) {
      if(carId) {
        let queryParams = {};
        queryParams.utmMarketing = rootState.app.utmMarketing;
        queryParams.selectedOfferId = state.calculation.offerId;
        const offers = await api.get(`/partner/car/${carId}/offers`, queryParams, undefined, {
          cancelToken: new CancelToken(function executor(c) {
            cancelLoadingOffers.push(c);
          })
        });
        if (api.isSuccess(offers)) {
          commit("saveStatuses", offers.data.statusDetails);
          commit("pushOffers", offers.data.offers);
          const computedTargetOffer = getters.computedTargetOffer;
          if (offers.data.isCompleted || (computedTargetOffer && !computedTargetOffer.dateIsChanged && !computedTargetOffer.priceIsChanged)) {
            await dispatch("stopLoadingOffers");
          }
          if (computedTargetOffer && computedTargetOffer.draft && !state.gettingDraft) {
            dispatch("startDraftLoading");
          }
        }
      } else {
        throw "{carId} not passed";
      }
    },

    _cancelLoadingOffers() {
      cancelLoadingOffers.forEach((item) => {
        item("due to cancelLoadingOffers called");
      });
      cancelLoadingOffers = [];
    },

    async startDraftLoading({state, commit, dispatch}) {
      commit("setGettingDraft", true);
      while(state.gettingDraft) {
        await dispatch("loadDraft");
        await sleep(nextRequestDelay);
      }
    },

    async loadDraft({dispatch, commit, getters}) {
      const draftInfo = await api.get(`/partner/offer/${getters.computedTargetOffer.offerId}/draftStatus`);
      if(api.isSuccess(draftInfo)) {
        if(draftInfo.data.status === "ok") {
          commit("setDraftURL", draftInfo.data.draftUrl);
          dispatch("stopDraftLoading");
        }
      }
    },

    stopDraftLoading({commit}) {
      commit("setGettingDraft", false);
    },

    async getPaymentLink({getters}, offerId) {
      offerId = offerId ? offerId : getters.computedTargetOffer && getters.computedTargetOffer.offerId;
      if (offerId) {
        const paymentLink = await api.get(`/partner/offer/${offerId}/payment_url`);
        if(api.isSuccess(paymentLink) && paymentLink.data.paymentUrl) {
          return paymentLink.data.paymentUrl
        }
      }
      return false
    },

    async spoilTargetOffer({getters}) {
      const rejectUpsale = await api.post(`/partner/offer/${getters.computedTargetOffer.offerId}/spoil`)
      return api.isSuccess(rejectUpsale) && !rejectUpsale.data.error;
    },

    stopOffersActivity({dispatch}) {
      dispatch("stopDraftLoading");
      dispatch("stopLoadingOffers");
    },
  }
}

export default offers;
