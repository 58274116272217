class DeviceStorage {
  constructor() {
  }

  get abTestData() {
    const stored = JSON.parse(localStorage.getItem("abTestData") || "{}");
    const now = new Date().getTime();
    if (stored && stored.data && stored.createdTime && now - stored.createdTime < 20*60*1000) {
      return stored.data;
    } else {
      localStorage.removeItem("abTestData");
      return false;
    }
  }
  set abTestData(value) {
    let data = {
      createdTime: new Date().getTime(),
      data: value,
    }
    localStorage.setItem("abTestData", JSON.stringify(data));
  }

  get activeCarId() {
    return parseInt(localStorage.getItem("activeCarId"), 10) || 0;
  }
  set activeCarId(value) {
    value = parseInt(value || 0, 10);
    if (value) {
      localStorage.setItem("activeCarId", value);
    } else {
      localStorage.removeItem("activeCarId");
    }
  }

  /** @fixme На самом деле этот метод можно было назвать getOffers, тк хранит он в себе офферы, а не calculations. */
  get chosenCalcs() {
    return JSON.parse(localStorage.getItem("storedICIds") || "{}");
  }
  set chosenCalcs({carId, company}) {
    let storedICIds = JSON.parse(localStorage.getItem("storedICIds") || "{}");
    storedICIds[carId] = company;
    localStorage.setItem("storedICIds", JSON.stringify(storedICIds));
  }

  get cohortId() {
    return localStorage.getItem("cohortId");
  }
  set cohortId(value) {
    localStorage.setItem('cohortId', value);
  }

  get companies() {
    let data = JSON.parse(localStorage.getItem("companies") || "{}");
    const now = new Date().getTime();
    data.needToUpdate = (now - (data.updatedAt || 0)) > 60*60*1000;
    return data;
  }
  set companies(companies) {
    const data = {
      companies,
      updatedAt: new Date().getTime(),
    }
    localStorage.setItem("companies", JSON.stringify(data));
  }

  get dataConfirmed() {
    return JSON.parse(localStorage.getItem("dataConfirmed") || "[]");
  }
  set dataConfirmed(carId) {
    let storedDataConfirmed = JSON.parse(localStorage.getItem("dataConfirmed") || "[]");
    if(storedDataConfirmed.indexOf(carId) === -1) {
      storedDataConfirmed.push(carId);
    }
    localStorage.setItem("dataConfirmed", JSON.stringify(storedDataConfirmed));
  }

  get entryPagePassed() {
    return localStorage.getItem("entryPagePassed") || false;
  }
  set entryPagePassed(value) {
    localStorage.setItem('entryPagePassed', value ? "1" : "");
  }

  get experimentId() {
    return localStorage.getItem("experimentId");
  }
  set experimentId(value) {
    localStorage.setItem('experimentId', value);
  }

  get filterType1() {
    return localStorage.getItem("enableFilterType1");
  }
  set filterType1(value) {
    localStorage.setItem('enableFilterType1', value);
  }

  get GBFeatures() {
    let data = JSON.parse(localStorage.getItem("GBFeatures") || "{}");
    const now = new Date().getTime();
    data.needToUpdate = (now - (data.updatedAt || 0)) > 60*60*1000;
    return data;
  }
  set GBFeatures(features) {
    const data = {
      features,
      updatedAt: new Date().getTime(),
    }
    localStorage.setItem("GBFeatures", JSON.stringify(data));
  }

  get oldPolicyInfo() {
    const data = JSON.parse(localStorage.getItem("oldPolicyInfo") || "[]");
    return (data && data.insuranceCompanyId) ? data : null;
  }
  set oldPolicyInfo(oldPolicyInfo) {
    if (typeof oldPolicyInfo !== "undefined") {
      localStorage.setItem("oldPolicyInfo", JSON.stringify(oldPolicyInfo));
    } else {
      localStorage.removeItem("oldPolicyInfo");
    }
  }

  get RSAData() {
    return JSON.parse(localStorage.getItem("RSAData")) || {};
  }

  set RSAData(data) {
    localStorage.setItem("RSAData", JSON.stringify(data));
  }

  /**
   * @return String (важно отличать 0 от "нет данных" (пустой строки)  )
   */
  get quantityPayments() {
    return localStorage.getItem("quantityPayments") || "";
  }

  set quantityPayments(quantityPayments) {
    quantityPayments = parseInt(quantityPayments, 10) || 0;
    if (!isNaN(quantityPayments)) {
      localStorage.setItem("quantityPayments", String(quantityPayments));
    }
  }

  set quantityPaymentTimestamp(ts) {
    localStorage.setItem("quantityPaymentsTs", ts);
  }

  get quantityPaymentTimestamp() {
    return localStorage.getItem("quantityPaymentsTs");
  }

  get savedChosenCompanyId() {
    return parseInt(localStorage.getItem("savedChosenCompanyId"), 10) || 0;
  }
  set savedChosenCompanyId(value) {
    localStorage.setItem("savedChosenCompanyId", parseInt(value || 0, 10))
  }

  get showAddInsCalcsToPay() {
    return localStorage.getItem("showAddInsCalcsToPay");
  }
  set showAddInsCalcsToPay(value) {
    if (value) {
      localStorage.setItem('showAddInsCalcsToPay', value);
    } else {
      localStorage.removeItem('showAddInsCalcsToPay');
    }
  }

  get showAppHeader() {
    return parseInt(localStorage.getItem("showAppHeader"), 10) || 0;
  }
  set showAppHeader(value) {
    localStorage.setItem('showAppHeader', value);
  }

  get source() {
    return localStorage.getItem("source");
  }
  set source(value) {
    localStorage.setItem('source', value);
  }

  get utmMarketing() {
    return JSON.parse(localStorage.getItem("utmMarketing") || "[]");
  }
  set utmMarketing(utmMarketing) {
    localStorage.setItem("utmMarketing", JSON.stringify({utmMarketing}));
  }

}

const DeviceStorageInstance = new DeviceStorage();

export default DeviceStorageInstance;
