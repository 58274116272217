<template>
  <div
    class="switchbox"
    @focusin="onFocus"
    :data-field="id"
  >
    <div
      class="form-check-label"
    >
      <span v-html="label"/>&nbsp;
      <a
        v-if="modal"
        @click="$showModal(modal)"
        href="javascript:;"
      >
        <i class="svgimg svg-help svgimg_inline" />
      </a>
    </div>
    <ul
      :id="`${id}-pills-tab`"
      class="nav nav-pills"
      role="tablist"
    >
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{active: switchboxValue}"
          data-toggle="pill"
          :aria-selected="switchboxValue ? true: false"
          href="javascript:;"
          @click="switchboxValue = true"
        >
          {{ labelTrue }}
        </a>
      </li>
      <li class="nav-item">
        <a
          id="pills-body-tab"
          class="nav-link"
          :class="{active: !switchboxValue}"
          data-toggle="pill"
          :aria-selected="!switchboxValue ? true: false"
          href="javascript:;"
          @click="switchboxValue = false"
        >
          {{ labelFalse }}
        </a>
      </li>
    </ul>
    <div v-if="serverValidation && !wasFocused" class="invalid-feedback--vue js_invalid_feedback">{{serverValidation && serverValidation[0] || serverValidation}}</div>
  </div>
</template>

<script>
export default {
  props: {
    fontSize: {
      require: false,
      type: Number,
      default: 16,
    },
    id: {
      type: String,
      require: true,
      default: null,
    },
    label: {
      type: String,
      require: true,
      default: null,
    },
    labelTrue: {
      type: String,
      require: true,
      default: null,
    },
    labelFalse: {
      type: String,
      require: true,
      default: null,
    },
    modelValue: {
      type: Boolean
    },
    modal: {
      type: String,
      default: null
    },
    serverValidation: {
      type: Array,
      default: null,
    },
  },
  emits: [
    'change',
    'update:modelValue'
  ],
  data() {
    return {
      switchboxValue: null,
      wasFocused: false,
    }
  },
  watch: {
    switchboxValue(val, oldVal) {
      this.$emit('update:modelValue', val);
      if (typeof oldVal === 'boolean') {
        this.$emit('change', val);
      }
    },
    modelValue(val) {
      this.switchboxValue = val;
    }
  },
  mounted() {
    this.switchboxValue = this.modelValue;
  },
  methods: {
    onFocus() {
      this.wasFocused = true;
    },
    onChange(event) {
      this.$emit('change', event.target.checked )
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.form-check-label {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
}
.switchbox {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  @include view-lg {
    margin-top: 32px;
    margin-bottom: 16px;
  }
}
.nav-pills {
  margin-left: 7px;
  @include view-lg {
    margin-left: 16px;
  }
  .nav-link {
    font-weight: normal;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
</style>
