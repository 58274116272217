import {scrollToError} from "@/services/field-validation.helper";
import Swal from "sweetalert2";
import {statService} from "@/services/statService";

class SwalHelper {
  constructor() {
  }

  showSwal(title = null, text = null, type = "error", cancelButtonText = 'OK') {
    const swal = Swal.fire({
      title: title || "Ошибка",
      html: text || "Что-то пошло не так при оформлении полиса ОСАГО. Попробуйте повторить операцию.",
      type,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText,
      customClass: {
        cancelButton: 'cancel-main',
      }
    });
    let eventData = {
      fieldValue: text,
    };
    statService.setStatServiceDataParam({query: {}}, eventData);
    statService.logEvent(`[Ситуация] Показана модалка [${title}]`, eventData);
    return swal;
  }

  showErrorBox(error) {
    let text = error?.status === 429
      ? 'Упс, что-то пошло не так. Мы уже разбираемся'
      : error
    return this.showSwal("Ошибка", text, "error");
  }

  showInfoBox(message) {
    return this.showSwal("Информация", message, "info");
  }

  showResponseError(error) {
    if (error.toString() === 'Error: Network Error') {
      if (!navigator.onLine) {
        this.showSwal("Ошибка", "Проверьте соединение с Интернетом.", "error");
        return;
      }
    }

    let isUnknownError = true;
    let errorMsg = "";

    if(error.status !== 200){
      if(error?.data?.fields) {
        isUnknownError = false;
        for (let i in error.data.fields) {
          errorMsg += "<br />" + error.data.fields[i][0];
        }
      } else if (error?.data?.data?.fields) {
        isUnknownError = false;
        for (let i in error.data.data.fields) {
          errorMsg += "<br />" + error.data.data.fields[i][0];
        }
      } else if (error?.status === 409) {
        isUnknownError = false;
        errorMsg = 'У вас уже есть автомобиль с такими данными';
      }
    }


    if (!isUnknownError && errorMsg) {
      this.showSwal("Ошибка", errorMsg, "error");

    } else {

      // TODO: Проверка на интернет имеет ложнве срабатывание про ошибке на беке или редиректе, проверять по другому
      /*if (!error.response) {
          showSwal("Нет соединения с интернетом", "Проверьте ваше подключение к интернету и повторите попытку", "info");
      } else {
          showSwal("Ошибка", "Что-то пошло не так при оформлении полиса ОСАГО. Попробуйте повторить операцию.", "error");
      }*/
      this.showSwal("Ошибка", "Что-то пошло не так при оформлении полиса ОСАГО. Попробуйте повторить операцию.", "error");

      setTimeout(() => {
        scrollToError();
      }, 200);
    }
  }
}

const swalHelper = new SwalHelper();

export default swalHelper;
