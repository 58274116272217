import { PAGES_NAMES } from '@/constants/flowMap';

const map = {
  "car": {
    "carModification": {
      "brand": PAGES_NAMES.CAR_DATA,
      "brandId": PAGES_NAMES.CAR_DATA,
      "modelId": PAGES_NAMES.CAR_DATA,
      "model": PAGES_NAMES.CAR_DATA,
      "power": PAGES_NAMES.CAR_DATA
    },
    "sts": {
      "number": PAGES_NAMES.CAR_DATA,
      "date": PAGES_NAMES.CAR_DATA,
    },
    "pts": {
      "number": PAGES_NAMES.CAR_DATA,
      "date": PAGES_NAMES.CAR_DATA,
    },
    "epts": {
      "number": PAGES_NAMES.CAR_DATA,
      "date": PAGES_NAMES.CAR_DATA,
    },
    "vin": PAGES_NAMES.CAR_DATA,
    "bodyNumber": PAGES_NAMES.CAR_DATA,
    "productionYear": PAGES_NAMES.CAR_DATA,
    "withTrailer": PAGES_NAMES.CAR_DATA,
    "isTaxi": PAGES_NAMES.CAR_DATA,
  },
  "isOwnerPolicyholder": PAGES_NAMES.OWNER,
  "autoOwner": {
    "companyShortName": PAGES_NAMES.OWNER,
    "companyFullName": PAGES_NAMES.OWNER,
    "ogrn": PAGES_NAMES.OWNER,
    "inn": PAGES_NAMES.OWNER,
    "kpp": PAGES_NAMES.OWNER,
    "isRegistrationCertificateMissing": PAGES_NAMES.OWNER,
    "registrationCertificateNumber": PAGES_NAMES.OWNER,
    "registrationCertificateIssueDate": PAGES_NAMES.OWNER,
    "companyRegistrationAddress": PAGES_NAMES.OWNER,
    "egrul": PAGES_NAMES.OWNER,
    "firstName": PAGES_NAMES.OWNER,
    "secondName": PAGES_NAMES.OWNER,
    "lastName": PAGES_NAMES.OWNER,
    "passportNumber": PAGES_NAMES.OWNER,
    "passportIssueDate": PAGES_NAMES.OWNER,
    "registrationAddress": PAGES_NAMES.OWNER,
    // "registrationAddress": {
    //   "address": PAGES_NAMES.OWNER,
    //   "city": PAGES_NAMES.OWNER,
    //   "cityCode": PAGES_NAMES.OWNER,
    //   "street": PAGES_NAMES.OWNER,
    //   "houseNumber": PAGES_NAMES.OWNER,
    //   "buildingNumber": PAGES_NAMES.OWNER,
    //   "flatNumber": PAGES_NAMES.OWNER,
    //   "cityUuid": PAGES_NAMES.OWNER,
    //   "streetCode": PAGES_NAMES.OWNER,
    //   "fiasId": PAGES_NAMES.OWNER
    // },
    "birthDate": PAGES_NAMES.OWNER
  },
  "policyholder": {
    "companyShortName": PAGES_NAMES.OWNER,
    "companyFullName": PAGES_NAMES.OWNER,
    "ogrn": PAGES_NAMES.OWNER,
    "inn": PAGES_NAMES.OWNER,
    "kpp": PAGES_NAMES.OWNER,
    "isRegistrationCertificateMissing": PAGES_NAMES.OWNER,
    "registrationCertificateNumber": PAGES_NAMES.OWNER,
    "registrationCertificateIssueDate": PAGES_NAMES.OWNER,
    "companyRegistrationAddress": PAGES_NAMES.OWNER,
    "egrul": PAGES_NAMES.OWNER,
    "firstName": PAGES_NAMES.OWNER,
    "secondName": PAGES_NAMES.OWNER,
    "lastName": PAGES_NAMES.OWNER,
    "passportNumber": PAGES_NAMES.OWNER,
    "passportIssueDate": PAGES_NAMES.OWNER,
    "registrationAddress": PAGES_NAMES.OWNER,
    // "registrationAddress": {
    //   "address": PAGES_NAMES.OWNER,
    //   "city": PAGES_NAMES.OWNER,
    //   "cityCode": PAGES_NAMES.OWNER,
    //   "street": PAGES_NAMES.OWNER,
    //   "houseNumber": PAGES_NAMES.OWNER,
    //   "buildingNumber": PAGES_NAMES.OWNER,
    //   "flatNumber": PAGES_NAMES.OWNER,
    //   "cityUuid": PAGES_NAMES.OWNER,
    //   "streetCode": PAGES_NAMES.OWNER,
    //   "fiasId": PAGES_NAMES.OWNER
    // },
    "birthDate": PAGES_NAMES.OWNER
  },
  "isUnlimitedDrivers": PAGES_NAMES.DRIVERS,
  "docType": PAGES_NAMES.CAR_DATA,
  "userStartDate": PAGES_NAMES.CALCULATIONS,
  "email": PAGES_NAMES.OWNER,
  "phone": PAGES_NAMES.OWNER,
  "drivers": {
    "firstName": PAGES_NAMES.DRIVERS,
    "secondName": PAGES_NAMES.DRIVERS,
    "lastName": PAGES_NAMES.DRIVERS,
    "birthDay": PAGES_NAMES.DRIVERS,
    "licenceNumber": PAGES_NAMES.DRIVERS,
    "licenceStartDate": PAGES_NAMES.DRIVERS,
    "isOnlyExperienceStartYear": PAGES_NAMES.DRIVERS,
    "experienceStartYear": PAGES_NAMES.DRIVERS,
    "experienceStartDate": PAGES_NAMES.DRIVERS,
    "isLicenceChanged": PAGES_NAMES.DRIVERS,
    "oldLicenceNumber": PAGES_NAMES.DRIVERS,
    "oldLicenceStartDate": PAGES_NAMES.DRIVERS,
    "oldLastName": PAGES_NAMES.DRIVERS,
  }
};

export default map;
