<template>
  <div
    class="info-block"
    :class="{
      'info-block_no-margin': noMargin,
    }"
  >
    <div class="info-block__ico">
      <svg v-if="altIcon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C20.4 8 24 11.6 24 16C24 20.4 20.4 24 16 24C11.6 24 8 20.4 8 16C8 11.6 11.6 8 16 8ZM16 6C10.5 6 6 10.5 6 16C6 21.5 10.5 26 16 26C21.5 26 26 21.5 26 16C26 10.5 21.5 6 16 6Z" fill="#448AFF"/>
        <path d="M16 21C16.5523 21 17 20.5523 17 20L17 16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16L15 20C15 20.5523 15.4477 21 16 21Z" fill="#448AFF"/>
        <path d="M16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11Z" fill="#448AFF"/>
      </svg>

      <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="white"/>
        <path d="M20.5517 12.2034C20.2323 11.9131 19.7356 11.9359 19.4437 12.2568L14.657 17.5277L12.3444 15.1767C12.0392 14.8675 11.5432 14.8628 11.2349 15.1673C10.9257 15.471 10.921 15.9677 11.2254 16.2769L14.1202 19.2195C14.2685 19.3702 14.4694 19.4541 14.6797 19.4541C14.6844 19.4541 14.6899 19.4541 14.6946 19.4549C14.9112 19.4502 15.1152 19.3576 15.2604 19.1976L20.605 13.3123C20.8962 12.9905 20.8726 12.4946 20.5517 12.2034Z" fill="#448AFF"/>
        <path d="M16 6C10.4857 6 6 10.4857 6 16C6 21.5143 10.4857 26 16 26C21.5143 26 26 21.5136 26 16C26 10.4864 21.5143 6 16 6ZM16 24C11.3408 24 8 20.66 8 16C8 11.34 11.3408 8 16 8C20.66 8 24 11.34 24 16C24 20.66 20.6592 24 16 24Z" fill="#448AFF"/>
      </svg>
    </div>
    <div
      class="info-block__text"
      :class="desc ? '' : 'd-flex flex-column justify-content-center'"
    >
      <div
        v-if="title"
        class="info-block__title"
      >
        {{ title }}
      </div>
      <div
        class="info-block__desc"
      >
        {{ desc }}
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBlock",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    desc: {
      type: String,
      required: true,
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
    altIcon: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";
.info-block {
  border-radius: 8px;
  background: #F0F6FF;
  display: flex;
  padding: 12px;
  margin: 0 0 16px;
  &_no-margin {
    margin: 0;
  }
  &__ico {
    margin: 0 8px 0 0;
  }
  &__title {
    color: $color-primary;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 133.333% */
    margin: 0 0 4px;
  }
  &__desc {
    color: $color-gray;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 138.462% */
  }
}
</style>
