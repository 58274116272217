<template>
  <div
    class="b_car_card"
    @click="onClick"
  >
    <div class="b_top">
      <div class="car-info">
        <div class="el_name">
          <img
            v-if="car.category === 'A'"
            alt=""
            class="el_icon"
            src="@/assets/img/landing2/moto-black.svg"
          >
          <img
            v-else
            alt=""
            class="el_icon"
            src="@/assets/img/icons/car.svg"
          >
          <span class="el_name_value">
            {{ getCardName(car) }}
          </span>
        </div>
        <div class="d-flex">
          <div
            v-if="car.vehiclePlate"
            class="vp-wrapper"
          >
            <div v-if="car.category === 'A'" class="el_number">
              <span class="el_number_numbers">{{ car.vehiclePlate.slice(0, 4) }}</span>
              <span>{{ car.vehiclePlate.slice(4, 6) }}</span>
              <span class="el_number_region">{{ car.vehiclePlate.slice(6) }}</span>
            </div>
            <div v-else class="el_number">
              <span>{{ car.vehiclePlate.slice(0, 1) }}</span>
              <span class="el_number_numbers">{{ car.vehiclePlate.slice(1, 4) }}</span>
              <span>{{ car.vehiclePlate.slice(4, 6) }}</span>
              <span class="el_number_region">{{ car.vehiclePlate.slice(6) }}</span>
            </div>
          </div>
          <div
            v-else
            class="no-plate-wrapper"
          >
            <div
              class="el_number el_number_no-plate"
            >
              <span>
                Без номера
              </span>
            </div>
            <a
              v-show="$route.name !== 'sellConfirmed'"
              class="el_number el_number__button"
              @click.stop.prevent="changeVehiclePlate(car.id, car.category === 'A' ? 'moto' : '')"
            >
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7725 7.04723C19.0758 7.35052 19.0758 7.84046 18.7725 8.14375L17.3493 9.5669L14.433 6.65061L15.8562 5.22747C16.1595 4.92418 16.6494 4.92418 16.9527 5.22747L18.7725 7.04723ZM5 18.9998V16.0835L13.6011 7.48243L16.5174 10.3987L7.91628 18.9998H5Z" fill="#448AFF"/>
                </svg>
              </span>
            </a>
          </div>

          <div
            v-if="car.status === 2"
            class="wrong-wp-warn"
          >
            Неверный<br>госномер
          </div>
        </div>
      </div>

      <img
        v-if="car.brand && car.status !== 2"
        class="el_car_logo_img"
        :src="'/images/car-logos/' + $filters.capitalize(car.brand).replace('/', '').replace(/\s/g, '') + '.png'"
        @error="$event.target.style.display = 'none'"
      >

      <template v-if="showIco">
        <div
          v-if="car.status === 1 && $route.name === 'sellConfirmed'"
        >
          <i
            class="ico-delete"
            @click="removeCarWithConfirm(car)"
          />
        </div>
        <div
          v-else
          class="arrow-right goto-car"
        />
      </template>
    </div>
    <div
      v-if="$slots.bottomRow"
      class="b_bot"
    >
      <slot name="bottomRow"></slot>
    </div>
  </div>

  <app-modal
    :id="`modal-sell-remove_${car.id}`"
    title="Точно удалить авто?"
    bottom-button-class="btn-warn"
    bottom-button-title="Удалить"
    @close="rejectRemoving"
  >
    <template #fullCustom>
      <p>
        После удаления авто оно будет недоступно в приложении. Не сможем присылать информацию о штрафах и продлении ОСАГО.
      </p>
      <button-component
        type="button"
        class="btn btn-lg btn-warn bg-white font-weight-bold"
        @click="confirmRemoving"
      >
        Удалить
      </button-component>
      <loading
        v-if="loading"
        :active="true"
      />
    </template>
  </app-modal>
</template>

<script>
import {statService} from "@/services/statService";
import swalHelper from "@/util/helpers/swal.helper";
import {mapActions} from "vuex";
import Loading from "vue-loading-overlay";

let resolveConfirmation = null;
let rejectConfirmation = null;

export default {
  name: 'CarCard',
  components: {
    Loading
  },
  props: {
    car: {
      type: Object,
      required: true,
    },
    showIco: {
      type: Boolean,
      default: true,
    }
  },
  emits: [
    'reload-recently',
    'click',
    'changeVehiclePlate',
  ],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      deleteCar: "cars/deleteCar",
      reloadCarsInfo: "cars/loadCarsInfo",
    }),
    getCardName(car) {
      if (car.brand && car.model) {
        return `${car.brand} ${car.model}`
      }

      if (car.vehiclePlate) {
        if(car.category === 'A') {
          return 'Мотоцикл'
        }
        return 'Автомобиль'
      }

      return `VIN: ${car.vin || 'Загружаем...'}`
    },
    async removeCarWithConfirm(car) {
      const confirmation = new Promise((resolve, reject) => {
        resolveConfirmation = resolve;
        this.rejectConfirmation = reject;
      });

      this.$showModal(`modal-sell-remove_${car.id}`);

      await confirmation.then(async () => {
        this.loading = true;
        let eventData = {};
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent('[Действие] Подтвердил удаление автомобиля на странице sold-car/confirmed', eventData);
        await this.deleteCar({carId: car.id}).catch((error) => {
          swalHelper.showResponseError(error);
        });
        this.$emit('reload-recently');
        this.$hideModal(`modal-sell-remove_${car.id}`);
        this.loading = false;
      }).catch(() => {});
    },

    confirmRemoving() {
      if (resolveConfirmation) {
        resolveConfirmation();
      }
    },

    rejectRemoving() {
      if (resolveConfirmation) {
        rejectConfirmation();
      }
    },

    onClick() {
      this.$emit('click');
    },

    changeVehiclePlate(carId, vehicleType) {
      this.$emit('changeVehiclePlate', {carId, vehicleType})
    }
  },
}
</script>
