<template>
  <div class="documents-links">
    <p>
      Нажимая на кнопку «Далее» или «Нет номера» вы принимаете
      <a href="https://rosfines.ru/licenses/osago-oferta/" target="_top">Пользовательское соглашение</a> и даёте
      <a href="https://rosfines.ru/licenses/osago-soglasie-na-obrabotku-pdn/" target="_top">согласие</a> на обработку персональных данных.
      Подробнее об обработке данных <a href="https://rosfines.ru/licenses/osago-politica-v-otnoshenii-obrabotki-personalnyh-dannyh/" target="_top">здесь</a>.
    </p>
    <popover-block
      ref="documentPopup"
      title=""
    >
      <iframe
        :src="popupDocumentLink"
        class="full-height"
        width="100%"
      />
    </popover-block>
  </div>
</template>

<script>
import PopoverBlock from '@/components/blocks/Popover';

export default {
  name: 'LegalDocuments',
  components: {
    PopoverBlock
  },
  props: {},
  data() {
    return {
        popupDocumentLink: '/oferta.html'
    }
  },
  methods: {
    showDocument(link){
        this.popupDocumentLink = link;
        this.$refs.documentPopup.show();
    }
  }
}
</script>

<style lang="scss" scoped>

.documents-links {
    text-align:left;
    color: #7F869C;
    font-size:13px;
    margin-top:12px;
}
.documents-links p{
    font-size:13px;
}
.full-height {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

iframe {
    border:none;
}
</style>
