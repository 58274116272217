import {CancelToken} from 'axios';
import api from "@rosfines/vue-common/common/api/osagoApi";
import {BACK_DATE_FORMAT, FRONT_DATE_FORMAT} from '@/constants/dateFormat';
import moment from "moment";


let cancelLoadingOffers = [];

const offer = {
  namespaced: true,
  state () {
    return {
      chosenOfferId: 0,
      requestedOffer: null,
    }
  },
  mutations: {
    saveChosenOfferId(state, id) {
      state.chosenOfferId = id;
    },
    setRequestedOffer(state, value) {
      state.requestedOffer = value;
    },
    clearRequestedOffer(state) {
      state.chosenOfferId = 0;
      state.requestedOffer = null;
    }
  },
  actions: {
    async getOfferById({state, dispatch, commit, rootState}, offerId) {
      commit("saveChosenOfferId", offerId);
      await dispatch("form/waitLoadingCarData", undefined, {root: true});
      if (state.chosenOfferId) {
        const carId = rootState.form.activeCarId;
        if (carId) {
          let queryParams = {};
          queryParams.utmMarketing = rootState.app.utmMarketing;
          const offer = await api.get(`/partner/car/${carId}/offer/${state.chosenOfferId}`, queryParams, undefined, {
            cancelToken: new CancelToken(function executor(c) {
              cancelLoadingOffers.push(c);
            })
          });
          if (api.isSuccess(offer)) {
            const currentStartDate = moment(offer.data.dateFrom, BACK_DATE_FORMAT);
            await dispatch("form/autoUpdateStartDate", undefined, {root: true});
            const closestStartDate = moment(rootState.form.activeCarData.startDate, FRONT_DATE_FORMAT);
            if(closestStartDate.isAfter(currentStartDate, 'D')) {
              offer.data.dateFrom = closestStartDate.format(BACK_DATE_FORMAT);
              offer.data.dateTo = closestStartDate.add(1, "year").subtract(1, "day").format(BACK_DATE_FORMAT);
              offer.data.dateIsChanged = true;
            }
            commit("setRequestedOffer", offer.data);
          }
        } else {
          throw new Error("{carId} missed #1");
        }
      } else {
        throw "{chosenOfferId} not passed";
      }
    },
  }
}

export default offer;
