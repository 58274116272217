import { defineAsyncComponent } from 'vue'
import AppInput from '@/components/fields/AppInput.vue';
import AppTextarea from '@/components/fields/AppTextarea.vue';
import AppTypeahead from '@/components/typeahead/AppTypeahead.vue';
import AppModal from '@/components/modals/AppModal.vue';
import PopupBlock from '@rosfines/vue-common/vue/components/misc/Popup';
import AppCheckbox from "@/components/fields/AppCheckbox";
import AppRadio from "@/components/fields/AppRadio";
import AppSwitchbox from "@/components/fields/AppSwitchbox";
import AppSelect from "@/components/fields/AppSelect";
import InputSwitch from "@/components/fields/InputSwitch";
import ButtonComponent from '@/components/button/button-component';
const SupportRequest = defineAsyncComponent(() => import('@/components/support/SupportRequest'));
import vClickOutside from 'v-click-outside'

export default {
  AppInput,
  AppTextarea,
  AppTypeahead,
  AppModal,
  PopupBlock,
  AppCheckbox,
  AppRadio,
  AppSwitchbox,
  AppSelect,
  InputSwitch,
  VButton: ButtonComponent,
  SupportRequest,
  vClickOutside,
}
