import urlHelper from "@/util/helpers/url.helper";
import finesApi from '@rosfines/vue-common/common/api/finesApi';
import osagoApi from '@rosfines/vue-common/common/api/osagoApi';
import {statService} from "@/services/statService";
import store from "@/store";
import DeviceStorage from "@/util/helpers/device-storage";
import sleep from '@/util/helpers/sleep';

export default {
  async run(silentMode = false) {
    const GET = urlHelper.parseQueryString(location.href).GET;

    if (GET.userId && GET.rand && GET.session) {
      const params = {response: GET};
      osagoApi.updateRequestParams(params, false);
    }
    store.commit("session/saveSourceFromQuery");
    if (!osagoApi.isAuth()) {
      const url = new URL(location.href);
      if (url.pathname === "/policy" && !url.searchParams.get("userId")) {
        //Возврат с платежной формы СК в браузер, где не авторизован
        //Проверка на userId нужна чтобы открывать ссылки, в которых передана авторизация
        //Пытаемся открыть приложение или перенаправить на страницу с навигацией
        let webQuery = ["path=policy"];
         url.searchParams.forEach((value, key) => {
          webQuery.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        });
        webQuery = encodeURIComponent(webQuery.join("&"));
        location.href = `rosfines://app/insurance?web_path=osago&web_query=${webQuery}`;
        setTimeout(() => {
          location.href = "/unauth-payment-result";
        },2000);
        await sleep(10000);
      }
    }
    await store.dispatch("auth", store.state.session.sourceFromQuery);

    if (GET.filterType === '1') {
      await store.dispatch("enableFilterType1");
    }

    if (GET.utm_marketing) {
      await store.dispatch("setUtmMarketing", GET.utm_marketing);
    }

    if (GET.showAppHeader) {
      const showAppHeader = GET.showAppHeader === "true" ? 1 : parseInt(GET.showAppHeader, 10);
      if (!isNaN(showAppHeader)) {
        await store.dispatch("setShowAppHeader", showAppHeader);
      }
    } else if (!GET.showAppHeader && GET.source) {
      //пытаемся отличить перезагрузку страницы от входа с виджета
      await store.dispatch("setShowAppHeader", 0);
    }

    if(GET.source) {
      DeviceStorage.source = GET.source;
      store.commit("session/isSourceUpdated", true);
    } else if (GET.utm_source && 'site' == GET.utm_source) {
      DeviceStorage.source = 'rosfines_ru';
      store.commit("session/isSourceUpdated", true);
    } else {
      if (!DeviceStorage.source) {
        DeviceStorage.source = finesApi.SOURCE_WEB_VERSION;
        store.commit("session/isSourceUpdated", true);
      }
    }

    statService.init();
    statService.sendLoadEvents(silentMode);
  }
}
