<template>
  <div
    v-if="matchedItems && matchedItems.length"
    class="dropdown-menu d-block shadow"
  >
    <vue-bootstrap-typeahead-list-item
      v-for="(item, id) in matchedItems"
      :key="id"
      :background-variant="backgroundVariant"
      :data="item.data ? item.data : item"
      :html-text="item.text ? highlight(item.text) : highlight(item)"
      :text-variant="textVariant"
      @click="handleHit(item, $event)"
    />
  </div>
</template>

<script>
import VueBootstrapTypeaheadListItem from "./AppTypeaheadListItem.vue";

function sanitize(text) {
  return text.toString().replace(/</g, "&lt;").replace(/>/g, "&gt;");
}

function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export default {
  components: {
    VueBootstrapTypeaheadListItem
  },
  props: {
    data: {
      type: Array,
      required: true,
      validator: d => d instanceof Array
    },
    query: {
      type: String,
      default: "",
    },
    backgroundVariant: {
      type: String,
      default: "",
    },
    textVariant: {
      type: String,
      default: "",
    },
    localDataFilter: {
      type: Boolean,
      default: false,
    },
    maxMatches: {
      type: Number,
      default: 10,
    },
    minMatchingChars: {
      type: Number,
      default: 1,
    }
  },
  emits: [
    'click',
    'hit',
  ],

  computed: {
    highlight() {
      return text => {
        text = sanitize(text);
        if (this.query.length === 0) {
          return text;
        }
        const re = new RegExp(this.escapedQuery, "gi");

        return text.replace(re, `<strong>$&</strong>`);
      };
    },

    escapedQuery() {
      return escapeRegExp(sanitize(this.query));
    },

    matchedItems() {
      if (this.query.length < this.minMatchingChars) {
        return [];
      }
      const query = String(this.query).toLowerCase();
      if (this.localDataFilter) {
        return this.data.filter((item) => !item.text || item.text && String(item.text).toLowerCase().indexOf(query) !== -1);
      } else {
        return this.data;
      }
    }
  },

  methods: {
    handleHit(item, evt) {
      this.$emit("hit", item);
      evt.preventDefault();
    }
  }
};
</script>
