import {statService} from "@/services/statService";
import swalHelper from "@/util/helpers/swal.helper";
import {mapActions, mapState} from "vuex";
import envHelper from '../util/helpers/environment.helper';

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      utmMarketing: state => state.app.utmMarketing,
    }),
  },
  methods: {
    ...mapActions({
      getPaymentLink: "offers/getPaymentLink",
      stopOffersActivity: "offers/stopOffersActivity",
    }),
    async _payOffer(offerId = undefined, waitForTracking = true) {
      this.loading = true;
      if (process.env.NODE_ENV === 'production' && waitForTracking) {
        window.ym(56687083, 'reachGoal', 'Purchase', {
          utmMarketing: this.utmMarketing,
        });
        window.gtag('event', 'conversion', {
          'send_to': 'AW-956464887/0nVMCIGxs_ECEPf9icgD',
          'transaction_id': ''
        });
        window._tmr && window._tmr.push({ type: "reachGoal", id: 3270047, goal: "open-payment-page"});
      }
      const paymentURL = await this.getPaymentLink(offerId);
      if (paymentURL) {
        this.stopOffersActivity();
        let eventData = {};
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent('[Ситуация] Получил ссылку на оплату', eventData);
        this.gotoPayform(paymentURL, waitForTracking);
      } else {
        swalHelper.showResponseError({});
        this.loading = false;
      }
    },
    gotoPayform(paymentURL, waitForTracking) {
      // TODO: перенести ссылку в стор и убрать waitForTracking
      // таймаут чтобы яндекс-метрика успела раздуплиться
      setTimeout(() => {
        if (
          envHelper.IS_ANDROID ||
          (envHelper.IS_IOS && (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.WebToNativeBridge))) {
          window.open(paymentURL, "_blank");
        } else {
          window.location.href = paymentURL;
        }
      }, waitForTracking ? 3000 : 0);
    },
  }
}
