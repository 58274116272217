import config from "@/config";
import api from "@rosfines/vue-common/common/api/osagoApi";
import DeviceStorage from "@/util/helpers/device-storage";

const getFilename = (path) => {
  const paths = path.split("/")
  if (paths.length > 0) {
    const name = paths[paths.length - 1];
    if (name && name.split) {
      return name.split(".")[0];
    }
  }
  return "";
};

const companies = {
  namespaced: true,
  state () {
    return {
      companies:[],
    }
  },
  getters: {
    getAllCompanies(state) {
      return state.companies.filter(item => item.isActive);
    },
    getICById: (state) => (id) => {
      return state.companies.find(item => item.id === id)
    },
  },
  mutations: {
    setCompaines(state, list) {
      list.map((item) => {
        const filename = getFilename(item.logo);
        item.logo_136 = filename ? `/images/logos-204/${filename}.png` : "";
        item.logo_40 = filename ? `${config.ASSETS_BASE_URL}/images/logos/logos-40/${filename}.svg` : "";
      });
      state.companies = list;
    }
  },
  actions: {
    async init({commit, dispatch}) {
      // isLoadingInsuranceСompanies фикс множественной отправки запросов
      const isLoading = Number(localStorage.getItem('isLoadingInsuranceСompanies'));
      const savedList = DeviceStorage.companies;
      if (savedList.companies) {
        commit("setCompaines", savedList.companies);
      }
      if (savedList.needToUpdate && !isLoading || isLoading && !savedList.companies) {
        localStorage.setItem('isLoadingInsuranceСompanies', '1')
        await dispatch("loadCompaines").catch(() => {
          localStorage.setItem('isLoadingInsuranceСompanies', '0')
        });
        localStorage.setItem('isLoadingInsuranceСompanies', '0')
      }
    },
    async loadCompaines({commit}) {
      const response = await api.get("/partner/insurance-companies").catch((e) => {
        console.error(e);
        throw "companies loading failed. code #1";
      });
      if (api.isSuccess(response)) {
        const list = response.data;
        commit("setCompaines", list);
        DeviceStorage.companies = list;
      } else {
        throw "companies loading failed. code #2";
      }
    }
  }
}

export default companies;
