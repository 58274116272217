export const CAROUSEL_ITEMS = [
  {
    title: 'Комиссия 0%',
    description: 'Мы показываем действующие цены страховых компаний и не берем комиссию',
  },
  {
    title: 'Экономия до 30%',
    description: 'Мы всегда следим за ценами и помогаем "поймать" самое выгодное предложение среди 20 + страховых',
  },
  {
    title: 'Передадим информацию',
    description: 'Мы сами зарегистрируем ваш полис в базе страховщиков и ГИБДД',
  },
  {
    title: 'Быстрое оформление',
    description: 'Заполним анкету автоматически, используя данные из РСА и ГИБДД',
  },
  {
    title: 'Всегда на связи',
    description: 'Больше не нужно заполнять анкету вручную. Загрузим данные из баз РСА и ГИБДД',
  },
]