<template>
  <section
    :data-field="id"
  >
    <Field
      ref="validationField"
      :name="id"
      :rules="rules"
      :value="modelValue"
    >
      <div class="form-check form-check-inline checkbox checkbox-primary" :data-field="id" @focusin="onFocus">
        <input
          v-if="inverted"
          :id="id"
          class="form-check-input"
          type="checkbox"
          :checked="!modelValue || defaultChecked"
          @change="onChange"
        >
        <input
          v-else
          :id="id"
          class="form-check-input"
          type="checkbox"
          :checked="modelValue"
          @change="onChange"
        >
        <label
          :style="{'fontSize': fontSize + 'px'}"
          :for="id"
          class="form-check-label"
        >
          {{ label }}
          <a
            v-if="labelModalId"
            href="javascript:;"
            @click="$showModal(labelModalId)"
          >
            <i class="svgimg svg-help svgimg_inline"></i>
          </a>
        </label>
      </div>
      <div v-if="serverValidation && !wasFocused" class="invalid-feedback--vue js_invalid_feedback">{{serverValidation && serverValidation[0] || serverValidation}}</div>
      <ErrorMessage
        v-if="!hideErrors"
        as="div"
        :name="id"
        class="invalid-feedback--vue js_invalid_feedback"
      />
    </Field>
  </section>
</template>

<script>
import {ErrorMessage, Field, useField} from "vee-validate";

export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    value: {
      require: true,
      type: Boolean,
      default: false
    },
    fontSize: {
      require: false,
      type: Number,
      default: 15
    },
    hideErrors: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      require: true,
      default: null,
    },
    label: {
      type: String,
      require: true,
      default: null,
    },
    labelModalId: {
      type: String,
      require: false,
      default: null,
    },
    inverted: {
      type: Boolean
    },
    defaultChecked: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    modelValue: {
      type: Boolean
    },
    serverValidation: {
      type: Array,
      default: null,
    },

  },
  data() {
    return {
      wasFocused: false,
      errorMessage: null,
    }
  },
  emits: [
    'change',
    'update:modelValue'
  ],
  mounted() {
    this.errorMessage = useField(this.id).errorMessage;
  },
  methods: {
    onFocus() {
      this.wasFocused = true;
    },
    onChange(event) {
      let checked = this.inverted ? !event.target.checked : event.target.checked;
      this.$emit('update:modelValue', checked);
      this.$emit('change', checked);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-check-label {
  font-weight: normal;
  font-size: 15px;
}
</style>
