<template>
  <a
    v-if="Object.keys(configForCurrentPage).length"
    :href="bannerHref"
    class="cashback-banner__wrapper"
    :class="{ 'mt-4': page === PAGES_WITH_PROMO_BANNER.OFFER && isMobileWidth}"
    @click="openModal"
  >
    <img
      :class="bannerImageDesktopClass"
      :src="configForCurrentPage?.desktopImageUrl"
      alt="cashback_banner"
    >

    <img
      :class="bannerImageMobileClass"
      :src="configForCurrentPage?.mobileImageUrl"
      alt="cashback_banner"
    >
  </a>

  <app-modal
    v-if="!configForCurrentPage.action"
    id="modal-cashback"
    :title="modalContent?.title"
  >
    <template #fullCustom>
      <div class="modal-cashback">
        <div class="modal-cashback__content">
          <p>{{ modalContent?.mainText }}</p>
        </div>

        <p class="modal-cashback__info">
          {{ modalContent?.resultDateText }}
        </p>
      </div>

      <div class="modal-cashback__footer">
        <a
          href="javascript:;"
          class="modal-cashback__rules"
          @click="openRules"
        >
          Правила акции
        </a>
      </div>

    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/AppModal.vue";
import payment from "@/mixins/payment";
import osagoApi from "@rosfines/vue-common/common/api/osagoApi";
import baseFinesApi from '@rosfines/vue-common/common/api/base/baseFinesApi';

import { PAGES_WITH_PROMO_BANNER } from '@/constants/pagesWithPromoBanner';

export default {
  name: "OsagoPromoBanner",
  components: {AppModal},
  mixins: [payment],
  props: {
    page: {
      type: String,
      required: true,
      validator: function (value) {
        return value in PAGES_WITH_PROMO_BANNER
      },
    },
  },
  data() {
    return {
      bannersConfig: null,

      userId: '',
      rand: '',
      session: '',
    }
  },
  computed: {
    configForCurrentPage() {
      if (!this.bannersConfig) return {};

      return this.bannersConfig[this.page] ?? {};
    },
    modalContent() {
      return this.configForCurrentPage.modal ?? {};
    },
    bannerHref() {
      if (!this.configForCurrentPage.action) return 'javascript:;';

      return `${this.configForCurrentPage.action}&userId=${this.userId}&rand=${this.rand}&session=${this.session}`;
    },
    bannerImageDesktopClass() {
      if ([this.PAGES_WITH_PROMO_BANNER.START, this.PAGES_WITH_PROMO_BANNER.CALCULATIONS].includes(this.page)) return 'desktop';

      if ([this.PAGES_WITH_PROMO_BANNER.OFFER, this.PAGES_WITH_PROMO_BANNER.ENTRY].includes(this.page)) return 'hidden';

      return 'desktop success';
    },
    bannerImageMobileClass() {
      if ([this.PAGES_WITH_PROMO_BANNER.START, this.PAGES_WITH_PROMO_BANNER.CALCULATIONS].includes(this.page)) return 'mobile';

      if ([this.PAGES_WITH_PROMO_BANNER.OFFER, this.PAGES_WITH_PROMO_BANNER.ENTRY].includes(this.page)) return 'middle';

      return 'mobile success';
    },
    PAGES_WITH_PROMO_BANNER() {
      return PAGES_WITH_PROMO_BANNER;
    },
    isMobileWidth() {
      return window.innerWidth < 992;
    },
  },
  async mounted() {
    await this.getPromoOsagoConfig();
  },
  methods: {
    async getPromoOsagoConfig() {
      const { userId, rand, session } = osagoApi.getRequestParams();

      this.userId = userId;
      this.rand = rand;
      this.session = session;

      const res = await baseFinesApi.get(`/feature/config/config/osago.banners.?userId=${userId}&rand=${rand}&session=${session}`);

      if (baseFinesApi.isSuccess(res)) {
        const { data: { response: { config }}} = res;

        this.bannersConfig = { ...config };
      }
    },
    openModal() {
      this.$showModal('modal-cashback');
    },
    openRules() {
      this.gotoPayform(this.modalContent?.promoRulesUrl);
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';
@import "@/assets/styles/_mixins.scss";

.cashback-banner__wrapper {
  display: block;
  max-width: 960px;
  margin: 0 auto 16px;
  .desktop {
    display: none;
    @include view-lg {
      display: block;
      width: 100%;
    }
    &.success {
      margin-top: 16px;
      max-height: 105px;
    }
  }
  .mobile {
    display: block;
    width: 100%;
    max-height: 88px;
    object-fit: contain;
    @include view-lg {
      display: none;
    }
    &.success {
      margin-top: 16px;
      max-height: 105px;
    }
  }
  .middle {
    display: block;
    width: 100%;
    object-fit: contain;
    border-radius: 12px;
  }
  @include view-lg {
    margin-bottom: 32px;
  }
}

.modal-cashback {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  padding: 24px;
  border-radius: 16px;
  background: #FFFFFF;
  &__content {
    display: flex;
    column-gap: 8px;
    align-items: center;
    p {
      color: #20273D;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 0;
    }
    svg {
      min-width: 24px;
    }
  }
  &__info {
    color: #777E96;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
  }
  &__rules {
    color: #777E96;
    font-size: 13px;
    line-height: 20px;
    text-decoration-line: underline;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__footer {
    display: flex;
    justify-content: center;
    @include view-lg {
      justify-content: start;
    }
  }
}

.hidden {
  display: none;
}
</style>
