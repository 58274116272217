import api from "@rosfines/vue-common/common/api/osagoApi";
import moment from "moment";
import DeviceStorage from "@/util/helpers/device-storage";
import { BACK_DATE_FORMAT } from '@/constants/dateFormat';

const rsa = {
  namespaced: true,
  state() {
    return {
      data: {}
    }
  },
  getters: {
    policyExpirationDate(state) {
      return state.data && state.data.policyExpirationDate || false;
    },
  },
  mutations: {
    setRSAData(state, value) {
      state.data = value;
    },
  },
  actions: {
    async getRSAData({commit, dispatch, rootState}) {
      try {
        await dispatch("form/waitLoadingCarData", undefined, {root: true});
        const storedRSAData = DeviceStorage.RSAData;
        const carId = rootState.form.activeCarData.car.id;
        if (storedRSAData && storedRSAData.car && storedRSAData.car.id &&
          storedRSAData.car.id === carId && storedRSAData.policyExpirationDate &&
          moment(storedRSAData.policyExpirationDate, BACK_DATE_FORMAT).isAfter(moment())
        ) {
          commit("setRSAData", storedRSAData);
        } else {
          await dispatch("_requestRSAData", carId).catch(e => {
            throw new Error(e)
          });
        }
      } catch (e) {
        commit("setRSAData", null);
      }
    },
    async _requestRSAData({commit, dispatch}, carId) {
      try {
        await dispatch("form/waitLoadingCarData", undefined, {root: true});
        if (carId) {
          const response = await api.get(`/rsa/by-car-id/${carId}/last-policy`).catch(e => {
            throw new Error(e)
          });
          if (api.isSuccess(response)) {
            commit("setRSAData", response.data);
            DeviceStorage.RSAData = response.data;
          }
        }
      } catch (e) {
        commit("setRSAData", null);
      }
    },
  }
}

export default rsa;

