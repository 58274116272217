<template>
  <ul
    v-show="showPills"
    id="pills-tab"
    class="nav nav-pills mb-3"
    role="tablist"
  >
    <li class="nav-item">
      <a
        id="pills-vin-tab"
        class="nav-link"
        :class="{active: !carData.noVin}"
        data-toggle="pill"
        href="#pills-vin"
        role="tab"
        aria-controls="pills-vin"
        aria-selected="true"
        @click="onChangeNoVin(false)"
      >
        VIN
      </a>
    </li>
    <li class="nav-item">
      <a
        id="pills-body-tab"
        class="nav-link"
        :class="{active: carData.noVin}"
        data-toggle="pill"
        href="#pills-body"
        role="tab"
        aria-controls="pills-body"
        aria-selected="false"
        @click="onChangeNoVin(true)"
      >
        Номер кузова
      </a>
    </li>
  </ul>

  <div
    v-if="isVisibleVinText"
    class="inform_text inform_text_warning"
  >
    <div class="el__content">
      <p class="el_text">
        Вы уже ввели VIN. Для расчета достаточно одного идентификатора.
      </p>
    </div>
  </div>

  <template v-if="carData.noVin">
    <AppInput
      id="bodyNumber"
      key="bodyNumber"
      v-model="bodyNumber"
      mask="bodyNumber"
      label="Номер кузова"
      rules="required|bodyNumber"
      type="text"
      :max_length="20"
      uppercase="true"
      modal="modal-question-body-number"
      :server-validation="serverValidations.car && serverValidations.car.bodyNumber"
      @on-model-value-change="onModelValueChange"
    />
    <div
      v-if="showInformTexts"
      class="inform_text_notice"
    >
      <div class="el__content">
        <p class="el_text">
          Ввод доступен только латинскими буквами и&nbsp;цифрами
        </p>
      </div>
      <div class="d-none d-lg-block pb-lg-3"></div>
    </div>
  </template>
  <template v-else>
    <AppInput
      id="vin"
      key="vin"
      v-model="vin"
      :class="{'mt-3': !showPills}"
      mask="vinNumber"
      :label="vinLabel"
      rules="required|vinLength|vinNoOnlyNumbers|vinLast4Numbers|vinNotContaintsMoreThan8Symbols|vinLatinAndNumbersOnly|vin"
      uppercase="true"
      :max_length="17"
      modal="modal-question-vin"
      :server-validation="serverValidations.car && serverValidations.car.vin"
      @on-model-value-change="onModelValueChange"
    />
    <div
      v-if="showInformTexts"
      class="inform_text_notice"
    >
      <div class="el__content">
        <p
          class="el_text"
          :class="{'mb-0': noGaps}"
        >
          Ввод&nbsp;доступен только латинскими буквами и&nbsp;цифрами
        </p>
      </div>
    </div>
  </template>
</template>

<script>
import statEventsHelper from "@/util/helpers/statEvents.helper";
import {mapGetters} from "vuex";

export default {
  name: "VinInput",
  props: {
    screen: {
      type: String,
      required: true,
    },
    carData: {
      type: Object,
      required: true,
      default: () => {},
    },
    noGaps: {
      type: Boolean,
      default: false,
    },
    showPills: {
      type: Boolean,
      default: true,
    },
    vinLabel: {
      type: String,
      default: 'VIN',
    },
    showInformTexts: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'onChangeNoVin',
    'changeVin',
    'changeBodyNumber',
    'onModelValueChange',
  ],
  data() {
    return {
        vin: this.carData.vin,
        bodyNumber: this.carData.bodyNumber,
    }
  },
  computed : {
    ...mapGetters({
      "serverValidationFull": "validation/pagesWithErrors",
    }),
    isVisibleVinText() {
      return this.carData.vin && this.carData.noVin && /^([a-zA-Z0-9]){8,17}$/.test(this.carData.vin);
    },
    serverValidations() {
      return this.serverValidationFull && this.serverValidationFull.carData || {};
    },
  },
  watch: {
    vin(newVin) {
      this.$emit('changeVin', newVin);
    },
    bodyNumber(newBodyNumber) {
      this.$emit('changeBodyNumber', newBodyNumber);
    },
  },
  methods: {
    onChangeNoVin(value) {
      this.$emit('onChangeNoVin', value)
      statEventsHelper.switchButtonEvent(this.carData.noVin ? "Номер кузова" : "VIN", this.screen);
      this.onModelValueChange();
    },
    onModelValueChange() {
      this.$emit('onModelValueChange');
    }
  },
}
</script>
