<template>
  <div class="now-online">
    Сейчас подбирают полис: {{ online }} {{ $filters.pluralize(online, ['человек', 'человека', 'человек']) }}
  </div>
</template>

<script>
export default {
  computed: {
    online() {
      return 100 + Math.ceil(500 * Math.random())
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';
@import "@/assets/styles/_mixins.scss";

.now-online {
  border-radius: 12px;
  background: #7256D1;
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: $color-white;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px; /* 145.455% */
  letter-spacing: 0.2px;
  margin: 0 0 12px;
  flex: 0 1 auto;
  @include view-lg {
    margin: 0 0 8px;
  }
}
</style>
