import api from "@rosfines/vue-common/common/api/osagoApi";
import { createStore } from 'vuex'
import app from "@/store/app";
import abtest from "@/store/abtest";
import actualPolicy from "@/store/actualPolicy";
import calculations from "@/store/calculations";
import cars from "@/store/cars";
import companies from "@/store/companies";
import form from "@/store/form";
import GBFeatures from '@/store/GBFeatures';
import offer from "@/store/offer";
import offers from "@/store/offers";
import policy from "@/store/policy";
import products from "@/store/products";
import productsCalcs from '@/store/productsCalcs';
import productsOffers from '@/store/productsOffers';
import recentPolicies from "@/store/recentPolicies";
import rsa from "@/store/rsa";
import session from "@/store/session";
import support from "@/store/support";
import validation from "@/store/validation";
import notifications from "@/store/notifications";
import swalHelper from '../util/helpers/swal.helper';
import {statService} from "@/services/statService";

export const store = createStore({
  // strict: process.env.NODE_ENV !== 'production',
  modules: {
    app,
    abtest,
    actualPolicy,
    calculations,
    cars,
    companies,
    form,
    GBFeatures,
    offer,
    offers,
    policy,
    products,
    productsCalcs,
    productsOffers,
    recentPolicies,
    rsa,
    session,
    support,
    validation,
    notifications
  },

  state: {},
  getters: {},
  mutations: {},
  actions: {
    auth(context, source) {
      if (!api.isAuth()) {
        statService.logEvent(`[Переход к регистрации] Referer: ${document.referrer || "Direct Traffic"}`)
        api.authV2(source).catch((e) => swalHelper.showErrorBox(e));
      }
    }
  }
});

export default store;
