import api from "@rosfines/vue-common/common/api/osagoApi";
import supportApi from '@rosfines/vue-common/common/api/supportApi';

const support = {
  namespaced: true,
  state() {
    return {
      entrypoints: {},
      supportData: {},
    }
  },
  getters: {
    ifSupportWidgetEnabled: (state) => (widgetCode) => {
      return !!state.entrypoints[widgetCode];
    },
    hasSupportTickets(state) {
      return state.supportData.hasTickets || false;
    },
    supportNotificationsCount(state) {
      return state.supportData?.unreadCount || 0;
    }
  },
  mutations: {
    setEntrypoint(state, value) {
      state.entrypoints = {
        ...state.entrypoints,
        ...value,
      };
    },
    setSupportData(state, value) {
      state.supportData = value;
    }
  },
  actions: {
    async getSupportWidget({commit}, widgetCode) {
      const response = await supportApi.get(`/widget/by/${widgetCode}`).catch((e) => {
        console.error(e);
        throw new Error("getting support widget failed");
      });
      if (api.isSuccess(response)) {
        const widget = response.data.widget;
        let newData = {};
        newData[widgetCode] = widget;
        commit("setEntrypoint", newData);
      } else {
        throw new Error("support widget parsing failed");
      }
    },
    async getSupportSummary({commit}) {
      let data = {};
      const response = await supportApi.get("/notifications/stat/count/tickets").catch(async () => {
        throw new Error('failed loading notifications');
      });
      if (supportApi.isSuccess(response)) {
        data.hasTickets = !!response.data.allTicketsCount;
        data.unreadCount = response.data.ticketsWithNewMessagesCount;
      } else {
        data.hasTickets = false;
        data.unreadCount = 0;
      }
      commit("setSupportData", data);
    },
  },
}

export default support;

