export const PAGES_NAMES = {
  CAR_DATA: "carData",
  DRIVERS: "drivers",
  OWNER: "owner",
  CALCULATIONS: "calculations",
  OFFERS: "offers",
  ADDITIONAL_SERVICES: 'additionalServices',
  ADDITIONAL_FUNNEL: 'additionalFunnel',
}

export const DEFAULT_PAGE = PAGES_NAMES.CALCULATIONS;

export const PAGES_ORDER = {
  [PAGES_NAMES.CAR_DATA]: 1,
  [PAGES_NAMES.DRIVERS]: 2,
  [PAGES_NAMES.OWNER]: 3,
  [PAGES_NAMES.CALCULATIONS]: 4,
  [PAGES_NAMES.OFFERS]: 5,
}

export const FORM_STEPS_ORDER = [
  PAGES_NAMES.CAR_DATA,
  PAGES_NAMES.DRIVERS,
  PAGES_NAMES.OWNER,
]

export const FORM_STEPS_ORDER_LEGAL_ENTITY = [
  PAGES_NAMES.CAR_DATA,
  PAGES_NAMES.OWNER,
  PAGES_NAMES.DRIVERS,
]

export const GET_PREV_PAGE = (page) => {
  if (PAGES_ORDER[page]) {
    const currentOrder = PAGES_ORDER[page];
    let closestOrder = 0;
    let closestPage = false;
    for (const i in PAGES_ORDER) {
      if (PAGES_ORDER[i] < currentOrder && PAGES_ORDER[i] > closestOrder) {
        closestOrder = PAGES_ORDER[i];
        closestPage = i;
      }
    }
    return closestPage;
  } else if (PAGES_ORDER[page] !== DEFAULT_PAGE) {
    return DEFAULT_PAGE
  } else {
    return false;
  }
}
