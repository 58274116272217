<template>
  <app-modal
    id="questions-and-answers-modal"
    title="Вопросы и ответы"
  >
    <template #modalContent>
      <div
        v-for="question in sectionQuestions"
        :key="question.id"
        class="faq-question-wrapper"
      >
        <Disclosure v-slot="{ open }">
          <DisclosureButton class="faq-question-header" @click.once="openQuestion(question.question)">
            <span>{{ question.question }}</span>

            <svg v-if="open" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.39509 13.068L9.35402 13.068L9.37475 13.0883L4.69501 17.7499L2.36523 15.6553L11.5247 6.70406L20.6841 15.6553L18.5098 17.7539L18.4784 17.7424L11.5247 10.9467L9.39509 13.068Z" fill="#448AFF"/>
            </svg>

            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M13.6547 11.391L13.6958 11.391L13.6751 11.3707L18.3548 6.70912L20.6846 8.80365L11.5251 17.7549L2.36569 8.80365L4.53999 6.70508L4.57143 6.71662L11.5251 13.5123L13.6547 11.391Z" fill="#448AFF"/>
            </svg>
          </DisclosureButton>
          <DisclosurePanel>
            <disclosure-button class="faq-question-body">
              <span>{{ question.answer }}</span>
            </disclosure-button>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </template>
  </app-modal>
</template>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";
import {FAQ_QUESTIONS} from "@/constants/faqQuestions";
import {statService} from "@/services/statService";

export default {
  name: "QuestionsAndAnswersModal",
  components: {
    DisclosurePanel,
    DisclosureButton,
    Disclosure,
  },
  props: {
    pageName: {
      type: String,
      default: 'calculations',
    },
  },
  computed: {
    sectionQuestions() {
      return FAQ_QUESTIONS[this.pageName].questions
    },
  },
   methods: {
    openQuestion(question) {
      statService.logEvent(`[Ситуация] Юзер открыл вопрос "${question}" на странице ${this.$route.name}`, {});
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.faq-question-wrapper {
  display: block;
  border-radius: 12px;
  background: $color-gray-white;
  border: 0;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
  @include view-lg {
    width: 100%;
    max-width: calc(992px - (8 * $pixel-size));
    background: $color-gray-white;
    border-radius: 12px;
    margin: 0 auto 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.faq-question-header {
  border: 0;
  appearance: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  padding: 16px;
  width: 100%;
  text-align: start;
  color: $color-dark;

  @include view-lg {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
  span {
    max-width: 680px;
  }
  svg {
    min-width: 24px;
    min-height: 24px;
    margin-left: 8px;
  }
}

.faq-question-body {
  font-family: inherit;
  color: #777E96;
  padding: 0 16px 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  span {
    display: block;
    max-width: 680px;
  }
}
</style>
