<template>
  <div class="container-fluid">
    <div
      class="support-request-wrapper"
    >
      <div
        class="support-request"
        :class="{
          'support-request_toggled': toggled
        }"
      >
        <template v-if="isSent">
          <div class="support-request__title-send">Сообщение успешно отправлено <img src="@/assets/img/icons/check_green.svg" alt=""></div>
          <div class="support-request__congrats">Благодарим вас за обратную связь! Мы обязательно учтем ваши пожелания.</div>
        </template>
        <template v-else>
          <div
            class="support-request__title support-request__toggle"
            @click="toggleBlock"
          >
            Сложности при оформлении?
          </div>
          <template v-if="toggled">
            <div
              v-if="showNotificationsBlock"
              class="all-notifications_wrapper"
            >
              <div class="all-notifications">
                <input-switch
                  :id="getAllNotificationsObj.key"
                  v-model="getAllNotificationsObj.value"
                  @change="changeNotifications({
                    value: !getAllNotificationsObj.value,
                    type: 'insuranceOsago',
                    key: getAllNotificationsObj.key
                  })"
                />
                <span>Все уведомления</span>
                <a
                  class="tooltip"
                  href="javascript:;"
                  @click="$showModal('modal-osagoNotifications')"
                >
                  <i class="svgimg svg-help tooltip_icon" />
                </a>
              </div>
              <p class="support-request__desc">Отключите настройку, если уже оформили полис ОСАГО или продали автомобиль, чтобы не получать от нас уведомления по страхованию</p>
              <hr
                class="notify-sepa"
              >
            </div>
            <div class="support-request__title mb-2">{{ showNotificationsBlock ? "Или оставьте сообщение" : "Оставьте сообщение" }}</div>
            <div class="support-request__desc">Пожалуйста, опишите свою проблему, чтобы мы смогли стать еще лучше</div>
            <div class="support-request__text-wrapper">
              <textarea
                id="request-text"
                v-model="requestText"
                placeholder="Ваше сообщение"
                class="form-control form-control-lg"
                :disabled="sending"
              ></textarea>
            </div>
            <div class="support-request__submit">
              <v-button
                :loading="sending"
                @click="submitForm"
              >
                Отправить
              </v-button>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>

  <loading
    :active="loading"
    :is-full-page="true"
  />

  <app-modal
    id="modal-osagoNotifications"
    title="Уведомления ОСАГО"
    bottom-button-class="btn-warn"
    bottom-button-title="Понятно"
  >
    <p>
      Уже продлили полис ОСАГО или продали автомобиль? Отключите данную функцию и уведомления приостановятся на 30 дней
    </p>
  </app-modal>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import api from "@rosfines/vue-common/common/api/osagoApi"
import Loading from "vue-loading-overlay";

export default {
  name: "SupportRequest",
  components: {Loading},
  props: {
    showNotificationsBlock: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      toggled: false,
      isSent: false,
      requestText: "",
      sending: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      formId: (state) => state.form.activeCarData.id,
      isNotificationsLoading: state => state.notifications.isNotificationsLoading,
    }),
    ...mapGetters({
      isInControlGroup: 'abtest/isInControlGroup',
      getAllNotificationsObj: "notifications/getAllNotificationsObj",
      getNotifications: "notifications/getNotifications",
    }),
  },
  watch: {
    async toggled(val) {
      if (val) {
        await this.$nextTick();
        document.getElementById("request-text").scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
    },
  },
  async mounted() {
    if (!Object.keys(this.getNotifications).length && !this.isNotificationsLoading) {
      await this.loadNotificationsStatus();
    }
  },
  methods: {
    ...mapActions({
      loadNotificationsStatus: "notifications/loadNotificationsStatus",
      saveNotificationsSettings: "notifications/saveNotificationsSettings",
    }),
    async changeNotifications(data) {
      this.loading = true;
      await this.saveNotificationsSettings({data, place: 'в запросе к поддержке'});
      this.loading = false;
    },
    toggleBlock() {
      this.toggled = !this.toggled;
    },
    async submitForm() {
      if (this.requestText && !this.sending) {
        this.sending = true;
        await api.post(`/feedback/policy-form/${this.formId}`, {
          url: this.$route.name,
          message: this.requestText,
        })
        this.isSent = true;
        this.toggled = false;
        this.requestText = "";
        this.sending = false;
        setTimeout(() => {
          this.isSent = false;
        }, 10000);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.support-request-wrapper {
  border-radius: 12px;
  padding: 18px 16px;
  box-shadow: 0 4px 20px rgba(32, 39, 61, 0.08);
  background: $color-white;
  @include view-lg {
    margin: 0 auto;
    //padding: 12*$pixel-size 10*$pixel-size 8*$pixel-size;
    padding: 6*$pixel-size 12*$pixel-size 6*$pixel-size;
    width: 100%;
    max-width: calc(992px - (8 * $pixel-size));
    background: $color-white;
    border-radius: 12px;
  }
}
.support-request {
  &_toggled {
    .support-request__toggle {
      margin: 0 0 12px;
      &:before {
        bottom: 0;
      }
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  &__title, &__title-send {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    @include view-lg {
      font-weight: 700;
      font-size: 19px;
      line-height: 26px;
    }
  }
  &__title-send {
    justify-content: flex-start;
    margin: 0 0 12px;
    img {
      width: 24px;
      height: 24px;
      margin: -5px 0 0 8px;
      @include view-lg {
        margin: -1px 0 0 8px;
      }
    }
  }
  &__toggle {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -16px;
      top: -18px;
      right: -16px;
      bottom: -18px;
      border-radius: 16px;
      cursor: pointer;
      @include view-lg {
        left: -12*$pixel-size;
        top: -6*$pixel-size;
        right: -12*$pixel-size;
        bottom: -6*$pixel-size;
      }
    }
    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url(@/assets/img/icons/chevron.svg);
      cursor: pointer;
      transition: transform .3s;
    }
  }
  &__desc, &__congrats {
    font-size: 13px;
    line-height: 20px;
    margin: 0 0 12px;
    color: #777E96;
    @include view-lg {
      margin: 0 0 16px;
    }
  }
  &__congrats {
    margin: 0;
    @include view-lg {
      margin: 0;
    }
  }
  &__text-wrapper {
    max-width: 651px;
    margin: 0 0 12px;
    @include view-lg {
      margin: 0 0 16px;
    }
    textarea {
      padding: 16px;
      font-size: 15px;
      line-height: 20px;
      min-height: 132px;
      background: #FBFBFB;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  &__submit {
    @include view-lg {
      max-width: 200px;
    }
  }
}
.all-notifications {
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 8px;
  span {
    line-height: 1;
  }
  &_wrapper {
    p {
      max-width: 651px;
      margin-bottom: 16px;
    }
  }
}
.tooltip {
  display: block;
  height: 18px;
  &_icon {
    position: static;
  }
}
</style>
