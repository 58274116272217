<template>
  <div>
    <info-block
      v-if="carData?.category"
      alt-icon
      :title="`Вы оформляете полис на категорию «${carData?.category}».`"
    />
    <info-block
      v-else
      alt-icon
      title="Кого можем застраховать"
      desc="Для физических лиц — автомобили категорий A,B,C,D, такси, мотоциклы. Водители только граждане РФ. Для юридических лиц — автомобили категории B. Водители только граждане РФ."
    />
    <hr class="d-none d-lg-block mt-4">
  </div>
</template>

<script>
import InfoBlock from "@/components/blocks/misc/InfoBlock.vue";

export default {
  name: "OsagoRestrictions",
  components: {
    InfoBlock
  },
  props: {
    carData: {
      type: Object,
      default: () => {},
    }
  }
};
</script>

