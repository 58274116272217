<template>
  <div
    class="popup"
    :class="{
      'open': isOpen,
      'popup_gray': isGray,
      'no-title': title === '',
    }"
  >
    <div class="popup-shadow" />
    <div class="popup-content">
      <div class="popup-title">
        <span
          class="ico ico_close"
          @click="hide()"
        />
        {{ title }}
      </div>
      <div class="popup-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PopoverBlock',
  props: {
    title: {
      type: String,
      required: true,
    },
    isGray: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    show() {
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars";

.popup {
  &.open {
    .popup-content {
      left: 0;
      opacity: 1;
    }
  }
  &.no-title {
    .popup-title {
      box-shadow: none;
    }
    .popup-body {
      height: calc(100% - 42px);
    }
  }
  &.popup_gray {
    .popup-content {
      background: #FFF;
    }
  }
  .popup-content {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 1500;
    opacity: .1;
    background: #FFF;;
    transition: left .3s ease-in, opacity .3s ease-out;
  }
  .popup-title {
    min-height: 42px;
    padding: 19px 40px 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
    cursor: pointer;
    .ico_close {
      position: absolute;
      left: 16px;
      top: 18px;
    }
  }
  .popup-body {
    padding: 24px 16px;
    overflow: auto;
    height: calc(100% - 58px);
  }
}

.ico {
  display: inline-block;
  &_close {
    width: 24px;
    height: 24px;
    background: url(@/assets/icons/close.svg);
  }
  &_question {
    width: 18px;
    height: 18px;
    background: url(@/assets/icons/question.svg);
    margin: 0 0 -4px 1px;
  }
  &_check {
    width: 16px;
    height: 12px;
    background: url(@/assets/icons/check.svg);
  }
  &_cross {
    width: 14px;
    height: 14px;
    background: url(@/assets/icons/cross.svg);
  }
}
</style>
