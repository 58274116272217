import api from "@rosfines/vue-common/common/api/osagoApi";
import DeviceStorage from "@/util/helpers/device-storage";
import { AB_TESTS } from '@/constants/abTests';

let abTestLoad;
let resolveAbTestLoad;

const abtest = {
  namespaced: true,
  state () {
    return {
      dataInitialized: false,
      abData: {},
    }
  },
  getters: {
    abData(state) {
      const keys = Object.keys(state.abData);
      if(keys.length) {
        return {
          cohortId: Object.values(state.abData).join(",") || "",
          experimentId: keys.join(",") || "",
        }
      } else {
        return {
          cohortId: "",
          experimentId: "",
        }
      }
    },
    getCohortId: (state) => (experimentId) => {
      return state.abData[experimentId];
    },
    isInControlGroup: (state, getters) => (experimentId, controlGroupName) => {
      if (typeof controlGroupName === "undefined") {
        controlGroupName = "control"
      }
      return !(experimentId in state.abData) || getters.getCohortId(experimentId) === controlGroupName;
    },
    isBonusFlowActive(state, getters) {
      return getters.getCohortId(AB_TESTS.BONUS_NEW_USERS.name) === AB_TESTS.BONUS_NEW_USERS.cohorts.TEST ||
        getters.getCohortId(AB_TESTS.BONUS_OLD_USERS.name) === AB_TESTS.BONUS_OLD_USERS.cohorts.TEST
    }
  },
  mutations: {
    saveAbTestData(state, value) {
      state.abData = value;
    },
    setDataInitialized(state) {
      state.dataInitialized = true;
    },
  },
  actions: {
    async getAbTestData({state, dispatch}, forceUpdate = false) {
      if (!abTestLoad) {
        abTestLoad = new Promise((resolve) => {
          // обязательно должен резолвиться и никогда не реджектиться
          resolveAbTestLoad = (data) => {
            resolve(data);
          };
        });
      }
      if (!state.dataInitialized || forceUpdate) {
        await dispatch("_initData", forceUpdate);
      }
      return abTestLoad;
    },
    async _initData({getters, commit, dispatch, rootState}, forceUpdate = false) {
      commit("setDataInitialized");
      const isSourceUpdated = rootState.session.isSourceUpdated;
      const storedData = await dispatch("_getStoredAbTestData");
      if (storedData && !isSourceUpdated && !forceUpdate) {
        commit("saveAbTestData", storedData);
        resolveAbTestLoad(getters.abData);
      } else {
        await dispatch("_loadAbTestData").catch((e) => {
          console.error(e);
        });
        resolveAbTestLoad(getters.abData);
      }
    },
    _getStoredAbTestData() {
      return DeviceStorage.abTestData;
    },
    async _loadAbTestData({commit}) {
      const response = await api.get("/experiments");
      if (api.isSuccess(response)) {
        const {data} = response;
        if (data && data.experiments) {
          commit("saveAbTestData", data.experiments);
          DeviceStorage.abTestData = data.experiments;
          return;
        }
        commit("saveAbTestData", {});
      }
    },
  }
}
export default abtest;

