import api from "@rosfines/vue-common/common/api/osagoApi";
import finesApi from '@rosfines/vue-common/common/api/finesApi';
import DeviceStorage from "@/util/helpers/device-storage";
import Cookies from "js-cookie";

const app = {
  state () {
    return {
      fullScreenLoading: true,
      isCommonLoading: true,
      isExperiment: true,
      events: [],
      enterFunnelEventSent: false,

      utmMarketing: "",

      showAppHeader: false,

      entryPagePassed: false,

      filterType1: false,
    }
  },
  mutations: {
    setCommonLoading(state, flag) {
      state.isCommonLoading = flag;
    },
    setUtmMarketing(state, value) {
      state.utmMarketing = value;
    },
    setShowAppHeader(state, value) {
      state.showAppHeader = value;
    },
    setEntryPagePassed(state, value) {
      state.entryPagePassed = value;
    },
    enableFilterType1(state) {
      state.filterType1 = true;
      DeviceStorage.filterType1 = '1';
      api.setExtraHeaders({
        "Channel": "Web",
      });
    }
  },
  actions: {
    async init({dispatch}) {
      if (finesApi.isAuth()) {
        dispatch("restoreFilterType1");
        await dispatch("getEntryPagePassed", undefined, { root: true });
        await dispatch("getUtmMarketing", undefined, { root: true });
        await dispatch("getShowAppHeader", undefined, { root: true });
        this.hasModule("companies") && await dispatch("companies/init", undefined, { root: true });
        this.hasModule("abtest") && await dispatch("abtest/getAbTestData", undefined, { root: true });
      }
    },
    async getUtmMarketing({commit}) {
      const stored = DeviceStorage.utmMarketing;
      commit("setUtmMarketing", Cookies.get('utm_marketing') || stored && stored.utmMarketing);
    },
    async setUtmMarketing({dispatch}, value) {
      DeviceStorage.utmMarketing = value;
      await dispatch("getUtmMarketing")
    },
    async getShowAppHeader({commit}) {
      const val = DeviceStorage.showAppHeader;
      commit("setShowAppHeader", val);
    },
    async setShowAppHeader({commit}, value) {
      const val = parseInt(value, 10);
      if (!isNaN(val)) {
        commit("setShowAppHeader", val);
        DeviceStorage.showAppHeader = val;
      }
    },
    async getEntryPagePassed({commit}) {
      const stored = DeviceStorage.entryPagePassed;
      commit("setEntryPagePassed", !!stored);
    },
    async setEntryPagePassed({dispatch}, value) {
      DeviceStorage.entryPagePassed = !!value;
      await dispatch("getEntryPagePassed")
    },
    async enableFilterType1({commit}) {
      commit("enableFilterType1");
    },
    async restoreFilterType1({dispatch}) {
      if (DeviceStorage.filterType1) {
        dispatch("enableFilterType1");
      }
    },
    async onChangeCar() {
    }
  }
}

export default app;
