<template>
  <a
    :class="textClasses"
    href="javascript:;"
    tabindex="0"
    @mouseout="active = false"
    @mouseover="active = true"
    @click="$emit('click', $event)"
  >
    <slot
      name="suggestion"
      v-bind="{ htmlText: htmlText }"
    >
      <span v-html="htmlText" />
    </slot>
  </a>
</template>

<script>
export default {
  props: {
    htmlText: {
      type: String,
      default: "",
    },
    backgroundVariant: {
      type: String,
      default: "",
    },
    textVariant: {
      type: String,
      default: "",
    }
  },
  emits: [
    'click'
  ],

  data() {
    return {
      active: false
    };
  },

  computed: {
    textClasses() {
      let classes = "";
      classes += this.active ? "" : "";
      classes += this.backgroundVariant ? ` bg-${this.backgroundVariant}` : "";
      classes += this.textVariant ? ` text-${this.textVariant}` : "";
      return `vbst-item dropdown-item ${classes}`;
    }
  }
};
</script>
