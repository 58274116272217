<template>
  <Teleport to="body">
    <popup-block
      :id="id"
      ref="modal"
      :title="title"
      :short-view="shortView"
      :class="{
        'hide-close-button': !showCloseButton,
        'no-padding': noPadding,
      }"
      :is-gray="isGray"
    >
      <div
        v-if="$slots.modalContent"
        class="content-slot"
      >
        <slot name="modalContent" />
      </div>
      <template
        v-else-if="$slots.fullCustom"
      >
        <slot name="fullCustom" />
      </template>
      <template v-else>
        <div class="popup-content-block content-slot">
          <slot />
        </div>
        <div
          v-if="showCloseButton"
          class="popup-control-block"
        >
          <a
            href="javascript:;"
            class="c_btn_full btn-primary"
            @click="$refs['modal'].hide()"
          >
            {{ bottomButtonTitle }}
          </a>
        </div>
      </template>
    </popup-block>
  </Teleport>
</template>

<script>
import PopupBlock from '@rosfines/vue-common/vue/components/misc/Popup';
import webToNativeBridgeClient from '@rosfines/vue-common/common/services/webToNativeBridgeClient';

export default {
  name: "AppModal",
  components: {
    PopupBlock,
  },
  props: {
    id: {
      type: String,
      default: null
    },
    bottomButtonTitle: {
      default: "Понятно",
      type: String,
    },
    title: {
      type: String,
      default: null
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    shortView: {
      type: Boolean,
      default: false,
    },
    isGray: {
      type: Boolean,
      default: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    "clickButton",
    "close",
  ],
  mounted() {
    this.$registerModal(this.id, this.$refs.modal);

    this.$watch(() => this.$refs.modal.isOpen, async (val) => {
      if (!val) this.$emit('close');

      await webToNativeBridgeClient.postMessageWithResult(`action_enable_swipe_refresh?enable=${Boolean(!val)}`)
        .catch((e) => { console.error(e) }); 
    })
  },
  beforeUnmount() {
    if(this.$refs?.modal?.isOpen) {
      this.$hideModal(this.id);
    }
  },
  unmounted() {
    this.$unregisterModal(this.id);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars";
@import "@/assets/styles/_mixins";

::v-deep {
  &.popup {
    .popup-content {
      max-height: calc(100% - 58px);
      display: flex;
      flex-direction: column;

      .popup-body {
        height: auto;
        flex: 0 10 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .content-slot {
        flex: 0 1 auto;
        overflow: auto;
        position: relative;
      }
    }

    &.short-view.open {
      .popup-content {
        //transform: none;
        //left: 0;
        //right: 0;
        //bottom: 0;
        //top: auto;
        //width: auto;
        border-radius: 16px;
        bottom: auto;

        .popup-body {
          padding: 16px;
        }
      }
    }

    &.hide-close-button {
      .popup-title .ico.ico_close {
        display: none;
      }
    }

    &.no-padding {
      .popup-content {
        .popup-body {
          padding: 0;
        }
      }
    }
  }
}

.popup-control-block {
  margin: 0 0-16px 0-16px;
  padding: 16px;
  border-radius: 16px 16px 0 0;
  background: $color-white;
  box-shadow: 0 0 16px 0 rgba(32, 39, 61, 0.15);
  @include view-lg {
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    max-width: 256px;
  }
}
</style>
