import urlHelper from "@/util/helpers/url.helper";

const session = {
  namespaced: true,
  state () {
    return {
      orderIds: null,
      landingViewed: false,
      sourceFromQuery: null,
      isSourceUpdated: false,
    }
  },
  mutations: {
    setOrderIds(state, payload) {
      state.orderIds = payload;
    },
    landingViewed(state) {
      state.landingViewed = true;
    },
    isSourceUpdated(state, val){
      state.isSourceUpdated = !!val;
    },
    saveSourceFromQuery(state){
      state.sourceFromQuery = urlHelper.parseQueryString(location.href).GET.source;
    },
  }
}

export default session;
