import { frontDate2BackDate } from "@/util/helpers/frontDate2BackDate";

const getFormattedCarData = function(context, formData) {
  formData = JSON.parse(JSON.stringify(formData));
  const carData = formData.car;
  return {
    vehiclePlate: carData.vehiclePlate,
    carMarkId: carData.carModification.brandId,
    carModelId: carData.carModification.modelId,
    carPower: carData.carModification.power,
    carModificationId: carData.carModification.id,
    docType: formData.docType,
    sts: {
      number: String(carData.sts.number || "").replace(/\s/gi, ""),
      date: frontDate2BackDate(carData.sts.date),
    },
    pts: {
      number: String(carData.pts.number || "").replace(/\s/gi, ""),
      date: frontDate2BackDate(carData.pts.date),
    },
    epts: {
      number: String(carData.epts.number || "").replace(/\s/gi, ""),
      date: frontDate2BackDate(carData.epts.date),
    },
    diagnosticCard: {
      number: null,
      date: null
    },
    vin: carData.vin,
    noVin: carData.noVin,
    bodyNumber: String(carData.bodyNumber || "").replace(/\s+/g, ""),
    productionYear: carData.productionYear,
    isLeftHandDrive: carData.isLeftHandDrive,
    withTrailer: formData.withTrailer,
    isTaxi: formData.isTaxi,
    category: carData.category,
  }
};

const getFormattedDriversData = function(context, driversData) {
  driversData = JSON.parse(JSON.stringify(driversData));

  if (driversData.isUnlimitedDrivers) {
    driversData.drivers = driversData.drivers.filter(item => !!item.firstName)
  }
  driversData.drivers.map(item => {
    item.birthDay = frontDate2BackDate(item.birthDay);
    item.experienceStartDate = (frontDate2BackDate(item.experienceStartDate) || null);
    item.oldLicenceStartDate = (frontDate2BackDate(item.oldLicenceStartDate) || null);
    item.licenceNumber = String(item.licenceNumber || "").replace(/\s/gi, "");
    item.oldLicenceNumber = String(item.oldLicenceNumber || "").replace(/\s/gi, "");
    if (item.isOnlyExperienceStartYear) {
      item.experienceStartDate = null;
    }
  });
  return driversData;
};

const getFormattedOwnerData = function (context, formData) {
  formData = JSON.parse(JSON.stringify(formData));
  const data = {
    isOwnerPolicyholder: formData.isOwnerPolicyholder,
    autoOwner: formData.autoOwner,
    policyholder: formData.policyholder,
    phone: formData.phone,
    email: formData.email,
  }
  formData.autoOwner.passportIssueDate = formData.autoOwner.passportIssueDate && frontDate2BackDate(formData.autoOwner.passportIssueDate) || null;
  formData.autoOwner.birthDate = formData.autoOwner.birthDate && frontDate2BackDate(formData.autoOwner.birthDate) || null;
  formData.autoOwner.registrationCertificateIssueDate = formData.autoOwner.registrationCertificateIssueDate && frontDate2BackDate(formData.autoOwner.registrationCertificateIssueDate) || null;
  formData.policyholder.passportIssueDate = formData.policyholder.passportIssueDate && frontDate2BackDate(formData.policyholder.passportIssueDate) || null;
  formData.policyholder.birthDate = formData.policyholder.birthDate && frontDate2BackDate(formData.policyholder.birthDate) || null;
  formData.policyholder.registrationCertificateIssueDate = formData.policyholder.registrationCertificateIssueDate && frontDate2BackDate(formData.policyholder.registrationCertificateIssueDate) || null;

  if (data.autoOwner.passportNumber) {
    data.autoOwner.passportNumber = String(data.autoOwner.passportNumber || "").replace(/\s/gi, "");
  }
  if (data.policyholder.passportNumber) {
    data.policyholder.passportNumber = String(data.policyholder.passportNumber || "").replace(/\s/gi, "");
  }
  return data;
};

export {
  getFormattedCarData,
  getFormattedDriversData,
  getFormattedOwnerData,
};
