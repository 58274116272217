import {statService} from "@/services/statService";
import trackingApi from "@/services/tracking";
import urlHelper from "@/util/helpers/url.helper";

class StatEventsHelper {
  constructor() {
    this.smsEventsSources = [
      'sms_precalc_no_push',
      'sms_paylink',
    ];

    this.emailEventsSources = [
      'email_car_data',
      'email_driver',
      'email_owner',
      'email_offers_calc',
      'email_offers_order_paylink',
    ];

    this.pushEventsSources = [
      'push_offers_calc',
      'push_offers_order',
      'push_owner',
      'push_driver',
      'push_car_data'
    ]
  }

  buttonEvent(text, screen) {
    let eventData = {};
    statService.setStatServiceDataParam({query: {}}, eventData);
    statService.logEvent(`[Действие] [${screen}] Нажал кнопку '${text}' на экране '${screen}'`, eventData);
  }

  checkBoxEvent(label, flag, screen) {
    let eventData = {};
    statService.setStatServiceDataParam({query: {}}, eventData);
    statService.logEvent(`[Действие] [${screen}] ${flag ? 'Включил' : 'Отключил'} чекбокс '${label}' на экране '${screen}'`,
        eventData);
  }

  switchButtonEvent(text, screen) {
    let eventData = {};
    statService.setStatServiceDataParam({query: {}}, eventData);
    statService.logEvent(`[Действие] [${screen}] Нажал переключатель '${text}' на экране '${screen}'`, eventData);
  }

  sendEventFromSmsOrPush(screen, GET) {
    let sourceString;
    GET = GET || urlHelper.parseQueryString(location.href).GET;
    const isFromSms = this.smsEventsSources.find((source) => {
      return source === GET.source
    });
    const isFromEmail = this.emailEventsSources.find((source) => {
      return source === GET.source
    });
    const isFromPush = this.pushEventsSources.find((source) => {
      return source === GET.source
    });
    if (isFromSms) {
      sourceString = `[Ситуация] Вошел в воронку из СМС на экран ${screen}`;
    } else if (isFromEmail) {
      sourceString = `[Ситуация] Вошел в воронку из Email на экран ${screen}`;
    } else if (isFromPush) {
      sourceString = `[Ситуация] Вошел в воронку из Пуша на экран ${screen}`;
    }
    if (sourceString) {
      let eventData = {};
      statService.setStatServiceDataParam({query: {}}, eventData);
      let eventProperties = statService.filterParams({
        ...eventData,
        mod: GET.mod || "",
        source: statService.getSource(),
      });
      trackingApi.tracking(
        sourceString,
          (eventProperties.experimentId ? eventProperties.experimentId : ''),
          (eventProperties.cohortId ? eventProperties.cohortId : ''),
          (eventProperties.source ? eventProperties.source : ''),
          (eventProperties.mod ? eventProperties.mod : '')
      );
    }
  }
}

const statEventsHelper = new StatEventsHelper();

export default statEventsHelper;
