export default {
  mask: '+7 (900) 000-00-00',
  formatOut(innerVal) {
    return "79" + innerVal.unmaskedValue;
  },
  formatIn(outerVal) {
    //фикс части ранее сохраненных неправильных номеров
    if (outerVal && outerVal.length === 11 && new RegExp(/^7[0-8]{1}[\d]{9}$/).test(outerVal)) {
      outerVal = outerVal.replace(/^7[\d]{1}/, "79");
    }
    //дефолнтое значение, чтоб юзеру было понятнее
    if (!outerVal) {
      outerVal = "79";
    }
    return "+" + outerVal;
  },
}
