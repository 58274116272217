<template>
  <div class="data-steps">
    <span>{{ step }}</span>
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "DataSteps",
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped lang="scss">
  .data-steps {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;
    span {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      &:first-child {
        display: flex;
        width: 24px;
        padding: 3px 8px 3px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: #448AFF;

        color: #FFF;
      }
      &:last-child {
        color: #20273D;
      }
    }
  }
</style>
