import {CancelToken} from 'axios';
import api from "@rosfines/vue-common/common/api/osagoApi";
import sleep from "@/util/helpers/sleep";

let offersTimer;
let cancelLoadingOffers = [];
const nextRequestDelay = 3000;
const defaultOffersLoadingTimeout = 5*60*1000;

const productsOffers = {
  namespaced: true,
  state () {
    return {
      offersLoadingTimeout: 0,

      loadingOffers: false,
      isFinishTimeout: false,
      /**
       * loadingOffersStatus:
       * 0 - ожидание
       * 10 - получение в процессе
       * 20 - процесс остановлен
       */
      loadingOffersStatus: 0,
      calculation: {},
      offers: [],
      staticOffers: [],
      offersMap: {},
      statusesMap: {},
      loadingOffersArray: [],

      paidOffers: [],

      savedChosenCompanyId: 0,
    }
  },
  getters: {
    getStaticOffers(state) {
      return state.staticOffers;
    },
    getList(state, getters, rootState, rootGetters) {
      return state.offers.map((calc) => {
        return {
          ...calc,
          meta: rootGetters["products/getByCode"](calc.serviceCode),
        }
      })
    },
    getPaidList(state, getters, rootState, rootGetters) {
      return state.paidOffers.map((calc) => {
        return {
          ...calc,
          meta: rootGetters["products/getByCode"](calc.serviceCode),
        }
      })
    },
    getIsFinishTimeout(state) {
      return state.isFinishTimeout;
    },
    loadingProducts(state, getters, rootState, rootGetters) {
      let mappingArray = [];
      if (state.loadingOffersArray.length < 1) {
        mappingArray = rootGetters["products/getUserProducts"].map(prod => {
          return {
            serviceCode: prod,
            status: 'initial',
          }
        })
      } else {
        mappingArray = state.loadingOffersArray
      }

      return mappingArray.map((calc) => {
        if (state.offers.find(offer => offer.serviceCode === calc.serviceCode)) {
          return null;
        }
        return {
          ...calc,
          meta: rootGetters["products/getByCode"](calc.serviceCode),
        }
      })
      .filter(item => item !== null);
    },
    loadingOffers(state, getters, rootState) {
      return state.loadingOffers || rootState.productsCalcs.loadingCalculations;
    },
    loadedOffers(state) {
      return state.loadingOffersStatus === 20;
    },
    getByCode: (state, getters) => (code) => {
      const item = getters.getList.find((item) => (item.serviceCode === code));
      return item || false;
    },
    unconfirmedProducts(state, getters, rootState) {
      const userProducts = rootState.products.userProducts;
      const confirmedProducts = [
        ...state.offers.reduce(
          (accumulator, offer) => {
            accumulator.push(offer.serviceCode)
            return accumulator;
          },
          []
        ),
        ...state.paidOffers.reduce(
          (accumulator, offer) => {
            accumulator.push(offer.serviceCode)
            return accumulator;
          },
          []
        ),
      ];
      const unconfirmedCodes = userProducts.filter(userProduct => !confirmedProducts.includes(userProduct));
      return rootState.products.products.filter((product) => {
        if (product.code === 'premium-support') {
          return false
        }
        return unconfirmedCodes.includes(product.code);
      }).map((item) => {
        return {
          meta: item,
        }
      });
    },
  },
  mutations: {
    setStaticOffers(state, value) {
      state.staticOffers.push(value);
    },
    setLoadingStatus(state, value) {
      state.loadingOffers = [0, 20].indexOf(value) === -1;
      state.loadingOffersStatus = value;
    },
    setIsFinishTimeout(state, value) {
      state.isFinishTimeout = value;
    },
    resetOffers(state) {
      state.offers = [];
      state.offersMap= {};
      state.statusesMap= {};
    },
    saveStatuses(state, statuses) {
      statuses.map((status) => {
        state.statusesMap[`offer_${status.serviceCode}`] = status.status;
      });
    },
    pushOffers(state, offers) {
      offers.map((offer) => {
        if (typeof state.offersMap[`offer_${offer.offerId}`] === "undefined") {
          state.offers.push(offer);
          state.offersMap[`offer_${offer.offerId}`] = state.offers.length - 1;
        }
      });
    },
    setOffersLoadingTimeout(state, value) {
      state.offersLoadingTimeout = value || defaultOffersLoadingTimeout;
    },
    setPaidOffers(state, value) {
      state.paidOffers = value;
    },
    setLoadingOffers(state, value) {
      state.loadingOffersArray = value;
    }
  },
  actions: {
    getProductOffers({state, dispatch}) {
      if (!state.loadingOffers) {
        dispatch("getOffers");
      }
    },

    async getOffers({state, dispatch, commit, rootState}, options) {
      const {offersLoadingTimeout} = options || {};
      const carId = rootState.form.activeCarId;
      if (carId) {
        if (!rootState.form.dateIsActual) {
          await dispatch("form/autoUpdateStartDate", undefined, {root: true}).catch((e) => {
            throw e;
          });
        }
        await dispatch("stopLoadingOffers", false);

        // await dispatch("products/loadUserProducts", undefined, {root: true});
        await dispatch("products/loadProductsByCar", carId, {root: true});
        await dispatch("products/loadProducts", undefined, {root: true});
        commit("setOffersLoadingTimeout", offersLoadingTimeout || defaultOffersLoadingTimeout);
        commit("setLoadingStatus", 10);
        commit("resetOffers");
        dispatch("getPaidOffers");
        if (rootState.products.userProducts.length) {
          if (rootState.products.userProducts.length < 2 && rootState.products.userProducts[0] === 'premium_support') {
            return
          }
          if (!offersTimer) {
            offersTimer = setTimeout(() => {
              commit("setIsFinishTimeout", true);

              dispatch("stopLoadingOffers");
            }, state.offersLoadingTimeout);
            let localOffersTimer = offersTimer
            while (offersTimer && offersTimer === localOffersTimer) {
              await dispatch("_loadOffers");
              await sleep(nextRequestDelay);
            }
          }
        } else {
          dispatch("stopLoadingOffers");
        }
      } else {
        throw new Error("{carId} missed #8");
      }
    },

    async stopLoadingOffers({state, commit, dispatch}) {
      if (state.loadingOffersStatus !== 0) {
        commit("setLoadingStatus", 20);
      }
      clearTimeout(offersTimer);
      offersTimer = undefined;
      dispatch('_cancelLoadingOffers');
    },

    async _loadOffers({commit, dispatch, rootState}) {
      // const userProducts = rootState.products.userProducts;

      // const selectedOfferId = rootGetters["productsCalcs/getByCode"](nextProduct).calculationId;
      const carId = rootState.form.activeCarId;
      let queryParams = {
        carId,
        // selectedOfferId,
      };
      const offers = await api.get(`/additional-service/offers`, queryParams, undefined, {
        cancelToken: new CancelToken(function executor(c) {
          cancelLoadingOffers.push(c);
        })
      });
      if (api.isSuccess(offers)) {
        commit("saveStatuses", offers.data.statusDetails);
        commit("pushOffers", offers.data.offers);
        if (offers.data.isCompleted) {
          await dispatch("stopLoadingOffers");
        } else {
          commit("setLoadingOffers", offers.data.statusDetails);
        }
      }
    },

    _cancelLoadingOffers() {
      cancelLoadingOffers.forEach((item) => {
        item("due to cancelLoadingOffers called");
      });
      cancelLoadingOffers = [];
    },

    async getPaymentLink(context, offerId) {
      if (offerId) {
        const paymentLink = await api.get(`/partner/offer/${offerId}/payment_url`);
        if(api.isSuccess(paymentLink) && paymentLink.data.paymentUrl) {
          return paymentLink.data.paymentUrl
        }
      }
      return false
    },

    stopOffersActivity({dispatch}) {
      dispatch("stopLoadingOffers");
    },

    async getPaidOffers({commit, rootState}) {
      const carId = rootState.form.activeCarId;
      if (carId) {
        const paidOffers = await api.get(`/additional-service/paid-offers`, {
          carId
        });
        if(api.isSuccess(paidOffers)) {
          commit("setPaidOffers", paidOffers.data.offers);
        }
      }
    },

    async pushStaticOffers({commit}, value) {
      commit('setStaticOffers', value);
    }
  }
}

export default productsOffers;
