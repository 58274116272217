import { statService } from '@/services/statService';
import sleep from '@/util/helpers/sleep';
import api from '@rosfines/vue-common/common/api/osagoApi';
import { CancelToken } from 'axios';
import {GET_FAKE_CALCULATION} from "@/constants/abTests";

let calculationsTimer;
let cancelLoadingCalculations = [];
const calculationsLoadingTimeout = 4*60*1000;
const nextRequestDelay = 3000;

let lastProcessStartedTime;

const productsCalcs = {
  namespaced: true,
  state () {
    return {
      loadingCalculations: false,
      calculations: [],
      calculationsMap: {},
      gotFirstCalc: false,

      previewCalculations: [
        {
          "serviceCode": "kasko_no_policy_vsk",
          "insuranceCompanyId": 4,
          "calculationId": 171,
          "dateFrom": "2024-03-15",
          "dateTo": "2025-03-14",
          "price": 5169
        },
        {
          "serviceCode": "kasko_no_policy_sber",
          "insuranceCompanyId": 26,
          "calculationId": 172,
          "dateFrom": "2024-03-15",
          "dateTo": "2025-03-14",
          "price": 1500
        },{
          "serviceCode": "kasko_no_policy_alfa",
          "insuranceCompanyId": 1,
          "calculationId": 173,
          "dateFrom": "2024-03-15",
          "dateTo": "2025-03-14",
          "price": 1500
        },
      ],

      chosenCalcIds: [],
      lastIdIndex: 0,

      offers: [],
      offersMap: {},
    }
  },
  getters: {
    getList(state, getters, rootState, rootGetters) {
      if (rootGetters["GBFeatures/insuranceAdditionalEnabled"]) {
        return state.calculations.map((calc) => {
          return {
            ...calc,
            meta: rootGetters["products/getByCode"](calc.serviceCode),
          }
        })
      }
      return []
    },
    getPreviewList(state, getters, rootState, rootGetters) {
      if (rootGetters["GBFeatures/insuranceAdditionalEnabled"]) {
        return state.previewCalculations.map((calc) => {
          return {
            ...calc,
            meta: rootGetters["products/getByCode"](calc.serviceCode, true),
          }
        })
      }
      return []
    },
    getByCode: (state, getters) => (code, isStatic = false) => {
      const list  = (isStatic) ? getters.getPreviewList : getters.getList;
      const item = list.find((item) => (item.serviceCode === code));
      return item || false;
    },
    chosenCalcs(state, getters, rootState) {
      return getters.getList.filter((item) => rootState.products.userProducts.includes(item.serviceCode));
    },
    userProductsTotalPrice(state, getters) {
      return getters.chosenCalcs.reduce((accumulator, calc) => accumulator += calc.price, 0)
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loadingCalculations = !!value;
    },
    resetCalculations(state) {
      state.calculations = [];
      state.calculationsMap= {};
      state.gotFirstCalc= false;
    },
    pushCalculations(state, calcs) {
      calcs.map((calc) => {
        if (typeof state.calculationsMap[`calc_${calc.calculationId}`] === "undefined") {
          state.calculations.push(calc);
          state.calculationsMap[`calc_${calc.calculationId}`] = state.calculations.length - 1;
          if (!state.gotFirstCalc) {
            statService.logEvent('[Ситуация] Получен расчет доп услуг', {});
          }
          state.gotFirstCalc = true;
        }
      })
    },
  },
  actions: {
    async getCalculations({dispatch, commit, state, rootState}) {
      lastProcessStartedTime = new Date().getTime();
      let currentProcessStartedTime = new Date().getTime();
      const carId = rootState.form.activeCarId;
      if (carId) {
        if (!rootState.form.dateIsActual) {
          await dispatch("form/autoUpdateStartDate", undefined, {root: true}).catch((e) => {
            throw e;
          });
        }
        await dispatch("stopLoadingCalculations");

        commit("setLoading", true);
        commit("resetCalculations");

        await dispatch("products/loadProducts", undefined, {root: true});
        // await dispatch("products/loadUserProducts", undefined, {root: true});
        await dispatch("products/loadProductsByCar", carId, {root: true});

        calculationsTimer = setTimeout(() => dispatch("stopLoadingCalculations"), calculationsLoadingTimeout);
        while(calculationsTimer && lastProcessStartedTime <= currentProcessStartedTime) {
          await dispatch("_loadCalcs", carId);
          await sleep(nextRequestDelay);
        }
        if (lastProcessStartedTime <= currentProcessStartedTime && state.calculations.length) {
          dispatch("productsOffers/getOffers", undefined, { root: true });
        }
      } else {
        throw new Error("{carId} missed #7");
      }
    },

    async stopLoadingCalculations({state, commit, dispatch}) {
      if(state.calculations.length === 0 && state.loadingCalculations) {
        statService.logEvent('[Ситуация] Не получено расчетов допуслуг', {});
      }
      commit("setLoading", false);
      clearTimeout(calculationsTimer);
      calculationsTimer = undefined;
      dispatch('_cancelLoadingCalculations');
    },

    async _loadCalcs({commit, dispatch, rootGetters}, carId) {
      if(carId) {
        const calculations = await api.get(`/additional-service/calculations`, {
          carId
        }, undefined, {
          cancelToken: new CancelToken(function executor(c) {
            cancelLoadingCalculations.push(c);
          })
        });
        if (api.isSuccess(calculations)) {
          const premiumSupportFeature = rootGetters['GBFeatures/premiumSupportFeature'];
          if (premiumSupportFeature.isActive) {
            const premiumCalculation = GET_FAKE_CALCULATION(premiumSupportFeature.value)
            commit("pushCalculations", [...premiumCalculation ,...calculations.data.calculations]);
          } else {
            commit("pushCalculations", calculations.data.calculations);
          }
          if(calculations.data.isCompleted) {
            dispatch("stopLoadingCalculations");
          }
        }
      } else {
        throw "{carId} not passed";
      }
    },

    _cancelLoadingCalculations() {
      cancelLoadingCalculations.forEach((item) => {
        item("due to cancelLoadingCalculations called");
      });
      cancelLoadingCalculations = [];
    },



  },
}
export default productsCalcs;

