import formPagesMap from "@/constants/formPagesMap";
import api from "@rosfines/vue-common/common/api/osagoApi";
import router from "@/router";

const getPageByField = function(fieldString) {
  let fieldPath = fieldString.split(".");
  let page = formPagesMap;
  for (let i in fieldPath) {
    if (/\[\d*\]$/.test(fieldPath[i])) {
      fieldPath[i] = fieldPath[i].replace(/\[\d*\]$/, "");
    }
    page = page[fieldPath[i]];
    if (page && typeof page === "string") {
      return page;
    }
  }
  if (page && typeof page === "string") {
    return page;
  } else {
    return router.formStepsOrder[0];
  }
}

const validation = {
  namespaced: true,
  state() {
    return {
      validationData: {
        "isValid": true
      }
    }
  },
  getters: {
    pagesWithErrors(state) {
      let pagesWithError = {};
      const errors = state.validationData.errors;
      for (let i in errors) {
        let page = getPageByField(i);
        if (!pagesWithError[page]) {
          pagesWithError[page] = {};
        }
        const path = i.split(".");
        for (let j in path) {
          if (/\[\d*\]$/.test(path[j])) {
            const subPath = [...path[j].matchAll(/^(.*)\[(\d{1,})\]$/g)][0].slice(1);
            path.splice(j, 1, subPath[0]);
            path.splice(j+1, 0, subPath[1]);
          }
        }
        let link2Error = pagesWithError[page];
        for (let j in path) {
          if (!link2Error[path[j]]) {
            link2Error[path[j]] = {};
          }
          link2Error = link2Error[path[j]]
        }
        let j = 0;
        switch(path.length) {
          case 1:
            pagesWithError[page][path[j++]] = errors[i];
            break;
          case 2:
            pagesWithError[page][path[j++]][path[j++]] = errors[i];
            break;
          case 3:
            pagesWithError[page][path[j++]][path[j++]][path[j++]] = errors[i];
            break;
          case 4:
            pagesWithError[page][path[j++]][path[j++]][path[j++]][path[j++]] = errors[i];
            break;
          case 5:
            pagesWithError[page][path[j++]][path[j++]][path[j++]][path[j++]][path[j++]] = errors[i];
            break;
          case 6:
            pagesWithError[page][path[j++]][path[j++]][path[j++]][path[j++]][path[j++]][path[j++]] = errors[i];
            break;
          default:
            break;
        }
      }
      return pagesWithError;
    }
  },
  mutations: {
    setValidationData(state, value) {
      state.validationData = value;
    },
    updateValidationData(state, step) {
      if (state.validationData && state.validationData.errors) {
        for (let i in state.validationData.errors) {
          if (formPagesMap[i] === step) {
            delete state.validationData.errors[i];
          }
        }
        state.validationData.isValid = state.validationData.errors.length === 0;
      }
    },
  },
  actions: {
    async checkIfHasErrors({rootState, commit, dispatch}) {
      await dispatch("form/waitLoadingCarData", undefined, { root: true });
      if(rootState.form.activeCarData) {
        const validationData = await api.get(`/policy-form/${rootState.form.activeCarData.id}/validate`);
        if (api.isSuccess(validationData)) {
          commit("setValidationData", validationData.data);
          return validationData.data.isValid === false;
        }
      }
    },
  }
}

export default validation;

