import api from "@rosfines/vue-common/common/api/osagoApi";
import sleep from "@/util/helpers/sleep";

const nextRequestDelay = 30 * 1000;

const policy = {
  namespaced: true,
  state () {
    return {
      currentOfferId: null,
      setPolicyInfoLoaded: false,
      status: null,
      readyTime: {
        remain: 0,
        updated: 0,
      },
      policyInfo: {},
      upsaleInfo: {},
      policySharingLinkData: {},
      sharedPolicyData: {},
    }
  },
  getters: {
    getTimeRemain(state) {
      return () => state.readyTime.remain - (new Date().getTime() - state.readyTime.updated);
    }
  },
  mutations: {
    currentOfferId(state, offerId) {
      state.currentOfferId = offerId;
    },
    setPolicyInfo(state, data) {
      state.policyInfo = data;
    },
    setUpsaleInfo(state, data) {
      state.upsaleInfo = data;
    },
    setPolicyStatus(state, status) {
      state.status = status;
    },
    setReadyTime(state, readyTime) {
      state.readyTime = {
        remain: readyTime * 1000,
        updated: new Date().getTime(),
      };
    },
    setPolicyInfoLoaded(state, value) {
      state.setPolicyInfoLoaded = !!value;
    },
    setPolicySharingLinkData(state, value) {
      state.policySharingLinkData = value;
    },
    setSharedPolicyData(state, value) {
      state.sharedPolicyData = value;
    },
  },
  actions: {
    async loadPolicyInfo({commit, dispatch}, offerId) {
      commit("currentOfferId", offerId);
      // перед loadPolicyBonusInfo не нужен await
      dispatch("loadPolicyBonusInfo", offerId);

      await dispatch("getPolicyReadyStatus");
      // перед pollPolicyInfo не нужен await, оно будет как бы в фоне выполняться
      dispatch("pollPolicyInfo");
    },
    async pollPolicyInfo({state, dispatch}) {
      while(state.status !== "ready") {
        await dispatch("getPolicyReadyStatus");
        await sleep(nextRequestDelay);
      }
    },
    async getPolicyReadyStatus({state, commit, rootState}) {
      const carId = rootState.form.activeCarId;
      const offerId = state.currentOfferId;
      if (carId) {
        if (offerId) {
          const policyStatus = await api.get(`/partner/car/${carId}/offer/${offerId}/policy-ready-status`).catch(() => {
            commit("setPolicyInfoLoaded", true);
          });
          if (api.isSuccess(policyStatus)) {
            commit("setPolicyStatus", policyStatus.data.status);
            let policyInfo;
            switch(state.status) {
              case "ready":
                policyInfo = await api.get(`/partner/policy/download/${offerId}`);
                if (api.isSuccess(policyInfo)) {
                  const upsaleInfo = await api.get(`/partner/imputed-upsale-policy/download-link/${offerId}`);
                  if (api.isSuccess(upsaleInfo)) {
                    commit("setUpsaleInfo", upsaleInfo.data);
                  }
                  commit("setPolicyInfo", policyInfo.data);
                } else {
                  throw new Error("data loading failed");
                }
                break;
              case "pending":
                commit("setReadyTime", policyStatus.data.timer);
                break;
              // case "unpaid":
              //   break;
              case "error":
                break;
            }
          }
          commit("setPolicyInfoLoaded", true);
        } else {
          throw new Error("{offerId} missed");
        }
      } else {
        throw new Error("{carId} missed #4");
      }
    },
    async getPaymentStatus({ rootState }) {
      const carId = rootState.form.activeCarId;
      if (carId) {
        const status = await api.get(`/partner/car/${carId}/purchase-status`).catch(() => {
          return false;
        });
        if (api.isSuccess(status)) {
          return status.data
        }
      } else {
        throw new Error("{carId} missed #5");
      }
    },
    async getPaymentReadyOffers({ rootState }) {
      const carId = rootState.form.activeCarId;
      if (carId) {
        const offers = await api.get(`/partner/car/${carId}/payment-ready-offers`).catch(() => {
          return false;
        });
        if (api.isSuccess(offers)) {
          return offers.data
        }
      } else {
        throw new Error("{carId} missed #6");
      }
    },
    async loadPolicyBonusInfo({dispatch, rootGetters}, offerId) {
      await dispatch("abtest/getAbTestData", undefined, { root: true });
      if (rootGetters["abtest/isBonusFlowActive"]) {
        await dispatch("offer/getOfferById", offerId, { root: true });
      }
    },
    async loadSharingLink({commit}, offerId) {
      const sharingLinkData = await api.post(`/policy/sharing-link/${offerId}`, {}).catch((err) => {
      });
      if (api.isSuccess(sharingLinkData)) {
        commit("setPolicySharingLinkData", sharingLinkData.data.sharingLink);
      } else {

      }
    },
    async loadSharedPolicyData({commit}, hash) {
      const sharedPolicyData = await api.get(`/policy/shared-data?hash=${hash}`, {}).catch((err) => {
      });
      if (api.isSuccess(sharedPolicyData)) {
        commit("setSharedPolicyData", sharedPolicyData.data);
      } else {
        commit("setSharedPolicyData", {error: true, message: sharedPolicyData.message});
      }
    }
  }
}

export default policy;
