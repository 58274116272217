<template>
  <button
    :class="{
      'button_stroked': stroked,
      'button_disabled': disabled,
      'button_green-disabled': disabled && green,
      'button_small': small,
      'button_green': green,
      'button_grey': grey,
      'button_transparent': transparent,
    }"
    class="button"
    v-bind="$attrs"
    @click="onClick"
  >
    <svg
      v-if="loading"
      class="button__loader"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="14.5"
        :stroke="stroked ? '#448aff' : 'white'"
        stroke-opacity="0.35"
        stroke-width="3"
      />
      <mask
        id="path-2-inside-1"
        :fill="stroked ? '#448aff' : 'white'"
      >
        <path
          d="M14.1374 0.108788C10.7 0.511683 7.48599 2.01825 4.97739 4.4025C2.46879 6.78674 0.800903 9.9201 0.22392 13.3325L3.16148 13.8292C3.63103 11.0522 4.98835 8.50228 7.02984 6.56199C9.07132 4.6217 11.6869 3.39566 14.4842 3.06779L14.1374 0.108788Z"
        />
      </mask>
      <path
        d="M14.1374 0.108788C10.7 0.511683 7.48599 2.01825 4.97739 4.4025C2.46879 6.78674 0.800903 9.9201 0.22392 13.3325L3.16148 13.8292C3.63103 11.0522 4.98835 8.50228 7.02984 6.56199C9.07132 4.6217 11.6869 3.39566 14.4842 3.06779L14.1374 0.108788Z"
        :stroke="stroked ? '#448aff' : 'white'"
        stroke-width="6"
        mask="url(#path-2-inside-1)"
      />
    </svg>
    <template v-else>
      <slot />
    </template>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    small: {
      type: Boolean,
      default: false
    },
    stroked: {
      type: Boolean
    },
    green: {
      type: Boolean
    },
    grey: {
      type: Boolean
    },
    transparent: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'click'
  ],
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
}
</script>

<style scoped>
.button {
  font-size: 17px;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #448aff;
  border: 1px solid #448aff;
  border-radius: 10px;
  color: #fff;
  transition: 200ms all linear;
  cursor: pointer;
}

.button_small {
  height: 32px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  border-radius: 8px;
}

.button:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #448AFF;
}

.button_disabled {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #448AFF;
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}

.button_green {
  background: #00C752;
  border: 1px solid #00C752;
}

.button_green:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #00C752;
}

.button_green-disabled {
  background: #00C752;
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}

.button_stroked {
  background: transparent;
  border: 1px solid #448aff;
  color: #448aff;
}

.button_stroked:active {
  background: transparent;
  border: 1px solid #3C7AE3;
  color: #3C7AE3;
}

.button_stroked:disabled {
  background: transparent;
  opacity: 0.4;
}

.button_grey {
  background: #F2F4F6;
  border: 1px solid #F2F4F6;
  color: #777E96;
}

.button_grey:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #F2F4F6;
  border: 1px solid #F2F4F6;
  color: #777E96;
}

.button_grey:disabled {
  background: #F2F4F6;
  border: 1px solid #F2F4F6;
  color: #777E96;
  opacity: 0.6;
}
.button_transparent {
  background: transparent;
  border: 1px solid #448AFF;
  color: #448AFF;
}

.button_transparent:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #448AFF;
  color: #FFFFFF;
}

.button_transparent:disabled {
  background: #F2F4F6;
  opacity: 0.6;
}

.button__loader {
  animation: rotation 0.8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
