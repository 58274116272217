<template>
  <div
    class="faq-question-wrapper"
    :class="{'faq-question-wrapper_landing': isLanding}"
  >
    <Disclosure v-slot="{ open }">
      <DisclosureButton class="faq-question-header" @click.once="openQuestion">
        <span>{{ question }}</span>
        <svg v-if="open" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.6415 13.467H5.35849C4.74184 13.467 4.25 12.9751 4.25 12.3585C4.25 11.7418 4.74184 11.25 5.35849 11.25H18.6415C19.2582 11.25 19.75 11.7418 19.75 12.3585C19.75 12.9751 19.2582 13.467 18.6415 13.467Z" fill="#20273D" stroke="#F2F4F6" stroke-width="0.5"/>
        </svg>

        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.2075 13.2594H12.9575V13.5094V18.6415C12.9575 19.2582 12.4657 19.75 11.8491 19.75C11.2324 19.75 10.7406 19.2582 10.7406 18.6415V13.5094V13.2594H10.4906H5.35849C4.74184 13.2594 4.25 12.7676 4.25 12.1509C4.25 11.5343 4.74184 11.0425 5.35849 11.0425H10.4906H10.7406V10.7925V5.35849C10.7406 4.74184 11.2324 4.25 11.8491 4.25C12.4657 4.25 12.9575 4.74184 12.9575 5.35849V10.7925V11.0425H13.2075H18.6415C19.2582 11.0425 19.75 11.5343 19.75 12.1509C19.75 12.7676 19.2582 13.2594 18.6415 13.2594H13.2075Z" fill="#20273D" stroke="#F2F4F6" stroke-width="0.5"/>
        </svg>
      </DisclosureButton>
      <DisclosurePanel>
        <disclosure-button class="faq-question-body">
          <span>{{ answer }}</span>
        </disclosure-button>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";
import {statService} from "@/services/statService";

export default {
  name: "FaqQuestion",
  components: {
    DisclosurePanel,
    DisclosureButton,
    Disclosure
  },
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
    isLanding: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    openQuestion() {
      statService.logEvent(`[Ситуация] Юзер открыл вопрос "${this.question}" на странице ${this.$route.name}`, {});
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.faq-question-wrapper {
  display: block;
  border-radius: 12px;
  background: #F2F4F6;
  border: 0;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  @include view-lg {
    //padding: 12*$pixel-size 10*$pixel-size 8*$pixel-size;
    width: 100%;
    max-width: calc(992px - (8 * $pixel-size));
    background: #F2F4F6;
    border-radius: 12px;
    margin: 0 auto 12px;
    &:last-child {
      margin-bottom: 0;
    }
    &_landing {
      max-width: unset;
    }
  }
}

.faq-question-header {
  border: 0;
  appearance: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 16px;
  width: 100%;
  text-align: start;
  color: #20273D;
  @include view-lg {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.2px;
  }
  span {
    max-width: 680px;
  }
  svg {
    min-width: 24px;
    min-height: 24px;
    margin-left: 8px;
  }
}

.faq-question-body {
  font-family: inherit;
  color: #777E96;
  padding: 0 16px 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: start;
  width: 100%;
  span {
    display: block;
    max-width: 680px;
  }
}
</style>
