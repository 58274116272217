import moment from "moment";
import IMask from "imask";
const dateFormat = "YYYY";

export default {
  mask: Date,
  pattern: dateFormat,

  format: function (date) {
    return moment(date).format(dateFormat);
  },
  parse: function (str) {
    return moment(str, dateFormat);
  },
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: new Date().getFullYear()
    }
  }
}
