<template>
  <button
    v-bind="$attrs"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  emits: [
    'click'
  ],
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_vars.scss";

button {
  font-family: 'SFProDisplay-Regular', sans-serif;
  background-color: $color-main;
  border-radius: 10px;
  font-size: 17px;
  line-height: 1.29;
  letter-spacing: -0.41px;
  padding: 15px 60px;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:focus, .btn-primary.focus {
  box-shadow: none;
}

.btn {
  &_small {
    font-family: 'SFProDisplay', sans-serif;
    padding: 7px 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }
}
</style>
