<template>
  <section
    :data-field="id"
    @focusin="onFocus"
    @focusout="onBlur"
  >
    <Field
      ref="validationField"
      :name="id"
      :rules="computedRules"
      :value="modelValue"
    >
      <div
        class="fly-label transparent-textarea"
        :style="{height:textareaBackgroundHeight}"
      >
        <textarea
          :id="id"
          ref="input"
          :type="mask ? 'text' : type"
          :name="name || id"
          :placeholder="placeholder"
          autocomplete="off"
          :aria-label="label"
          class="form-control form-control-lg textfield-shifted"
          :class="{
            ...classes,
            'is-modal': modal,
          }"
          @change="onChange"
          @input="onInput"
        />

        <a
          v-if="modal"
          @click="$showModal(modal)"
          class="link-input-icon"
          href="javascript:;"
        >
          <i class="svgimg svg-help" />
        </a>

        <label
          ref="label"
          :for="id"
          class="required placeholder-label placeholder-shifted"
        >{{ label }}</label>

        <vue-bootstrap-typeahead-list
          v-if="isFocused && data.length > 0"
          ref="list"
          :background-variant="backgroundVariant"
          :data="formattedData"
          :max-matches="maxMatches"
          :min-matching-chars="minMatchingChars"
          :query="modelValue"
          :text-variant="textVariant"
          class="vbt-autcomplete-list"
          @hit="handleHit"
        />
      </div>

      <div v-if="serverValidation && !wasFocused" class="invalid-feedback--vue js_invalid_feedback">{{serverValidation && serverValidation[0] || serverValidation}}</div>
      <ErrorMessage
        as="div"
        :name="id"
        class="invalid-feedback--vue invalid-feedback--vue-absolute js_invalid_feedback"
      />
    </Field>
  </section>
</template>

<script>
import VueBootstrapTypeaheadList from '../typeahead/AppTypeaheadList.vue';
import appField from "../../mixins/appField";
import {autoresize} from "@/services/field-validation.helper";
import {Field, ErrorMessage, useField} from "vee-validate";
import {statService} from "@/services/statService";

export default {
  components: {
    VueBootstrapTypeaheadList,
    Field,
    ErrorMessage,
  },
  mixins: [appField],

  props: {
    size: {
      type: String,
      default: null,
      validator: size => ['lg', 'sm'].indexOf(size) > -1
    },
    data: {
      type: Array,
      required: true,
      validator: d => d instanceof Array
    },
    serializer: {
      type: Function,
      default: d => d,
      validator: d => d instanceof Function
    },
    maxMatches: {
      type: Number,
      default: 10
    },
    minMatchingChars: {
      type: Number,
      default: 0
    },
    serverValidation: {
      type: Array,
      default: null,
    },
    prepend: String,
    append: String,
    classes: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
  },
  emits: [
    'change',
    'input',
    'update:modelValue',
    'hit'
  ],

  data() {
    return {
      errorMessage: null,
      /** @property {String} textareaBackgroundHeight высота фона textarea (оно прозрачное и с абсолютным позиционированием, поэтому ресайзим динамически) */
      textareaBackgroundHeight: 'auto',

      /** @property {Boolean} isFocused используется в логике выбора элемента автокомплита */
      isFocused: false,

      /** @property {String} invalidSelector css класс, который применяется для придания полю вида "Заполнено с ошибкой" */
      invalidSelector: 'is-invalid--vue',

      wasFocused: false,

      inContext: false,
    };
  },

  computed: {
    sizeClasses() {
      return this.size
          ? `input-group input-group-${this.size}`
          : 'input-group';
    },
    formattedData() {
      if (!(this.data instanceof Array)) {
        return [];
      }
      return this.data.map((d, i) => {
        return {
          id: i,
          data: d,
          text: this.serializer(d)
        };
      });
    },
    computedRules() {
      return this.inContext ? "" : this.rules;
    }
  },

  watch: {
    value() {
      this.autoresize();
    },
    errorMessage(newVal) {
      if (newVal) {
        let eventData = {
          fieldValue: this.$refs.input.value,
        };
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent(`[Ситуация] Показана ошибка валидации. Поле [${this.id}]`, eventData);
      }
    },
  },

  mounted() {
    this.$refs.input.value = this.modelValue;
    this.autoresize();
    this.resizeBackground();
    this.errorMessage = useField(this.id).errorMessage;
  },

  methods: {
    onFocus() {
      this.inContext = true;
      this.isFocused = true;
      this.wasFocused = true;
      statService.logEvent(`[Дейтсвие] Кликнул в textarea ${this.label}`);
    },

    handleHit(evt) {
      this.$emit('update:modelValue', evt.text);
      this.$emit('input', evt.text);
      this.inputValue = evt.text;
      this.$emit('hit', evt.text);
      this.$refs.input.value = evt.text;
      this.$refs.input.blur();
      this.autoresize();
      this.isFocused = false;
      this.$nextTick(() => {
        this.$refs.validationField.handleChange(evt.text);
      });
    },

    getValue(event) {
      return event.target.inputmask
          ? event.target.inputmask.unmaskedvalue()
          : event.target.value;
    },

    onBlur(evt) {
      let target = evt.relatedTarget || evt.target;
      if (window.$(target).closest(window.$(`section[data-field='${this.id}']`)).length === 0 || window.$(target).is("textarea")) {
        this.inContext = false;
      }
      const value = this.getValue(evt);
      const tgt = evt.relatedTarget;
      if (tgt && tgt.classList.contains('vbst-item')) {
        return;
      }
      this.isFocused = false;

      statService.logEvent(`[Дейтсвие] Ушел с textarea ${this.label}`);

      this.$refs.validationField.handleChange(value);
    },

    onInput(event) {
      const value = this.getValue(event);
      if (typeof value !== 'undefined') {
        this.inputValue = value;
        this.$emit('update:modelValue', this.keyToLang(value));
        this.$emit('input', this.keyToLang(value));
        this.autoresize();
      }
    },
    onChange(event) {
      const value = this.getValue(event);
      this.$emit('change', this.keyToLang(value));
      // autoresize(this.$refs.input);
    },
    autoresize() {
      autoresize(this.$refs.input, {context: this, method: this.resizeBackground});
    },
    /**
     * @description этот метод передаётся в autoresize из helpers как колбэк,
     * для того, чтобы можно было отресайзить фон вместе с полем ввода
     * @param {Number} height = null если передано число, высота бэкграунда
     *   устанавливается в это значение
     * Иначе вычисляется высота this.$refs.input с учётом паддингов и границ
     */
    resizeBackground(height = null) {
      let input = window.$(this.$refs.input),
          h = input.height(),
          borderThickness = isNaN(parseFloat(input.css('border-width'))) ? 0 : parseFloat(input.css('border-width')),
          paddingTop = isNaN(parseFloat(input.css('padding-top'))) ? 0 : parseFloat(input.css('padding-top')),
          paddingBottom = isNaN(parseFloat(input.css('padding-bottom'))) ? 0 : parseFloat(input.css('padding-bottom')),
          inputH;
      inputH = parseFloat(height) ? parseFloat(height) : (h + paddingTop + paddingBottom + borderThickness + 0.15);
      this.textareaBackgroundHeight = inputH + 'px';
    },
    setValue(val, setFocus = true) {
      if (setFocus) {
        this.$refs.input.focus();
      }
      this.$refs.input.value = val;
      if (setFocus) {
        const valLength = this.$refs.input.value.length;
        this.$refs.input.setSelectionRange(valLength, valLength);
        const e = document.createEvent("HTMLEvents");
        e.initEvent("change", false, true);
        this.$refs.input.dispatchEvent(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

.vbt-autcomplete-list {
  padding-top: 5px;
  position: absolute;
  max-height: 350px;
  overflow-y: auto;
  z-index: 999;
  width: 100%;

  [uppercase] {
    text-transform: uppercase;
  }
}

.placeholder-label {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  left: 16px;
}

.placeholder-shifted {
  top: 7px;
  font-size: 11px;
  line-height: 13px;
}

.textarea-shifted {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3) + 0.6rem) !important;
  padding-bottom: calc(var(--input-padding-y) / 3 + 0.2rem) !important;
  z-index: 3 !important;
}

.transparent-textarea > textarea {
  background-color: rgba(255, 255, 255, 0) !important;
  position: absolute !important;
  z-index: 5 !important;
  top: 0;
  left: 0;
  padding: 5.5*$pixel-size 4*$pixel-size 1*$pixel-size 4*$pixel-size;
  resize: none;
  &.is-modal {
    padding-right: 9*$pixel-size;
  }
}

.transparent-textarea {
  position: relative;
  border-radius: 0.75rem;
  background-color: #f2f4f6;
}

</style>
