<template>
  <div :class="{'container-fluid': !isLanding}">
    <div
      class="faq-wrapper"
      :class="{'faq-wrapper_landing': isLanding}"
    >
      <Disclosure v-slot="{ open }">
        <DisclosureButton
          class="faq-header"
          @click.once="openFaq"
        >
          <span>{{ sectionName }}</span>
          <svg v-show="!isLanding" :class="open && 'disclosure_open'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16146_42039)">
              <path d="M6 9L12 15L18 9" stroke="#A4AABF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_16146_42039">
                <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)"/>
              </clipPath>
            </defs>
          </svg>
        </DisclosureButton>
        <DisclosurePanel
          class="faq-body"
          :static="isLanding"
        >
          <faq-question
            v-for="question in sectionQuestions"
            :key="question.id"
            :question="question.question"
            :answer="question.answer"
            :is-landing="isLanding"
          />
        </DisclosurePanel>
      </Disclosure>
    </div>
  </div>
</template>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";
import FaqQuestion from "@/components/faq/FaqQuestion.vue";
import {FAQ_QUESTIONS} from "@/constants/faqQuestions";
import {statService} from "@/services/statService";

export default {
  name: "FaqComponent",
  components: {
    FaqQuestion,
    DisclosurePanel,
    DisclosureButton,
    Disclosure
  },
  props: {
    pageName: {
      type: String,
      required: true,
    },
    isLanding: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    sectionName() {
      return FAQ_QUESTIONS[this.pageName].sectionName
    },
    sectionQuestions() {
      console.log(FAQ_QUESTIONS[this.pageName].questions)
      return FAQ_QUESTIONS[this.pageName].questions
    },
  },
  methods: {
    openFaq() {
      statService.logEvent(`[Ситуация] Юзер открыл раздел FAQ на странице ${this.$route.name}`, {});
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.faq-wrapper {
  display: block;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(32, 39, 61, 0.08);
  background: $color-white;
  border: 0;
  margin-bottom: 24px;
  @include view-lg {
    margin: 0 auto 24px;
    //padding: 12*$pixel-size 10*$pixel-size 8*$pixel-size;
    width: 100%;
    max-width: calc(992px - (8 * $pixel-size));
    background: $color-white;
    border-radius: 12px;
    &_landing {
      max-width: unset;
      margin-top: 25px;
    }
  }
  &_landing {
    max-width: unset;
    margin-top: 8px;
  }
}

.faq-header {
  border: 0;
  appearance: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 18px 16px;
  width: 100%;
  color: #20273D;
  @include view-lg {
    padding: 6*$pixel-size 12*$pixel-size 6*$pixel-size;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
  }
  span {
    text-align: start;
    max-width: 640px;
  }
}

.faq-body {
  padding: 0 16px 24px;
  @include view-lg {
    padding: 0 48px 24px;
  }
}

.disclosure_open {
  transform: rotate(180deg);
}
</style>
