<template>
  <div class="container container_adaptive">
    <div class="funnel-list">
      <div class="funnel-list__title">{{ title }}</div>
      <div class="funnel-list__desc">Будем актуализировать для вас подборку на основе заполненных данных в анкете</div>
      <div
        v-if="showBadges"
        class="funnel-list__badges"
      >
        <div class="badge badge_green">
          Без комиссии
        </div>
      </div>
      <div class="funnel-list__carousel">
        <Carousel
          ref="carousel"
          snap-align="start"
          :items-to-show="1.3"
          :autoplay="0"
          :wrap-around="false"
          :breakpoints="{
            370: {
              itemsToShow: 1.5,
            },
            400: {
              itemsToShow: 1.7,
            },
            500: {
              itemsToShow: 2.2,
            },
            991: {
              itemsToShow: 3.3,
            },
          }"
        >
          <Slide
            v-for="(policy, index) in policies"
            :key="index"
          >
            <recent-policy
              :item="policy"
            />
          </Slide>

          <template #addons>
            <div class="carousel__nav">
              <Navigation />
            </div>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import RecentPolicy from '@/components/blocks/RecentPolicies/RecentPolicy';
import {Carousel, Slide, Navigation} from 'vue3-carousel'
import { mapState } from 'vuex';
export default {
  name: "FunnelList",
  props: {
    showBadges: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Сегодня уже купили'
    }
  },
  components: {
    RecentPolicy,
    Carousel,
    Slide,
    Navigation,
  },
  computed: {
    ...mapState({
      policies: state => state.recentPolicies.list
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.funnel-list {
  background: $color-white;
  @include view-lg {
    margin: 0 0 20px;
    border-radius: 24px;
    padding: 24px 48px;
  }
  &__title {
    color: #20273D;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 129.412% */
    letter-spacing: -0.2px;
    margin: 0 0 4px;
  }
  &__desc {
    color: #777E96;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.08px;
    margin: 0 0 12px;
  }
  &__badges {
    display: flex;
    margin: 0 0 12px;
    align-items: center;
    gap: 8px;
  }
  &__carousel {
    ::v-deep {
      .recent-policy {
        width: calc(100% - 8px);
        margin-right: 8px;
      }
    }
  }
}
.carousel {
  &::v-deep {
    .carousel__nav {
      display: none;
      position: absolute;
      right: 16px;
      top: -30px;
      @include view-lg {
        display: flex;
      }
    }
    .carousel__prev, .carousel__next {
      position: static;
      width: 24px;
      height: 24px;
      background: url(@/assets/img/icons/chevron-right-dark.svg);
      right: -28px;
      transform: none;
      @include view-lg {
        right: -47px;
      }
      > svg {
        display: none;
      }
      &--in-active {
        background: url(@/assets/img/icons/chevron-h.svg);
      }
    }
    .carousel__prev {
      right: auto;
      left: -28px;
      transform: rotate(180deg);
      @include view-lg {
        left: -47px;
      }
    }
  }
}

.carousel__nav {
  right:-6px!important;
}
.badge {
  padding: 2px 8px;
  justify-content: center;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px; /* 145.455% */
  letter-spacing: 0.2px;
  &_green {
    background: #EBFBF1;
    color: #00C752;
  }
}
</style>
