import dateProto from  "./prototypes/date";
import { IMask } from 'vue-imask';

export default {
  ...dateProto,
  min: new Date(),
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1999, //чтобы не выводился год в инпуте и не смущал
      to: new Date().getFullYear() + 3,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31
    }
  }
}
