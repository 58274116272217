export const ADDITIONAL_INSURANCE_DATA = [
  {
    "code": "kasko_no_policy_alfa",
    "name": "КАСКО от бесполисных",
    "description": "Полис защищает вас, если у виновника ДТП не окажется ОСАГО или он окажется поддельным. Страховая произведет вам выплату и сама разберется с виновником.",
    "type": "kasko_no_policy",
    "isActive": true,
    "companyId": 1,
    "attributes": [
      {
        "category": "main_info",
        "name": "kasko_no_policy_alfa_main_info",
        "value": null,
        "description": null,
        "properties": [
          {
            "category": "main_info_item",
            "value": "Если вы не виновник ДТП",
            "description": null,
            "sortNumber": 1
          },
          {
            "category": "main_info_item",
            "value": "Если у виновника ДТП нет ОСАГО",
            "description": null,
            "sortNumber": 2
          }
        ]
      },
      {
        "category": "additional_info",
        "name": "kasko_no_policy_alfa_additional_info",
        "value": null,
        "description": null,
        "properties": [
          {
            "category": "additional_info",
            "value": "До 400 000 ₽",
            "description": "Выплата от страховой",
            "sortNumber": 1
          },
          {
            "category": "additional_info_start_date",
            "value": null,
            "description": "Срок страхования — 1 год",
            "sortNumber": 2
          }
        ]
      },
      {
        "category": "benefit",
        "name": "kasko_no_policy_alfa_benefit",
        "value": "Будьте защищены",
        "description": "Почти 10% автомобилистов используют поддельный полис, либо ездят без него. Данная опция поможет обрести защиту от таких водителей и избежать судебных разбирательств",
        "properties": []
      },
      {
        "category": "tip",
        "name": "kasko_no_policy_alfa_tip",
        "value": "Как добавить?",
        "description": "Добавление дополнительной опции к полису ОСАГО доступно на следующем шаге оформления. Сначала необходимо выбрать предложение",
        "properties": []
      },
      {
        "category": "instruction",
        "name": "kasko_no_policy_alfa_instruction",
        "value": "Как работает опция",
        "description": "Условия и порядок действий",
        "properties": [
          {
            "category": "instruction_item",
            "value": "Установите виновника ДТП",
            "description": "Если машину повредят на парковке или во дворе, а виновник скроется или не будет зафиксирован — полис не сработает",
            "sortNumber": 1
          },
          {
            "category": "instruction_item",
            "value": "Проверьте полис виновника на действительность",
            "description": "Это может сделать сотрудник ГИБДД, или вы самостоятельно на сайте РСА",
            "sortNumber": 2
          },
          {
            "category": "instruction_item",
            "value": "Позвоните в страховую компанию",
            "description": "Сообщите, что вы попали в ДТП с водителем без полиса ОСАГО и у вас есть КАСКО от бесполисных",
            "sortNumber": 3
          },
          {
            "category": "instruction_item",
            "value": "Подайте заявление на выплату в страховую компанию",
            "description": "При обращении вам подскажут необходимый перечень документов",
            "sortNumber": 4
          },
          {
            "category": "instruction_item",
            "value": "Страховая компания проверит обстоятельства ДТП и отправит на ремонт",
            "description": "Страховая сразу выплатит вам сумму возмещения. Вам не нужно будет ходить по судам, этим займется страховая компания",
            "sortNumber": 5
          }
        ]
      },
      {
        "category": "document",
        "name": "kasko_no_policy_alfa_document",
        "value": "Полезные документы",
        "description": null,
        "properties": [
          {
            "category": "document_item",
            "value": "https://static.rosfines.ru/assets/rules/additional-services/alfa_insurance_rules.pdf",
            "description": "Правила страхования",
            "sortNumber": 1
          },
          {
            "category": "document_item",
            "value": "https://static.rosfines.ru/assets/rules/additional-services/alfa_cross_kid.pdf",
            "description": "КИД",
            "sortNumber": 2
          }
        ]
      }
    ]
  },
  {
    "code": "kasko_no_policy_vsk",
    "name": "КАСКО от бесполисных",
    "description": "Полис защищает вас, если ДТП произошло не по вашей вине, даже если у виновника нет полиса ОСАГО. Страховая компания компенсирует ущерб при повреждении багажа или при хищении ключей и личных документов.",
    "type": "kasko_no_policy",
    "isActive": true,
    "companyId": 4,
    "attributes": [
      {
        "category": "main_info",
        "name": "kasko_no_policy_vsk_main_info",
        "value": null,
        "description": null,
        "properties": [
          {
            "category": "main_info_item",
            "value": "Если вы не виновник ДТП",
            "description": null,
            "sortNumber": 1
          },
          {
            "category": "main_info_item",
            "value": "Если у виновника ДТП нет ОСАГО",
            "description": null,
            "sortNumber": 2
          },
          {
            "category": "main_info_item",
            "value": "Если виновник — пешеход или велосипедист",
            "description": null,
            "sortNumber": 3
          }
        ]
      },
      {
        "category": "additional_info",
        "name": "kasko_no_policy_vsk_additional_info",
        "value": null,
        "description": null,
        "properties": [
          {
            "category": "additional_info",
            "value": "До 800 000 ₽",
            "description": "Выплата от страховой",
            "sortNumber": 1
          },
          {
            "category": "additional_info_start_date",
            "value": null,
            "description": "Срок страхования — 1 год",
            "sortNumber": 2
          }
        ]
      },
      {
        "category": "benefit",
        "name": "kasko_no_policy_vsk_benefit",
        "value": "Будьте защищены",
        "description": "Почти 10% автомобилистов используют поддельный полис, либо ездят без него. Данная опция поможет обрести защиту от таких водителей и избежать судебных разбирательств",
        "properties": []
      },
      {
        "category": "tip",
        "name": "kasko_no_policy_vsk_tip",
        "value": "Как добавить?",
        "description": "Добавление дополнительной опции к полису ОСАГО доступно на следующем шаге оформления. Сначала необходимо выбрать предложение",
        "properties": []
      },
      {
        "category": "instruction",
        "name": "kasko_no_policy_vsk_instruction",
        "value": "Как работает опция",
        "description": "Условия и порядок действий",
        "properties": [
          {
            "category": "instruction_item",
            "value": "При ДТП — виновник не вы",
            "description": "Виновник ДТП согласен с тем, что виноват именно он (Должно быть зафиксировано в протоколе ГИБДД",
            "sortNumber": 1
          },
          {
            "category": "instruction_item",
            "value": "Проверьте полис виновника на действительность",
            "description": "Это может сделать сотрудник ГИБДД, или вы самостоятельно на сайте РСА",
            "sortNumber": 2
          },
          {
            "category": "instruction_item",
            "value": "Позвоните в страховую компанию",
            "description": "Сообщите, что вы попали в ДТП с водителем без полиса ОСАГО и у вас есть КАСКО от бесполисных",
            "sortNumber": 3
          },
          {
            "category": "instruction_item",
            "value": "Подайте заявление на выплату в страховую компанию",
            "description": "При обращении вам подскажут необходимый перечень документов",
            "sortNumber": 4
          },
          {
            "category": "instruction_item",
            "value": "Страховая компания проверит обстоятельства ДТП и отправит на ремонт",
            "description": "Ремонт будет осуществлен в пределах страховой суммы",
            "sortNumber": 5
          }
        ]
      },
      {
        "category": "document",
        "name": "kasko_no_policy_vsk_document",
        "value": "Полезные документы",
        "description": null,
        "properties": [
          {
            "category": "document_item",
            "value": "https://static.rosfines.ru/assets/rules/additional-services/vsk_insurance_rules.pdf",
            "description": "Правила страхования",
            "sortNumber": 1
          },
          {
            "category": "document_item",
            "value": "https://static.rosfines.ru/assets/rules/additional-services/vsk_cross_kid.pdf",
            "description": "КИД",
            "sortNumber": 2
          }
        ]
      }
    ]
  },
  {
    "code": "kasko_no_policy_sber",
    "name": "КАСКО от бесполисных",
    "description": "Полис защищает вас, если ДТП произошло не по вашей вине, даже если у виновника нет полиса ОСАГО. Страховая компания компенсирует ущерб при повреждении багажа или при хищении ключей и личных документов.",
    "type": "kasko_no_policy",
    "isActive": true,
    "companyId": 26,
    "attributes": [
      {
        "category": "main_info",
        "name": "kasko_no_policy_sber_main_info",
        "value": null,
        "description": null,
        "properties": [
          {
            "category": "main_info_item",
            "value": "Если вы не виновник ДТП",
            "description": null,
            "sortNumber": 1
          },
          {
            "category": "main_info_item",
            "value": "Если у виновника ДТП нет ОСАГО",
            "description": null,
            "sortNumber": 2
          },
          {
            "category": "main_info_item",
            "value": "Выплата при повреждении багажа",
            "description": null,
            "sortNumber": 3
          },
          {
            "category": "main_info_item",
            "value": "Выплата при хищении личных вещей",
            "description": null,
            "sortNumber": 4
          }
        ]
      },
      {
        "category": "additional_info",
        "name": "kasko_no_policy_sber_additional_info",
        "value": null,
        "description": null,
        "properties": [
          {
            "category": "additional_info",
            "value": "До 400 000 ₽",
            "description": "Выплата от страховой",
            "sortNumber": 1
          },
          {
            "category": "additional_info_start_date",
            "value": "С {date}",
            "description": "Срок страхования — 1 год",
            "sortNumber": 2
          }
        ]
      },
      {
        "category": "benefit",
        "name": "kasko_no_policy_sber_benefit",
        "value": "Будьте защищены",
        "description": "Почти 10% автомобилистов используют поддельный полис, либо ездят без него. Данная опция поможет обрести защиту от таких водителей и избежать судебных разбирательств",
        "properties": []
      },
      {
        "category": "tip",
        "name": "kasko_no_policy_sber_tip",
        "value": "Как добавить?",
        "description": "Добавление дополнительной опции к полису ОСАГО доступно на следующем шаге оформления. Сначала необходимо выбрать предложение",
        "properties": []
      },
      {
        "category": "instruction",
        "name": "kasko_no_policy_sber_instruction",
        "value": "Как работает опция",
        "description": "Условия и порядок действий",
        "properties": [
          {
            "category": "instruction_item",
            "value": "При ДТП — виновник не вы",
            "description": "Виновник ДТП согласен с тем, что виноват именно он (Должно быть зафиксировано в протоколе ГИБДД",
            "sortNumber": 1
          },
          {
            "category": "instruction_item",
            "value": "Позвоните в страховую компанию",
            "description": "Сообщите, что вы попали в ДТП и у вас есть КАСКО от бесполисных",
            "sortNumber": 2
          },
          {
            "category": "instruction_item",
            "value": "Подайте заявление на выплату в страховую компанию в течение 3 рабочих дней",
            "description": "При обращении вам подскажут необходимый перечень документов",
            "sortNumber": 3
          },
          {
            "category": "instruction_item",
            "value": "Страховая компания проверит обстоятельства ДТП и отправит на ремонт",
            "description": "Ремонт будет осуществлен в пределах страховой суммы",
            "sortNumber": 4
          },
          {
            "category": "instruction_item",
            "value": "При хищении багажа или личных вещей",
            "description": "Страховая компания разберется в обстоятельствах и осуществит страховую выплату",
            "sortNumber": 5
          }
        ]
      },
      {
        "category": "document",
        "name": "kasko_no_policy_sber_document",
        "value": "Полезные документы",
        "description": null,
        "properties": [
          {
            "category": "document_item",
            "value": "https://static.rosfines.ru/assets/rules/additional-services/sber_insurance_rules.pdf",
            "description": "Правила страхования",
            "sortNumber": 1
          },
          {
            "category": "document_item",
            "value": "https://static.rosfines.ru/assets/rules/additional-services/sber_cross_kid.pdf",
            "description": "КИД",
            "sortNumber": 2
          }
        ]
      }
    ]
  },
  {
    "code": "kasko_go_alfa_insapp_upsale",
    "name": "КАСКОGO",
    "description": "Компенсация при ДТП (деньги или ремонт), выплата по полной стоимости запчастей. Выплаты в течение 5 дней вместо 20",
    "type": "osago_imputed_upsale",
    "isActive": true,
    "companyId": 1,
    "attributes": []
  },
  {
    "code": "air_bag_rgs_insapp_upsale",
    "name": "Подушка безопасности",
    "description": "Компенсация при ДТП (деньги или ремонт) и урегулирование убытков через РГС",
    "type": "osago_imputed_upsale",
    "isActive": true,
    "companyId": 6,
    "attributes": []
  },
  {
    "code": "accident_ins_renaissance_insapp_upsale",
    "name": "Защита от несчастного случая",
    "description": "Защита страхователя от несчастного случая при ДТП",
    "type": "osago_imputed_upsale",
    "isActive": true,
    "companyId": 10,
    "attributes": []
  },
  {
    "code": "kasko_no_policy_renaissance_insapp_upsale",
    "name": "КАСКО от бесполисных",
    "description": "Защита  от ДТП по вине установленных третьих лиц, если у виновника ДТП нет полиса ОСАГО или полис поддельный. Лимит возмещения до 400 000 рублей по каждому страховому случаю.",
    "type": "osago_imputed_upsale",
    "isActive": true,
    "companyId": 10,
    "attributes": []
  }
]

export const ADDITIONAL_INSURANCE_PREMIUM = [
  {
    "code": "premium-support",
    "name": "Премиум поддержка",
    "description": "Специальная группа сотрудников, которые быстро помогут вам решить любой вопрос, связанный со страхованием и вашим полисом ОСАГО.",
    "type": "premium-support",
    "isActive": true,
    "companyId": 999,
    "attributes": [
      {
        "category": "main_info",
        "name": "car-crushed-instruction_main_info",
        "value": null,
        "description": null,
        "properties": [
          {
            "category": "main_info_item",
            "value": "Поддержка и консультация при ДТП",
            "description": null,
            "sortNumber": 1
          },
          {
            "category": "main_info_item",
            "value": "Юридическая помощь на дороге",
            "description": null,
            "sortNumber": 2
          },
          {
            "category": "main_info_item",
            "value": "Личный менеджер всегда на связи",
            "description": null,
            "sortNumber": 3
          }
        ]
      },
      {
        "category": "benefit",
        "name": "premium-support_benefit",
        "value": "Решим любой вопрос",
        "description": "Специалисты помогут с любым вопросом на тему страхования и вашего полиса ОСАГО.",
        "properties": []
      },
    ]
  },
]
