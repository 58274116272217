<template>
  <div class="recent-policy recent-policy">
    <div
      class="recent-policy__top-row top-row"
      :class="{
        'top-row_loading': loading,
      }"
    >
      <template v-if="item.price">
        <div class="top-row__logo">
          <img
            v-if="company && company.logo_40"
            :src="company.logo_40"
            alt=""
          >
        </div>
        <div class="top-row__price">
          {{ $filters.price(item.price) }}
        </div>
      </template>
      <div
        v-else
        class="top-row__loading"
      ></div>
      <div class="top-row__car">
        <div class="car-name">{{ item.mark }} {{ item.model }}</div>
      </div>
    </div>
    <div class="recent-policy__bottom-row">
      <div
        class="policy-props"
        :class="{
          'policy-props_loading': loading,
        }"
      >
        <div class="policy-props__row policy-props__row-car">
          <div class="policy-props__prop">Автомобиль:</div>
          <div class="policy-props__val">{{ item.mark }} {{ item.model }}</div>
        </div>
        <div class="policy-props__row">
          <div class="policy-props__prop">Регион:</div>
          <div class="policy-props__val policy-props__region">{{ item.region }}</div>
        </div>
        <div class="policy-props__row">
          <div class="policy-props__prop">Водители:</div>
          <div class="policy-props__val">{{ item.isUnlimitedDrivers ? 'Без ограничений' : item.drivers }}</div>
        </div>
        <div class="policy-props__row">
          <div class="policy-props__prop">КБМ:</div>
          <div class="policy-props__val">{{ item.kbm }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: "RecentPolicy",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed:{
    ...mapState({
      loading: state => state.recentPolicies.loading,
    }),
    ...mapGetters({
      getICById: "companies/getICById",
    }),
    company() {
      return this.getICById(this.item.insuranceCompanyId);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.recent-policy {
  border-radius: 12px;
  background: #F2F4F6;
  padding: 12px;
  width: 100%;
  &__top-row {
    display: flex;
    margin: 0 0 12px;
  }
  &__bottom-row {
  }
}
.top-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  &_loading {
    .top-row__car {
      &:before {
        content: "";
        display: block;
        height: 13px;
        width: 42px;
        border-radius: 8px;
        background: #D9DFE4;
      }
    }
  }
  &__logo {
    margin-right: 8px;
    flex: 0 1 24px;
    > img {
      width: 24px;
      height: 24px;
    }
  }
  &__price {
    flex: 10 0 auto;
    margin: 0 10px 0 0;
    color: #20273D;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 129.412% */
    letter-spacing: -0.2px;
    text-align: left;
  }
  &__loading {
    &:before {
      content: "";
      display: block;
      width: 92px;
      height: 24px;
      border-radius: 8px;
      background: #D9DFE4 url(@/assets/img/loader-main.svg) 6px center / 12px 12px no-repeat;
    }
  }
  &__car {
    display: flex;
    align-items: center;
    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: url(@/assets/img/icons/car.svg) center center / contain no-repeat;
      margin: 0 0 0 4px;
    }
    .car-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width:74px;
    }
  }
}
.policy-props {
  color: #20273D;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.08px;
  &_loading {
    .policy-props__val {
      &:empty {
        &:before {
          content: "";
          display: block;
          height: 18px;
          border-radius: 8px;
          background: #D9DFE4;
          margin-right: 30px;
        }
      }
    }
  }
  &__row {
    display: flex;
    + .policy-props__row {
      margin-top: 2px;
    }
  }
  &__row-car {
    @include view-lg {
      display: none;
    }
  }
  &__prop {
    font-weight: 600;
    margin-right: 2px;
  }
  &__val {
    flex: 1 0 auto;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width:120px;
  }
  &__region {
    flex: 1 0 auto;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width:160px;
  }
}
</style>
