import api from "@rosfines/vue-common/common/api/osagoApi";
import { frontDate2BackDate } from '@/util/helpers/frontDate2BackDate';
const actualPolicy = {
  namespaced: true,
  state () {
    return {
      policyData: {},
    }
  },
  mutations: {
    setPolicyData(state, data) {
      state.policyData = data;
    },
  },
  actions: {
    async addPolicy({commit}, formData) {
      formData.expirationDate = frontDate2BackDate(formData.expirationDate);
      const response = await api.post("/rsa/add-policy", formData).catch((error) => {
        throw error;
      });
      if (api.isSuccess(response)) {
        commit("setPolicyData", formData);
        return true;
      } else {
        console.error(response);
        throw response;
      }
    },
  },
}
export default actualPolicy;

