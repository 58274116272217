import api from "@rosfines/vue-common/common/api/osagoApi";
import {getV3Token, getV2Token} from "@/util/helpers/recaptcha";
import urlHelper from "@/util/helpers/url.helper";
import {BUSINESS_TYPES_ARR, PERSON_TYPES} from "@/constants/personTypes";
import CryptoJS from 'crypto-js';

let resolveWaitLoadingCarsList;
let rejectWaitLoadingCarsList;
let listLoad;
function reInitListLoad () {
  rejectWaitLoadingCarsList && rejectWaitLoadingCarsList();
  listLoad = new Promise((resolve, reject) => {
    resolveWaitLoadingCarsList = resolve;
    rejectWaitLoadingCarsList = reject;
  });
}
reInitListLoad();

const cars = {
  namespaced: true,
  state () {
    return {
      carsListLoaded: false,
      carsList: [],

      restoreCarRequest: false,
    }
  },
  getters: {
    getCarsList(state) {
      return state.carsList;
    },
    getCar: (state) => (carId) => {
      return state.carsList.filter(item => item.id === carId)[0] || {};
    },
    getCarStatus: (state, getters) => (carId) => {
      return getters.getCar(carId).status || 0;
    },
  },
  mutations: {
    saveCarsList(state, list) {
      if (Array.isArray(list)) {
        state.carsList = list;
        state.carsListLoaded = true;
      }
    },
    appendCar2List(state, car) {
      const isCarAlreadyOnList = state.carsList.find(item => {
        return item.id === car.car.id
      });

      if (!isCarAlreadyOnList) {
        state.carsList.push({
          id: car.car.id,
          vehiclePlate: car.car.vehiclePlate,
          vin: car.car.vin,
          category: car.car.category,
          brand: car.car.carModification.brand,
          model: car.car.carModification.model,
          modification: car.car.carModification.id,
          status: 1,
        });
      }
    },
    createRestoreRequest(state, data) {
      if (data && data.vehiclePlate) {
        state.restoreCarRequest = {
          vin: data.vin,
          vp: data.vehiclePlate,
          isCompany: data.isCompany,
          resolve: data.resolve,
          reject: data.reject,
        }
      } else {
        state.restoreCarRequest = false;
      }
    }
  },
  actions: {
    async waitLoadingCarsList() {
      return listLoad;
    },
    async chooseCar({dispatch, commit}, carId) {
      commit("form/_chooseCar", carId, { root: true });
      await dispatch("form/loadActiveCarData", undefined, { root: true });
    },
    async chooseCarByVP({state, dispatch}, {vehiclePlate, isCorp = false}) {
      if (state.carsListLoaded) {
        const carByVP = state.carsList.filter((car) => car.vehiclePlate === vehiclePlate);
        if (carByVP.length) {
          return await dispatch("chooseCar", carByVP[0].id);
        } else {
          return await dispatch("addCar", {vehiclePlate, personType: isCorp ? PERSON_TYPES.COMPANY : PERSON_TYPES.INDIVIDUAL});
        }
      } else {
        return await dispatch("addCar", {vehiclePlate, personType: isCorp ? PERSON_TYPES.COMPANY : PERSON_TYPES.INDIVIDUAL});
      }
    },
    async chooseCarById({dispatch}, {carId}) {
      await dispatch("chooseCar", carId);
    },
    async loadCarsInfo({dispatch}, {
      loadActiveCar= true
    }) {
      if (loadActiveCar) {
        await dispatch("form/loadActiveCarData", undefined, {root: true});
      }
      await dispatch("loadCarsList", loadActiveCar);
    },
    async loadCarsList({commit, dispatch}, loadActiveCar) {
      const response = await api.get("/policy-form/cars")
      if (api.isSuccess(response)) {
        commit("saveCarsList", response.data.items);
        if (response.data.items.length) {
          if (loadActiveCar) {
            await dispatch("form/setDefaultCarIfMissing", response.data.items[0].id, {root: true});
          }
        } else {
          const queryStringData = urlHelper.parseQueryString();
          const query = queryStringData.GET;
          const hasVP = query.vp && String(query.vp).length > 0;
          if (!hasVP) {
            commit("form/hasNoCar", undefined, {root: true});
          }
        }
        resolveWaitLoadingCarsList()
      } else {
        rejectWaitLoadingCarsList();
        throw new Error("Cars list loading failed")
      }
      return response;
    },
    async addCar({commit, dispatch}, {
      vehiclePlate,
      vin,
      category,
      isLeftHandDrive = undefined,
      restore = false,
      addToFines = false,
      personType,
    }) {
      let response = {};
      let interruptAdding = false;
      const v3Token = await getV3Token().catch((e) => {
        console.error(e);
        throw e;
      });
      response = await api.post("/policy-form", {
        vin,
        vehiclePlate,
        category,
        isLeftHandDrive,
        isAddToFines: addToFines,
        isCompany: BUSINESS_TYPES_ARR.includes(personType),
      }, undefined,{
        headers: {
          "recaptcha-token-v3": v3Token,
          "osago-policy-form-token": CryptoJS.MD5(api.getRequestParams().userId.toString()).toString()
        }
      }).catch( async (e) => {
        throw e;
      });
      if (response && response.status === 409) {
        interruptAdding = true;
        if (restore) {
          await dispatch("restoreCar", {vehiclePlate, vin, isCompany: BUSINESS_TYPES_ARR.includes(personType)});
        } else {
          //show modal
          await new Promise((resolve, reject) => {
            commit("createRestoreRequest", {
              vin,
              vehiclePlate,
              isCompany: BUSINESS_TYPES_ARR.includes(personType),
              resolve,
              reject
            });
          }).then(async () => {
            await dispatch("restoreCar");
          }).catch(() => {}).finally(() => {
            commit("createRestoreRequest");
          })
        }
      }
      if (interruptAdding) {
        return true;
      }
      if (response && !api.isSuccess(response) && response.data && response.data.data && response.data.data.invalidTokenV3) {
        const v2Token = await getV2Token().catch((e) => {
          console.error(e);
          throw e;
        });
        response = await api.post("/policy-form", {
          vin,
          vehiclePlate,
          category,
          isLeftHandDrive,
          isAddToFines: addToFines,
          isCompany: BUSINESS_TYPES_ARR.includes(personType),
        }, undefined, {
          headers: {
            "recaptcha-token-v3": v3Token,
            "recaptcha-token-v2": v2Token,
            "osago-policy-form-token": CryptoJS.MD5(api.getRequestParams().userId.toString()).toString()
          }
        }).catch((e) => {
          console.error(e);
          throw e;
        });
      }

      if (api.isSuccess(response)) {
        commit("appendCar2List", response.data);
        commit("form/carJustAdded", undefined, {root: true});
        commit("form/_chooseCar", response.data.car.id, {root: true});
        await dispatch("form/loadActiveCarData", undefined, {root: true});
      } else {
        console.error(response);
        throw api.throwError(response);
      }
      return response;
    },
    async restoreCar({state, dispatch}, {vehiclePlate, vin, isCompany}) {
      vehiclePlate = vehiclePlate || state.restoreCarRequest && state.restoreCarRequest.vp;
      vin = vin || state.restoreCarRequest && state.restoreCarRequest.vin;
      isCompany = isCompany || state.restoreCarRequest && state.restoreCarRequest.isCompany;

      if (vehiclePlate) {
        const response = await api.post("/policy-form/car/restore", {vehiclePlate, isCompany}).catch((e) => {
          console.error(e);
          throw e;
        });
        if (api.isSuccess(response)) {
          await dispatch("loadCarsList", false);
          return dispatch("chooseCarByVP", {vehiclePlate});
        } else {
          console.error(response);
          throw response;
        }
      } else if (vin) {
        const response = await api.post("/policy-form/car/restore", {vin, isCompany}).catch((e) => {
          console.error(e);
          throw e;
        });
        if (api.isSuccess(response)) {
          const carId = response.data.car.id;
          await dispatch("loadCarsList", false);
          return dispatch("chooseCarById", {carId});
        } else {
          console.error(response);
          throw response;
        }
      } else {
        throw 'vehiclePlate is missed for restore';
      }
    },
    async deleteCar({dispatch, commit}, {carId}) {
      const response = await api.delete(`/policy-form/car/${carId}`).catch((e) => {
        throw e;
      });
      if (api.isSuccess(response)) {
        commit('form/resetActiveCarId', undefined, {root: true});
        await dispatch("loadCarsList", true);
        return true;
      }
    },
    async addVehiclePlate({dispatch}, {carId, vehiclePlate}) {
      const response = await api.post(
        `/policy-form/car/${carId}/add-vehicle-plate`,
        {vehiclePlate}
      ).catch((e) => {
        console.error(e);
        throw e;
      });
      if (api.isSuccess(response)) {
        await dispatch("loadCarsList", false);
        await dispatch("form/loadActiveCarData", undefined, {root: true});
      } else {
        console.error(response);
        throw response;
      }
    },
  }
}

export default cars;
