<template>
  <div
    class="support-notifications-wrapper"
  >
    <div
      class="support-notifications"
      @click="$showModal('modal-notificationsWay')"
    >
      <div
        v-if="supportNotificationsCount > 0"
        class="support-notifications__count"
      >
        {{ supportNotificationsCountFormatted }}
      </div>
      <img
        v-if="black"
        src="@/assets/img/icons/bell-black.svg"
        alt=""
      >
      <picture
        v-else
      >
        <source srcset="@/assets/img/icons/bell-black.svg"
                media="(min-width: 992px)">
        <img src="@/assets/img/icons/bell.svg" alt="">
      </picture>
    </div>
  </div>

  <app-modal
    id="modal-notificationsWay"
    title="Уведомления"
  >
    <template #fullCustom>
      <p class="choose-way_text">Вы можете перейти к обращению в&nbsp;поддержку, или настроить уведомления</p>
      <div class="popup-control-block">
        <div class="container px-0">
          <div class="row">
            <div class="col-lg-6">
              <button
                type="button"
                class="c_btn_full btn-primary_new font-17 font-weight-semi mb-2"
                @click="openTickets"
              >
                Поддержка
              </button>
            </div>
            <div class="col-lg-6">
              <button
                type="button"
                class="c_btn_full btn-secondary font-17 font-weight-semi"
                @click="$router.push('notifications')"
              >
                Настроить уведомления
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import config from '@/config';
import finesApi from '@rosfines/vue-common/common/api/finesApi';
import { statService } from '@/services/statService';

export default {
  name: "SupportNotifications",
  props: {
    black: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      supportData: state => state.support.supportData,
    }),
    ...mapGetters({
      supportNotificationsCount: "support/supportNotificationsCount",
      hasSupportTickets: "support/hasSupportTickets",
    }),
    supportNotificationsCountFormatted() {
      return this.supportNotificationsCount > 99 ? "99+" : this.supportNotificationsCount;
    }
  },
  async mounted() {
    if (!Object.keys(this.supportData).length) {
      await this.getSupportSummary();
    }
    await this.$nextTick();
    if (this.hasSupportTickets && this.supportNotificationsCount > 0) {
      statService.logEvent('[Ситуация] Показан колокольчик с уведомлениями саппорта');
    }
  },
  methods: {
    ...mapActions({
      getSupportSummary: "support/getSupportSummary",
    }),
    openTickets() {
      statService.logEvent('[Действие] Нажал колокльчик перехода в саппорт');
      let url = new URL(`${config.COMMUNICATIONS_WEB_HOST}/support`);
      const authParams = finesApi.getRequestParams();
      for (const i in authParams) {
        url.searchParams.append(i, authParams[i]);
      }
      location.href = url.href;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.support-notifications-wrapper {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
  @include view-lg {
    flex: 0 1 auto;
    width: 56px;
    height: 56px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: $color-white;
    justify-content: center;
    align-items: center;
  }
}
.support-notifications {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  @include view-lg {
    &:before {
      content: "";
      position: absolute;
      top: -15px;
      right: -15px;
      bottom: -15px;
      left: -15px;
    }
  }
  &__count {
    position: absolute;
    right: -4px;
    top: -4px;
    width: 16px;
    height: 16px;
    padding: 2px;
    font-size: 9px;
    font-weight: 700;
    line-height: 12px;
    border-radius: 40px;
    background: #F44C4D;
    color: $color-white;
    text-align: center;
  }
}
.choose-way {
  &_text {
    color: #20273D;
    font-size: 15px;
    line-height: 22px;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 16px;
  }
}
.popup-control-block {
  @media (max-width: $lg-width) {
    margin: 0 -16px -16px;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 16px 16px 0 0;
  }
}
</style>
