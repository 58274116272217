import moment from "moment";
import IMask from "imask";
import {FRONT_DATE_FORMAT} from '@/constants/dateFormat';

const dateFormat = FRONT_DATE_FORMAT;

export default {
  mask: Date,
  pattern: dateFormat,
  lazy: false,

  format(date) {
    return moment(date).format(dateFormat);
  },
  parse(str) {
    return moment(str, dateFormat);
  },
  formatOut(innerVal) {
    const out = moment(innerVal && innerVal.value, dateFormat)
    return out.isValid() ? out.format(dateFormat) : "";
  },
  formatIn(outerVal) {
    return outerVal && moment(outerVal, dateFormat).format(dateFormat) || "";
  },
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: new Date().getFullYear()
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31
    }
  }
}
