export const PERSON_TYPES = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
  BUSINESSMAN: 'businessman',
}

export const BUSINESS_TYPES_ARR = [
  PERSON_TYPES.COMPANY,
  PERSON_TYPES.BUSINESSMAN,
]
export const BUSINESS_TYPES_SELECTOR = [
  PERSON_TYPES.INDIVIDUAL,
  PERSON_TYPES.COMPANY,
]
