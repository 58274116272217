<template>
  <main class="background-grey narrow-page">
    <page-header
      v-if="!preventContinue"
      :title="isStartScreenSingleInput ? 'Оформление полиса ОСАГО онлайн' : 'Автомобили'"
    />

    <Carousel
      v-if="!isStartScreenSingleInput"
      :items-to-show="1.4"
      :autoplay="0"
      :wrap-around="true"
      :breakpoints="{
        400: {
          itemsToShow: 1.8,
        },
        500: {
          itemsToShow: 2.5,
        },
      }"
      snap-align="start"
      class="mb-3 carousel"
    >
      <Slide v-for="slide in CAROUSEL_ITEMS" :key="slide">
        <div class="carousel__item">
          <div class="carousel__item_title"> {{ slide.title }} </div>

          <div class="carousel__item_desc"> {{ slide.description }} </div>
        </div>
      </Slide>
    </Carousel>

    <div
      class="page-content"
      :class="{'page-content_transparent page-content_no-pad': isStartScreenSingleInput}"
    >
      <div
        class="container container_adaptive"
        :class="{'container_no-pad': isStartScreenSingleInput}"
      >
        <div
          :class="{'row': isStartScreenSingleInput}"
        >
          <div
            :class="{'col col-lg-6': isStartScreenSingleInput}"
          >
            <div
              :class="{'form-wrapper': isStartScreenSingleInput}"
            >
              <div class="row">
                <div class="col-lg-12">
                  <osago-promo-banner page="start" />
                </div>
              </div>

              <div
                v-if="isStartScreenSingleInput"
              >
                <now-online class="d-lg-none" />
                <div class="trust-and-support-block trust-and-support-block_top d-lg-none" @click="$showModal('why-trust-us-modal')">
                  <div class="d-flex align-items-center">
                    <img
                      src="@/assets/img/icons/calculations/rosfines.png"
                      width="24"
                      height="24"
                      alt=""
                    >
                    <div class="trust-and-support-block__content">Нам доверяют, почему?</div>
                  </div>

                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.6859 10.1002V10.0591L10.6656 10.0798L6.00405 5.40009L8.09857 3.07031L17.0498 12.2298L8.09857 21.3892L6 19.2149L6.01154 19.1835L12.8072 12.2298L10.6859 10.1002Z" fill="#448AFF"/>
                  </svg>
                </div>
              </div>

              <div class="row content-wrapper">
                <div class="col-12">
                  <VeeForm
                    ref="validationObserver"
                    v-slot="{handleSubmit}"
                  >
                    <form
                      as="form"
                      @submit="handleSubmit($event, onNextPlate)"
                    >
                      <div
                        v-if="isStartScreenSingleInput"
                        class="short-input-block"
                      >
                        <div>
                          <AppInput
                            id="vehiclePlate"
                            ref="vehiclePlate"
                            v-model="vehiclePlate"
                            label="Госномер"
                            rules="required|vehiclePlateLength|vehiclePlateAny"
                            :mask="'vehiclePlateAny'"
                            uppercase="true"
                          />
                          <div class="inform_text_notice">
                            <div class="el__content">
                              <p class="el_text">
                                А000АА00, или 0000АА00, если страхуете мотоцикл
                              </p>
                            </div>
                            <div class="d-none d-lg-block pb-lg-3" />
                          </div>
                        </div>
                        <div>
                          <input-switch
                          :left-switcher="true"
                          :id="personType"
                          v-model="isPersonTypeLegal"
                          label="Страхователь — юр.лицо"
                        />
                        </div>
                      </div>
                      <template v-else>

                        <data-steps v-if="isLegalEntityEnabled" title="Тип транспортного средства" step="1" />

                        <TabGroup>
                          <TabList
                            as="div"
                            class="vehicle-type_tab-list"
                          >
                            <Tab
                              v-slot="{ selected }"
                              as="template"
                            >
                              <button
                                :class="{
                                  'vehicle-type_tab_selected': selected,
                                  'vehicle-type_tab vehicle-type_tab_mobile': true
                                }"
                                @click="changeCategory('')"
                              >
                                Автомобиль
                              </button>
                            </Tab>
                            <Tab
                              v-slot="{ selected }"
                              as="template"
                            >
                              <button
                                :class="{
                                  'vehicle-type_tab_selected': selected,
                                  'vehicle-type_tab vehicle-type_tab_mobile': true
                                }"
                                @click="changeCategory('A')"
                              >
                                Мотоцикл
                              </button>
                            </Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>
                              <AppInput
                                id="vehiclePlate"
                                ref="vehiclePlate"
                                v-model="vehiclePlate"
                                label="Госномер"
                                rules="required|vehiclePlateLength|vehiclePlate"
                                :mask="'vehiclePlate'"
                                uppercase="true"
                              />
                              <div class="inform_text_notice">
                                <div class="el__content">
                                  <p class="el_text">
                                    Например, А000АА00
                                  </p>
                                </div>
                                <div class="d-none d-lg-block pb-lg-3" />
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <AppInput
                                id="vehiclePlate"
                                ref="vehiclePlate"
                                v-model="vehiclePlate"
                                label="Госномер"
                                rules="required|vehiclePlateLength|vehiclePlateMoto"
                                :mask="'vehiclePlateMoto'"
                                uppercase="true"
                              />
                              <div class="inform_text_notice">
                                <div class="el__content">
                                  <p class="el_text">
                                    Например, 0000АА00
                                  </p>
                                </div>
                                <div class="d-none d-lg-block pb-lg-3" />
                              </div>
                            </TabPanel>
                          </TabPanels>
                        </TabGroup>

                        <data-steps v-if="isLegalEntityEnabled" title="Кто страхователь?" step="2" />

                        <TabGroup v-if="isLegalEntityEnabled" :selected-index="selectedPersonTypeIndex">
                          <TabList
                            as="div"
                            class="vehicle-type_tab-list"
                          >
                            <Tab
                              v-slot="{ selected }"
                              as="template"
                            >
                              <button
                                :class="{
                                  'vehicle-type_tab_selected': selected,
                                  'vehicle-type_tab vehicle-type_tab_mobile': true
                                }"
                                @click="setPersonType(PERSON_TYPES.INDIVIDUAL, 0)"
                              >
                                Физ.лицо
                              </button>
                            </Tab>
                            <Tab
                              v-slot="{ selected }"
                              as="template"
                              :disabled="category === 'A'"
                            >
                              <button
                                :class="{
                                  'vehicle-type_tab_selected': selected,
                                  'vehicle-type_tab vehicle-type_tab_mobile': true,
                                  'vehicle-type_tab vehicle-type_tab_mobile vehicle-type_tab_disabled': category === 'A'
                                }"
                                @click="setPersonType(PERSON_TYPES.COMPANY, 1)"
                              >
                                Юр.лицо
                              </button>
                            </Tab>
                          </TabList>
                        </TabGroup>
                      </template>

                      <div class="text-center">
                        <v-button
                          id="submit"
                          icon="arrow-right"
                          type="submit"
                          :disabled="loading"
                        >
                          Далее
                          <i class="icon-angle-right" />
                        </v-button>
                        <button
                          type="button"
                          @click="onClickNoPlate"
                          class="c_btn_full btn-secondary font-17 font-weight-semi mt-2"
                        >
                          Нет номера
                        </button>
                        <legal-documents />
                      </div>
                    </form>
                  </VeeForm>

                  <div
                    v-if="!isStartScreenSingleInput"
                    class="mt-3"
                  >
                    <osago-restrictions />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isStartScreenSingleInput"
            class="d-none d-lg-block col col-lg-6"
          >
            <div class="trust-and-support-blocks">
              <now-online />
              <div class="trust-and-support-block trust-and-support-block_top" @click="$showModal('why-trust-us-modal')">
                <div class="d-flex align-items-center">
                  <img
                    src="@/assets/img/icons/calculations/rosfines.png"
                    width="24"
                    height="24"
                    alt=""
                  >
                  <div class="trust-and-support-block__content">Нам доверяют, почему?</div>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M10.6859 10.1002V10.0591L10.6656 10.0798L6.00405 5.40009L8.09857 3.07031L17.0498 12.2298L8.09857 21.3892L6 19.2149L6.01154 19.1835L12.8072 12.2298L10.6859 10.1002Z" fill="#448AFF"/>
                </svg>
              </div>
              <div class="trust-and-support-block" @click="$showModal('questions-and-answers-modal')">
                <div class="d-flex align-items-center">
                  <div class="trust-and-support-block__left-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clip-path="url(#clip0_24100_206124)">
                        <path d="M4.18506 3.99219H7.67381C8.019 3.99219 8.29881 3.71237 8.29881 3.36719C8.29881 3.022 8.019 2.74219 7.67381 2.74219H4.18506C3.83987 2.74219 3.56006 3.022 3.56006 3.36719C3.56006 3.71237 3.83987 3.99219 4.18506 3.99219Z" fill="#448AFF"/>
                        <path d="M11.6298 10.4438C12.0089 10.0446 12.2019 9.52138 12.1733 8.97063C12.1196 7.936 11.2656 7.10916 10.2291 7.08828C9.22403 7.06847 8.35878 7.80353 8.21984 8.79913C8.20712 8.89019 8.20068 8.98319 8.20068 9.07556C8.20068 9.42075 8.4805 9.70056 8.82568 9.70056C9.17087 9.70056 9.45068 9.42075 9.45068 9.07556C9.45068 9.04078 9.45309 9.00591 9.45784 8.97188C9.50928 8.60297 9.83121 8.33 10.2039 8.33803C10.5884 8.34578 10.9051 8.65213 10.925 9.03544C10.9357 9.24041 10.864 9.43494 10.7233 9.58313C10.5824 9.73147 10.3925 9.81319 10.1884 9.81319C9.84318 9.81319 9.56337 10.093 9.56337 10.4382V11.0709C9.56337 11.4161 9.84318 11.6959 10.1884 11.6959C10.5336 11.6959 10.8134 11.4161 10.8134 11.0709V10.9616C11.1212 10.8592 11.4033 10.6823 11.6298 10.4438Z" fill="#448AFF"/>
                        <path d="M10.801 12.9127C10.7548 12.6669 10.5537 12.4713 10.3103 12.4221C10.0548 12.3704 9.78588 12.4899 9.65244 12.7137C9.5171 12.9405 9.54023 13.2372 9.71166 13.439C9.88298 13.6405 10.1716 13.7131 10.4178 13.6156C10.6946 13.506 10.8604 13.2056 10.801 12.9127Z" fill="#448AFF"/>
                        <path d="M15.1175 12.7361C15.0104 12.408 14.6576 12.2287 14.3294 12.3358C14.0013 12.4429 13.8221 12.7958 13.9292 13.1239L14.1629 13.8401L13.2173 13.7674C13.067 13.7558 12.9176 13.799 12.7967 13.8888C12.0383 14.4522 11.1363 14.75 10.1882 14.75C7.77405 14.75 5.80996 12.7868 5.80996 10.3737C5.80996 7.96063 7.77405 5.99744 10.1882 5.99744C12.4179 5.99744 14.2881 7.66456 14.5384 9.87528C14.5772 10.2183 14.8866 10.4647 15.2298 10.426C15.5728 10.3872 15.8193 10.0776 15.7805 9.73462C15.5149 7.38966 13.833 5.52028 11.6436 4.93716V2.50847C11.6436 1.12531 10.518 0 9.13443 0H2.72455C1.34096 0 0.215332 1.12531 0.215332 2.50847V6.97166C0.215332 8.24019 1.16221 9.29184 2.38646 9.45747V9.95391C2.38646 10.1674 2.49543 10.3661 2.67543 10.4809C2.77746 10.5459 2.89421 10.5789 3.01149 10.5789C3.10121 10.5789 3.19127 10.5596 3.27518 10.5206L4.57864 9.91397C4.5663 10.0656 4.56002 10.2189 4.56002 10.3737C4.56002 13.4761 7.08483 16 10.1883 16C11.3266 16 12.4135 15.6658 13.347 15.0311L14.9993 15.1581C15.2077 15.1742 15.4097 15.0852 15.5386 14.9212C15.6676 14.7571 15.7061 14.5395 15.6413 14.3411L15.1175 12.7361ZM3.63643 8.97372V8.85519C3.63643 8.51 3.35661 8.23019 3.01143 8.23019H2.72455C2.03018 8.23019 1.4653 7.66562 1.4653 6.97169V2.50847C1.46533 1.81456 2.03021 1.25 2.72455 1.25H9.13439C9.82874 1.25 10.3936 1.81456 10.3936 2.50847V4.75116C10.3254 4.74872 10.257 4.74747 10.1882 4.74747C9.17427 4.74747 8.22214 5.01691 7.39961 5.48784H4.18511C3.83993 5.48784 3.56011 5.76766 3.56011 6.11284C3.56011 6.45803 3.83993 6.73784 4.18511 6.73784H5.89633C5.48693 7.22003 5.15721 7.77187 4.92771 8.37278L3.63643 8.97372Z" fill="#448AFF"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_24100_206124">
                          <rect width="16" height="16" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>


                  <div class="trust-and-support-block__content">Вопросы и ответы</div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M10.6859 10.1002V10.0591L10.6656 10.0798L6.00405 5.40009L8.09857 3.07031L17.0498 12.2298L8.09857 21.3892L6 19.2149L6.01154 19.1835L12.8072 12.2298L10.6859 10.1002Z" fill="#448AFF"/>
                </svg>
              </div>
              <div class="trust-and-support-block" @click="$showModal('support-request-modal')">
                <div class="d-flex align-items-center">
                  <div class="trust-and-support-block__left-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clip-path="url(#clip0_24100_206134)">
                        <path d="M3.625 10.5C3.97019 10.5 4.25 10.2202 4.25 9.875C4.25 9.52981 3.97019 9.25 3.625 9.25C3.27981 9.25 3 9.52981 3 9.875C3 10.2202 3.27981 10.5 3.625 10.5ZM4.875 12.375C4.34672 12.375 3.873 12.0399 3.69616 11.5412L2.518 11.9588C2.87131 12.9554 3.81853 13.625 4.875 13.625C5.93147 13.625 6.87869 12.9554 7.232 11.9588L6.05384 11.5412C5.877 12.0399 5.40328 12.375 4.875 12.375ZM6.125 10.5C6.47019 10.5 6.75 10.2202 6.75 9.875C6.75 9.52981 6.47019 9.25 6.125 9.25C5.77981 9.25 5.5 9.52981 5.5 9.875C5.5 10.2202 5.77981 10.5 6.125 10.5ZM9.875 4.25C10.2202 4.25 10.5 3.97019 10.5 3.625C10.5 3.27981 10.2202 3 9.875 3C9.52981 3 9.25 3.27981 9.25 3.625C9.25 3.97019 9.52981 4.25 9.875 4.25ZM13 3.625C13 3.27981 12.7202 3 12.375 3C12.0298 3 11.75 3.27981 11.75 3.625C11.75 3.97019 12.0298 4.25 12.375 4.25C12.7202 4.25 13 3.97019 13 3.625ZM11.125 0C8.36128 0 6.25 2.11584 6.25 4.875C6.25 4.98522 6.25362 5.09666 6.26081 5.20616L7.50812 5.12444C7.50272 5.04203 7.5 4.95809 7.5 4.875C7.5 2.80841 9.05841 1.25 11.125 1.25C13.1916 1.25 14.75 2.80841 14.75 4.875C14.75 6.94159 13.1916 8.5 11.125 8.5C11.0419 8.5 10.958 8.49728 10.8756 8.49187L10.7938 9.73919C10.9033 9.74638 11.0148 9.75 11.125 9.75C13.8887 9.75 16 7.63416 16 4.875C16 2.11128 13.8842 0 11.125 0ZM4.875 6.25C2.11128 6.25 0 8.36584 0 11.125C0 13.8887 2.11584 16 4.875 16C7.63872 16 9.75 13.8842 9.75 11.125C9.75 8.36128 7.63416 6.25 4.875 6.25ZM4.875 14.75C2.80841 14.75 1.25 13.1916 1.25 11.125C1.25 9.05841 2.80841 7.5 4.875 7.5C6.94159 7.5 8.5 9.05841 8.5 11.125C8.5 13.1916 6.94159 14.75 4.875 14.75ZM12.3038 6.95884L13.482 6.54116C13.1287 5.54456 12.1815 4.875 11.125 4.875C10.0685 4.875 9.12131 5.54456 8.768 6.54116L9.94616 6.95884C10.123 6.46009 10.5967 6.125 11.125 6.125C11.6533 6.125 12.127 6.46009 12.3038 6.95884Z" fill="#448AFF"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_24100_206134">
                          <rect width="16" height="16" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>


                  <div class="trust-and-support-block__content">Сложности при расчёте?</div>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M10.6859 10.1002V10.0591L10.6656 10.0798L6.00405 5.40009L8.09857 3.07031L17.0498 12.2298L8.09857 21.3892L6 19.2149L6.01154 19.1835L12.8072 12.2298L10.6859 10.1002Z" fill="#448AFF"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isStartScreenSingleInput"
    >
      <div class="funnel-list-wrapper">
        <funnel-list
          :show-badges="true"
          title="Другие сегодня купили"
        />
      </div>
    </div>

    <div
      v-if="isStartScreenSingleInput"
      class="trust-and-support-blocks d-lg-none"
    >
      <div class="trust-and-support-block" @click="$showModal('questions-and-answers-modal')">
        <div class="d-flex align-items-center">
          <div class="trust-and-support-block__left-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_24100_206124)">
                <path d="M4.18506 3.99219H7.67381C8.019 3.99219 8.29881 3.71237 8.29881 3.36719C8.29881 3.022 8.019 2.74219 7.67381 2.74219H4.18506C3.83987 2.74219 3.56006 3.022 3.56006 3.36719C3.56006 3.71237 3.83987 3.99219 4.18506 3.99219Z" fill="#448AFF"/>
                <path d="M11.6298 10.4438C12.0089 10.0446 12.2019 9.52138 12.1733 8.97063C12.1196 7.936 11.2656 7.10916 10.2291 7.08828C9.22403 7.06847 8.35878 7.80353 8.21984 8.79913C8.20712 8.89019 8.20068 8.98319 8.20068 9.07556C8.20068 9.42075 8.4805 9.70056 8.82568 9.70056C9.17087 9.70056 9.45068 9.42075 9.45068 9.07556C9.45068 9.04078 9.45309 9.00591 9.45784 8.97188C9.50928 8.60297 9.83121 8.33 10.2039 8.33803C10.5884 8.34578 10.9051 8.65213 10.925 9.03544C10.9357 9.24041 10.864 9.43494 10.7233 9.58313C10.5824 9.73147 10.3925 9.81319 10.1884 9.81319C9.84318 9.81319 9.56337 10.093 9.56337 10.4382V11.0709C9.56337 11.4161 9.84318 11.6959 10.1884 11.6959C10.5336 11.6959 10.8134 11.4161 10.8134 11.0709V10.9616C11.1212 10.8592 11.4033 10.6823 11.6298 10.4438Z" fill="#448AFF"/>
                <path d="M10.801 12.9127C10.7548 12.6669 10.5537 12.4713 10.3103 12.4221C10.0548 12.3704 9.78588 12.4899 9.65244 12.7137C9.5171 12.9405 9.54023 13.2372 9.71166 13.439C9.88298 13.6405 10.1716 13.7131 10.4178 13.6156C10.6946 13.506 10.8604 13.2056 10.801 12.9127Z" fill="#448AFF"/>
                <path d="M15.1175 12.7361C15.0104 12.408 14.6576 12.2287 14.3294 12.3358C14.0013 12.4429 13.8221 12.7958 13.9292 13.1239L14.1629 13.8401L13.2173 13.7674C13.067 13.7558 12.9176 13.799 12.7967 13.8888C12.0383 14.4522 11.1363 14.75 10.1882 14.75C7.77405 14.75 5.80996 12.7868 5.80996 10.3737C5.80996 7.96063 7.77405 5.99744 10.1882 5.99744C12.4179 5.99744 14.2881 7.66456 14.5384 9.87528C14.5772 10.2183 14.8866 10.4647 15.2298 10.426C15.5728 10.3872 15.8193 10.0776 15.7805 9.73462C15.5149 7.38966 13.833 5.52028 11.6436 4.93716V2.50847C11.6436 1.12531 10.518 0 9.13443 0H2.72455C1.34096 0 0.215332 1.12531 0.215332 2.50847V6.97166C0.215332 8.24019 1.16221 9.29184 2.38646 9.45747V9.95391C2.38646 10.1674 2.49543 10.3661 2.67543 10.4809C2.77746 10.5459 2.89421 10.5789 3.01149 10.5789C3.10121 10.5789 3.19127 10.5596 3.27518 10.5206L4.57864 9.91397C4.5663 10.0656 4.56002 10.2189 4.56002 10.3737C4.56002 13.4761 7.08483 16 10.1883 16C11.3266 16 12.4135 15.6658 13.347 15.0311L14.9993 15.1581C15.2077 15.1742 15.4097 15.0852 15.5386 14.9212C15.6676 14.7571 15.7061 14.5395 15.6413 14.3411L15.1175 12.7361ZM3.63643 8.97372V8.85519C3.63643 8.51 3.35661 8.23019 3.01143 8.23019H2.72455C2.03018 8.23019 1.4653 7.66562 1.4653 6.97169V2.50847C1.46533 1.81456 2.03021 1.25 2.72455 1.25H9.13439C9.82874 1.25 10.3936 1.81456 10.3936 2.50847V4.75116C10.3254 4.74872 10.257 4.74747 10.1882 4.74747C9.17427 4.74747 8.22214 5.01691 7.39961 5.48784H4.18511C3.83993 5.48784 3.56011 5.76766 3.56011 6.11284C3.56011 6.45803 3.83993 6.73784 4.18511 6.73784H5.89633C5.48693 7.22003 5.15721 7.77187 4.92771 8.37278L3.63643 8.97372Z" fill="#448AFF"/>
              </g>
              <defs>
                <clipPath id="clip0_24100_206124">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>


          <div class="trust-and-support-block__content">Вопросы и ответы</div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M10.6859 10.1002V10.0591L10.6656 10.0798L6.00405 5.40009L8.09857 3.07031L17.0498 12.2298L8.09857 21.3892L6 19.2149L6.01154 19.1835L12.8072 12.2298L10.6859 10.1002Z" fill="#448AFF"/>
        </svg>
      </div>
      <div class="trust-and-support-block" @click="$showModal('support-request-modal')">
        <div class="d-flex align-items-center">
          <div class="trust-and-support-block__left-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_24100_206134)">
                <path d="M3.625 10.5C3.97019 10.5 4.25 10.2202 4.25 9.875C4.25 9.52981 3.97019 9.25 3.625 9.25C3.27981 9.25 3 9.52981 3 9.875C3 10.2202 3.27981 10.5 3.625 10.5ZM4.875 12.375C4.34672 12.375 3.873 12.0399 3.69616 11.5412L2.518 11.9588C2.87131 12.9554 3.81853 13.625 4.875 13.625C5.93147 13.625 6.87869 12.9554 7.232 11.9588L6.05384 11.5412C5.877 12.0399 5.40328 12.375 4.875 12.375ZM6.125 10.5C6.47019 10.5 6.75 10.2202 6.75 9.875C6.75 9.52981 6.47019 9.25 6.125 9.25C5.77981 9.25 5.5 9.52981 5.5 9.875C5.5 10.2202 5.77981 10.5 6.125 10.5ZM9.875 4.25C10.2202 4.25 10.5 3.97019 10.5 3.625C10.5 3.27981 10.2202 3 9.875 3C9.52981 3 9.25 3.27981 9.25 3.625C9.25 3.97019 9.52981 4.25 9.875 4.25ZM13 3.625C13 3.27981 12.7202 3 12.375 3C12.0298 3 11.75 3.27981 11.75 3.625C11.75 3.97019 12.0298 4.25 12.375 4.25C12.7202 4.25 13 3.97019 13 3.625ZM11.125 0C8.36128 0 6.25 2.11584 6.25 4.875C6.25 4.98522 6.25362 5.09666 6.26081 5.20616L7.50812 5.12444C7.50272 5.04203 7.5 4.95809 7.5 4.875C7.5 2.80841 9.05841 1.25 11.125 1.25C13.1916 1.25 14.75 2.80841 14.75 4.875C14.75 6.94159 13.1916 8.5 11.125 8.5C11.0419 8.5 10.958 8.49728 10.8756 8.49187L10.7938 9.73919C10.9033 9.74638 11.0148 9.75 11.125 9.75C13.8887 9.75 16 7.63416 16 4.875C16 2.11128 13.8842 0 11.125 0ZM4.875 6.25C2.11128 6.25 0 8.36584 0 11.125C0 13.8887 2.11584 16 4.875 16C7.63872 16 9.75 13.8842 9.75 11.125C9.75 8.36128 7.63416 6.25 4.875 6.25ZM4.875 14.75C2.80841 14.75 1.25 13.1916 1.25 11.125C1.25 9.05841 2.80841 7.5 4.875 7.5C6.94159 7.5 8.5 9.05841 8.5 11.125C8.5 13.1916 6.94159 14.75 4.875 14.75ZM12.3038 6.95884L13.482 6.54116C13.1287 5.54456 12.1815 4.875 11.125 4.875C10.0685 4.875 9.12131 5.54456 8.768 6.54116L9.94616 6.95884C10.123 6.46009 10.5967 6.125 11.125 6.125C11.6533 6.125 12.127 6.46009 12.3038 6.95884Z" fill="#448AFF"/>
              </g>
              <defs>
                <clipPath id="clip0_24100_206134">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>


          <div class="trust-and-support-block__content">Сложности при расчёте?</div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M10.6859 10.1002V10.0591L10.6656 10.0798L6.00405 5.40009L8.09857 3.07031L17.0498 12.2298L8.09857 21.3892L6 19.2149L6.01154 19.1835L12.8072 12.2298L10.6859 10.1002Z" fill="#448AFF"/>
        </svg>
      </div>
    </div>


    <faq-component
      v-else
      page-name="carData"
    />

    <loading
      v-if="fullScreenLoading"
      :active="true"
    />

  </main>

  <app-modal
    id="modal-vin"
    title="Оформление без номера"
    :isGray="false"
  >
    <template #fullCustom>
      <VeeForm
        ref="byVinValidationObserver"
        v-slot="{handleSubmit}"
        class="popup-content-block content-slot"
        :class="{
          'popup-content-block_alt': isStartScreenSingleInput
        }"
      >
        <form
          id="noVinForm"
          @submit="handleSubmit($event, onNextVin)"
        >
          <div>
            <template v-if="isStartScreenSingleInput">
              <p>
                По закону, для постановки авто в ГАИ госномер в полисе не обязателен. ОСАГО без номера будет действителен, а номер можно добавить позже.
              </p>
              <p class="vin-title">
                Заполните для расчёта без номера
              </p>
            </template>
            <template v-else>
              <p>
                Если у вашего автомобиля нет номера, или вы планируете его сменить — продолжайте оформление без номера
              </p>
              <info-block
                no-margin
                title="Подойдет для учета"
                desc="В ГИБДД можно поставить авто на учёт с распечатанным полисом даже без вписанного номера. Номер можно будет вписать в полис после постановки на учет, либо при следующем продлении полиса ОСАГО"
              />
            </template>
            <div class="container px-0">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <vin-input
                    screen="Добавление автомобиля"
                    :car-data="carData"
                    :show-pills="false"
                    :vin-label="isStartScreenSingleInput ? 'VIN номер' : 'VIN'"
                    :showInformTexts="false"
                    no-gaps
                    @change-vin="onVinChange"
                    @change-body-number="onBodyNumChange"
                    @on-change-no-vin="onChangeNoVin"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </VeeForm>
      <div
        class="popup-control-block"
        :class="{
          'popup-control-block_alt': isStartScreenSingleInput
        }"
      >
        <button
          type="submit"
          class="c_btn_full btn-primary_new font-17 font-weight-semi"
          form="noVinForm"
        >
          <template v-if="isStartScreenSingleInput">Рассчитать ОСАГО</template>
          <template v-else>Продолжить без номера</template>
        </button>
        <button
          type="button"
          class="c_btn_full btn-white_new font-17 font-weight-semi"
          @click="closeVinModal"
        >
          Ввести номер
        </button>
      </div>
    </template>
  </app-modal>

  <app-modal
    id="modal-question-vin"
    title="VIN номер"
  >
    <p>
      Находится в верхней части свидетельства&nbsp;о&nbsp;регистрации<br>
      Ввод&nbsp;доступен <span class="font-weight-bold">только латинскими буквами и&nbsp;цифрами</span>
    </p>
    <img
      alt="Свидетельство о регистрации транспортного средства, VIN номер"
      class="img-fluid mx-auto d-block"
      src="@/assets/img/vin.png"
    >
  </app-modal>

  <app-modal
    id="modal-question-body-number"
    title="Номер кузова"
  >
    <p>
      Вводите номер кузова только,  если у вас нет VIN-номера.<br>
      Ввод&nbsp;доступен только <span class="font-weight-bold">латинскими буквами и&nbsp;цифрами</span>
    </p>
    <img
      alt="Номер кузова"
      class="img-fluid mx-auto d-block"
      src="@/assets/img/body-number.png"
    >
  </app-modal>

  <why-trust-us-modal
    confirm-button-label="Продолжить оформление"
  />

  <support-request-modal v-if="!loading && !isNotificationsLoading" />

  <questions-and-answers-modal
    page-name="carData"
  />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {statService} from "@/services/statService";
import statEventsHelper from "@/util/helpers/statEvents.helper";
import swalHelper from "@/util/helpers/swal.helper";
import PageHeader from "@/components/layout/PageHeader";
import {mapActions, mapGetters, mapState} from "vuex";
import { Form as VeeForm } from "vee-validate";
import LegalDocuments from '@/components/blocks/LegalDocuments';
import InfoBlock from "@/components/blocks/misc/InfoBlock.vue";
import VinInput from "@/components/fields/VinInput.vue";
import FaqComponent from "@/components/faq/FaqComponent.vue";
import OsagoPromoBanner from "@/components/temp/OsagoPromoBanner.vue";
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from "@headlessui/vue";
import OsagoRestrictions from "@/components/blocks/OsagoRestirctions.vue";
import DataSteps from "@/components/blocks/misc/DataSteps.vue";
import {PERSON_TYPES} from "@/constants/personTypes";
import { CAROUSEL_ITEMS } from '@/constants/enterNumberPageCarouselItems';
import { Carousel, Navigation, Slide } from 'vue3-carousel';

import WhyTrustUsModal from '@/components/blocks/Calculations/modals/WhyTrustUsModal';
import SupportRequestModal from '@/components/blocks/Calculations/modals/SupportRequestModal';
import QuestionsAndAnswersModal from '@/components/blocks/Calculations/modals/QuestionsAndAnswersModal';
import FunnelList from '@/components/blocks/RecentPolicies/FunnelList';
import NowOnline from '@/components/blocks/misc/NowOnline.vue';

import 'vue3-carousel/dist/carousel.css'

export default {
  name: "StartPage",
  components: {
    DataSteps,
    OsagoRestrictions,
    TabPanel,
    TabPanels,
    Tab,
    TabList,
    TabGroup,
    OsagoPromoBanner,
    FaqComponent,
    VinInput,
    InfoBlock,
    Loading,
    PageHeader,
    VeeForm,
    LegalDocuments,
    Carousel,
    Navigation,
    Slide,
    WhyTrustUsModal,
    SupportRequestModal,
    QuestionsAndAnswersModal,
    FunnelList,
    NowOnline,
  },
  data() {
    return {
      loading: true,
      vehiclePlate: '',
      isLeftHandDrive: true,
      carData: {
        vin: '',
        noVin: false,
        bodyNumber: '',
      },
      category: '',
      personType: PERSON_TYPES.INDIVIDUAL,
      selectedPersonTypeIndex: 0,
    };
  },
  computed: {
    ...mapState({
      isNotificationsLoading: state => state.notifications.isNotificationsLoading,
    }),
    ...mapGetters({
      isLegalEntityEnabled: "GBFeatures/isLegalEntityEnabled",
      isEntryPageDisabledWithAdditionalInfo: "GBFeatures/isEntryPageDisabledWithAdditionalInfo",
      startScreenSingleInputCohort: "GBFeatures/startScreenSingleInputCohort",
    }),
    isStartScreenSingleInput() {
      return this.startScreenSingleInputCohort === 'test';
    },
    isPersonTypeLegal: {
      get() {
        return this.personType === this.PERSON_TYPES.COMPANY
      },
      set(isTrue) {
        if (isTrue) {
          this.setPersonType(PERSON_TYPES.COMPANY, 1);
        } else {
          this.setPersonType(PERSON_TYPES.INDIVIDUAL, 0);
        }
      }
    },
    CAROUSEL_ITEMS() {
      return CAROUSEL_ITEMS;
    },
    PERSON_TYPES() {
      return PERSON_TYPES;
    },
    ...mapState({
      carsListLoaded: state => state.cars.carsListLoaded,
    }),
    fullScreenLoading() {
      return !this.carsListLoaded || this.loading;
    },
    preventContinue() {
      return this.$route.query.preventContinue || false;
    },
    addToFines() {
      return !!this.$route.query.addToFines || false;
    },
    faqPageName() {
      return this.isEntryPageDisabledWithAdditionalInfo ? 'landingForDisabledEntryPageUsers' : 'landing';
    },
  },

  watch: {
    category() {
      if (!this.isStartScreenSingleInput) {
        this.$nextTick(() => {
          this.vehiclePlate = null;
        })
      }
    },
    vehiclePlate(val) {
      if (this.isStartScreenSingleInput) {
        if (/^\d/.test(val)) {
          this.changeCategory('A')
          this.isPersonTypeLegal = false
        } else {
          this.changeCategory('')
        }
      }
    }
  },

  async mounted() {

    await this.waitGBFeaturesLoad();

    this.loading = false;

    statService.setStatServiceDataParam(this.$route);

    // Дефолтный эвент для экспериментальной воронки
    statEventsHelper.sendEventFromSmsOrPush("Стартовый");
    statService.logEvent('[Экран] Стартовый');

    if (this.$route.query.vin) {
      this.$showModal("modal-vin");
    }
  },

  methods: {
    ...mapActions({
      addCar: "cars/addCar",
      waitGBFeaturesLoad: "GBFeatures/waitGBFeaturesLoad",
    }),
    changeCategory(category) {
      this.category = category;
      if (this.category === 'A') {
        this.setPersonType(PERSON_TYPES.INDIVIDUAL, 0)
      }
    },
    onVinChange(value) {
      this.carData.vin = value;
    },
    onBodyNumChange(value) {
      this.carData.bodyNumber = value;
    },
    onChangeNoVin(value) {
      this.carData.noVin = value;
    },
    onClickNoPlate() {
      this.$showModal("modal-vin");
    },
    closeVinModal() {
      this.$hideModal("modal-vin");
    },
    onNextPlate() {
      this.onNext(false);
    },
    onNextVin() {
      this.onNext(true)
    },
    async onNext(isVin) {
      this.loading = true;
      let validate;
      let eventData = {};

      if (isVin) {
        validate = await this.$refs.byVinValidationObserver.validate();
        this.$hideModal("modal-vin");
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent('[Действие] Ввел VIN на стартовом экране', eventData);
      } else {
        validate = await this.$refs.validationObserver.validate();
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent('[Действие] Нажал далее на стартовом экране', eventData);
      }

      if(validate && validate.valid) {
        const res = await this.addCar({
          restore: true,
          vin: isVin ? this.carData.vin : null,
          vehiclePlate: isVin ? null : String(this.vehiclePlate).toUpperCase(),
          isLeftHandDrive: this.isLeftHandDrive,
          addToFines: this.addToFines,
          category: this.category,
          personType: this.personType,
        }).catch((e) => {
          swalHelper.showResponseError(e);
          if (e && e.data && e.data.fields && e.data.fields.vehiclePlate && e.data.fields.vehiclePlate[0]) {
            this.$refs.vehiclePlate.setError(e.data.fields.vehiclePlate[0]);
          }
        });
        if (this.preventContinue) {
          window.top.postMessage({
            source: 'osagoApp',
            action: 'carAdded',
            success: true
          }, "*");
        } else {
          if (res) {
            await this.$router.push({ name: 'formContinue' });
          }
        }
      }
      this.loading = false;
    },
    setPersonType(personType, idx) {
      this.personType = personType;
      this.selectedPersonTypeIndex = idx;
      statService.logEvent(`[Действие] Выбрал тип анкеты "${personType === PERSON_TYPES.INDIVIDUAL ? "Физ. Лицо" : "Юр. Лицо"}"`, {});
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';
@import "@/assets/styles/_mixins.scss";

.docs-list {
  list-style: none;
  margin: 0 0 6*$pixel-size;
  padding: 0;
  display: flex;
  justify-content: space-between;
  > li {
    flex: 0 1 auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    padding: 0;
    font-size: 15px;
    line-height: 20px;
    white-space: nowrap;
    &.sepa {
      border-left: 1px solid $color-gray-border;
      flex: 0 1 1px;
    }
    > svg {
      margin-right: 8px;
    }
  }
}

.popup-control-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 8px;
  margin: 0 0-16px 0-16px;
  padding: 16px;
  border-radius: 16px 16px 0 0;
  background: $color-white;
  box-shadow: 0 0 16px 0 rgba(32, 39, 61, 0.15);
  @include view-lg {
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    flex-wrap: nowrap;
  }
  &_alt {
    box-shadow: none;
    margin-top: -16px !important;
    @include view-lg {
      margin-top: 0 !important;
      flex-direction: row-reverse;
    }
  }
}

.background-grey {
  background: $color-gray-white;
}

.content-wrapper {
  @media (max-width: 976px) {
    background: $color-white;
    border-radius: 16px;
    padding: 24px 8px 0;
  }
}

.carousel {
  @include view-lg {
    display: none;
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 12px 12px 32px 12px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 14px;
    text-align: left;
    height: 130px;

    border-radius: 12px;
    border: 1px solid $color-main;
    background: $color-white;

    &_title {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      color: $color-main;
    }

    &_desc {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $color-gray;
    }
  }
}
.short-input-block {
  margin: 0 0 16px;
}
.trust-and-support-blocks {
  margin: 0 8px 12px;
}
.trust-and-support-block {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #FFF;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 12px;

    @include view-lg {
      margin-bottom: 8px;
    }
  }

  &__content {
    color: $color-dark;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-left: 8px;
  }

  &__left-icon {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: #F0F6FF;
  }
  &_top {
    margin: 0 0 24px;
    @include view-lg {
      margin: 0;
    }
  }
}
.funnel-list-wrapper {
  margin: 0 -8px 24px;
  padding: 8px;
  max-width: 747px;
  background: #FFFFFF;
  @include view-lg {
    padding: 0;
    background: none;
    margin: 0 auto 24px;
  }
}
.form-wrapper {
  @include view-lg {
    background: $color-white;
    padding: 16px;
    border-radius: 8px;
  }
}
.page-content_no-pad {
  @include view-lg {
    padding-left: 0;
    padding-right: 0;
  }
}
.container_no-pad {
  @include view-lg {
    padding-left: 0;
    padding-right: 0;
  }
}
.start-page-faq-wrapper {
  margin: 0 auto;
  max-width: 731px;
}
.popup-content-block_alt {
  padding: 0 !important;
  border-radius: 0 !important;
}
.vin-title {
  color: #20273D;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin: 0 0 -6px;
}
.c_btn_full.btn-white_new {
  background: #F2F4F6;
}
</style>
