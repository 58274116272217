export const AB_TESTS = {
  BONUS_NEW_USERS: {
    name: "osagoBonusNewUsers",
    cohorts: {
      CONTROL1: "control-1",
      CONTROL2: "control-2",
      TEST: "test",
    }
  },
  BONUS_OLD_USERS: {
    name: "osagoBonusOldUsers",
    cohorts: {
      CONTROL1: "control-1",
      CONTROL2: "control-2",
      TEST: "test",
    }
  },
};

export const VERSIONS_KBM_SPLIT_TEST = {
  OLD_VERSION: 1,
  SECOND_VERSION: 2,
  THIRD_VERSION: 3,
  OUTSIDE_THE_EXPERIMENT: 4,
}

export const VERSIONS_ENTRY_PAGE_BTN_TEST = {
  CALCULATE: 'Рассчитать',
  FIND_OUT_PRICES: 'Узнать цены',
  READ_MORE: 'Подробнее',
  OUTSIDE_THE_EXPERIMENT: 'Оформить ОСАГО',
}

export const VERSIONS_PREMIUM_SUPPORT_TEST = [2,3,4]

export const GET_FAKE_CALCULATION = (featureValue) => {
  const prices = {
    2: 299,
    3: 349,
    4: 499,
  }
  return [
    {
      "serviceCode": "premium-support",
      "insuranceCompanyId": 999,
      "calculationId": 999,
      "dateFrom": "2024-03-15",
      "dateTo": "2025-03-14",
      "price": prices[featureValue] || 0
    },
  ]
}

export const VERSIONS_CALCULATIONS_TEST = {
  OLD_VERSION: 'old',
  NEW_VERSION: 'new',
  OUTSIDE_THE_EXPERIMENT: 'old',
};

export const VERSIONS_OFFERS_TEST = {
  OLD_VERSION: 'old',
  NEW_VERSION: 'new',
  OUTSIDE_THE_EXPERIMENT: 'old',
};

export const OCR_FAKEDOOR_TEST = {
  CONTROL1: "control1",
  CONTROL2: "control2",
  TEST1: "test1",
  TEST2: "test2",
  TEST3: "test3",
}
