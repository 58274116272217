import trackingApi from './tracking'
import urlHelper from "@/util/helpers/url.helper";
import store from "@/store";
import DeviceStorage from "@/util/helpers/device-storage";
import finesApi from '@rosfines/vue-common/common/api/finesApi';

let eventsPropsFromFunnel = {
  eventId: '',
}
function getAbTestData() {
  return store.dispatch("abtest/getAbTestData");
}

export const statService = {
  /**
   * Допустимые к существованию поля в eventProperties
   */
  allowFields: {
    experimentId: 1,
    cohortId: 1,
    source: 1,
    mod: 1
  },

  userProperties: null,

  /**
   * @description Добавляет новые параметры в аргумент statisticsData, который отдаём в амплитуду.
   *  Только в том случае, если они есть.
   * @param {Object} $route
   * @param {Object} statisticsData
   * @param {Object} userProperties
   */
  setStatServiceDataParam($route, statisticsData = {}, userProperties = {}) {

    if (!userProperties) {
      userProperties = {};
    }

    let query = $route.query;

    let data = urlHelper.parseQueryString();
    if (data.length && data.length > 0) {
      query = data.GET;
    }

    // Допустимые для отправки в кликхаус параметры. (через traking.js)
    // Из url будем брать только их
    const allowProperties = {
      userId: 1,
      session: 1,
      rand: 1,
      eventId: 1,
      experimentId: 1,
      cohortId: 1,
      source: 1,
      mod: 1,
      hasCost: 1,
    };


    for (const prop in query) {
      if (['rand', 'session'].includes(prop)) {
        continue;
      }

      // В кликхаус могут записываться только перечисленные в allowProperties
      if (!allowProperties[prop]) {
        continue;
      }

      statisticsData[prop] = query[prop];

    }
  },

  init() {
    store.dispatch("abtest/getAbTestData");
    const utmMarketing = store.state.app.utmMarketing;
    if (utmMarketing) {
      this.userProperties = {
        utmMarketing
      };
    }
  },

  /**
   * @param {String} eventType
   * @param {Object} eventProperties {experimentId:String, cohortId:String, source:String, mod:String}
   * Значения параметров (в том числе полей eventProperties) должны быть в snake_case и не длиннее 255 символов
   */
  logEvent(eventType, eventProperties = {}) {
    let mod = urlHelper.parseQueryString(location.href).GET.mod || "";
    let eventData = {
      ...this.userProperties,
      ...eventsPropsFromFunnel,
      ...eventProperties
    };
    Promise.all([getAbTestData()]).then(([abData]) => {
      // eventData = {...eventData, ...abData}; // @ToDo Разобраться с abData сервисом, т.к. конфликт с growthbook

      if (!mod && eventProperties.mod) {
        mod = eventProperties.mod;
      }
      statService.setStatServiceDataParam({query: {}}, eventData);

      const statisticsData = {
        ...eventData,
        mod: mod,
        source: this.getSource(),
      }

      if (process.env.NODE_ENV !== 'production' || localStorage.getItem("debugMode")) {
        console.log(`%c${eventType}`, "color: white; background: #448aff; padding: 2px", statisticsData);
      }

      eventProperties = this.filterParams(statisticsData);
      return Promise.all([
        trackingApi.tracking(
          eventType,
          eventData.experimentId,
          eventData.cohortId,
          (eventProperties.source ? eventProperties.source : ''),
          (eventProperties.mod ? eventProperties.mod : '')
        )
      ]);
    }).catch((e)=> console.error(e));
  },
  /**
   *  Возвращает объект c теми полями eventProperties, которые находятся в snake_case
   * и длина которых не более 255 символов
   * @param {Object} eventProperties
   */
  filterParams(eventProperties) {
    let
      i, value,
      result = {};
    for (i in eventProperties) {
      value = String(eventProperties[i]);
      if (value.length < 256) {
        result[i] = value;
      } else {
        throw new Error('eventProperties.' + i + ' must be less 256 symbols');
      }
    }

    return result;
  },

  getSource() {
    return DeviceStorage.source || finesApi.SOURCE_WEB_VERSION;
  },

  sendLoadEvents(silentMode = false) {
    const isSourceUpdated = store.state.session.isSourceUpdated;
    let eventData = {};
    const mod = urlHelper.parseQueryString(location.href).GET.mod || ""
    const source = statService.getSource();

    Promise.all([getAbTestData()]).then(([abData]) => {
      eventData = {
        ...this.userProperties,
        ...eventData,
        ...abData
      };
      if (!silentMode) {
        if (isSourceUpdated) {
          statService.setStatServiceDataParam({ query: {} }, eventData);
          statService.logEvent('[Ситуация] Вошел в воронку', eventData);
        }
        statService.setStatServiceDataParam({ query: {} }, eventData);
        if (process.env.NODE_ENV !== 'production' || localStorage.getItem("debugMode")) {
          console.log(`%c [Ситуация] Приложение загружено`, "color: white; background: #448aff; padding: 2px", {
            ...eventData,
            mod,
            source,
          });
        }

        let eventProperties = statService.filterParams({
          ...eventData,
          mod,
          source,
        });
        trackingApi.tracking(
          "[Ситуация] Приложение загружено",
          eventProperties.experimentId,
          eventProperties.cohortId,
          (eventProperties.source ? eventProperties.source : ''),
          (eventProperties.mod ? eventProperties.mod : '')
        );
      }
    });
  }

}

export default eventsPropsFromFunnel;
