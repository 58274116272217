import {CancelToken} from 'axios';
import api from "@rosfines/vue-common/common/api/osagoApi";
import {statService} from "@/services/statService";
import sleep from "@/util/helpers/sleep";
import { SORT_TYPES, SORT_FUNCS } from '@/util/helpers/sortCalcs';

let calculationsTimer;
let cancelLoadingCalculations = [];
const calculationsLoadingTimeout = 4*60*1000;
const nextRequestDelay = 3000;
let lastProcessStartedTime;

const calculations = {
  namespaced: true,
  state () {
    return {
      loadingCalculations: false,
      calculations: [],
      calculationsMap: {},
      gotFirstCalc: false,
      sortType: SORT_TYPES.STANDART_ASC,
    }
  },
  getters: {
    companies(state) {
      return state.calculations.sort(SORT_FUNCS[state.sortType]);
    },
    companiesRejected(state, getters, rootState, rootGetters) {
      return rootGetters["companies/getAllCompanies"].filter(company => !(`company_${company.id}` in state.calculationsMap));
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loadingCalculations = !!value;
    },
    resetCalculations(state) {
      state.calculations = [];
      state.calculationsMap = {};
      state.gotFirstCalc = false;
    },
    pushCalculations(state, calcs) {
      if (calcs.length !== state.calculations.length) {
        state.calculations = calcs;
        let calcsMap = {};
        calcs.map((calc, index) => {
          calcsMap[`company_${calc.insuranceCompanyId}`] = index;
          if (!state.gotFirstCalc) {
              let eventData = {};
              statService.setStatServiceDataParam({query: {}}, eventData);
              statService.logEvent('[Ситуация] Получен расчет', eventData);
          }
          state.gotFirstCalc = true;
        });
        state.calculationsMap = calcsMap;
      }
      // calcs.map((calc) => {
      //   if (typeof state.calculationsMap[`company_${calc.insuranceCompanyId}`] === "undefined") {
      //     if (calc.label === CALCS_BADGE.MOST_POPUPLAR) {
      //       state.calculations.map(item => {
      //         if (item.label === CALCS_BADGE.MOST_POPUPLAR ) {
      //           item.label = null;
      //         }
      //       });
      //     }
      //     state.calculations.push(calc);
      //     state.calculationsMap[`company_${calc.insuranceCompanyId}`] = state.calculations.length - 1;
      //     if (!state.gotFirstCalc) {
      //       let eventData = {};
      //       statService.setStatServiceDataParam({query: {}}, eventData);
      //       statService.logEvent('[Ситуация] Получен расчет', eventData);
      //     }
      //     state.gotFirstCalc = true;
      //   }
      // });
    },
    setSortType(state, sortType) {
      if (Object.values(SORT_TYPES).includes(sortType)) {
        state.sortType = sortType;
      }
    },
  },
  actions: {
    async getCalculations({dispatch, commit, rootState}) {
      const carId = rootState.form.activeCarId;
      if(carId) {
        lastProcessStartedTime = new Date().getTime();
        let currentProcessStartedTime = new Date().getTime();
        statService.logEvent('[Ситуация] Запуск процесса получения расчетов');
        commit("setLoading", true);

        await dispatch("stopLoadingCalculations", false);
        commit("resetCalculations");
        commit("form/setKbmList", [], {root: true});

        if (!rootState.form.dateIsActual) {
          await dispatch("form/autoUpdateStartDate", undefined, {root: true}).catch((e) => {
            throw e;
          });
        }

        calculationsTimer = setTimeout(() => {
          statService.logEvent('[Ситуация] Остановка получения расчетов по таймауту');
          dispatch("stopLoadingCalculations")
        }, calculationsLoadingTimeout);
        dispatch("productsCalcs/getCalculations", undefined, { root: true });
        while(calculationsTimer && lastProcessStartedTime <= currentProcessStartedTime) {
          await dispatch("_loadCalcs", carId);
          await sleep(nextRequestDelay);
        }
      } else {
        throw new Error("{carId} missed #2");
      }
    },

    async stopLoadingCalculations({state, commit, dispatch}, setLoadingToFalse = true) {
      if(state.calculations.length === 0 && state.loadingCalculations) {
        let eventData = {};
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent('[Ситуация] Не получен расчет', eventData);
      }
      if (setLoadingToFalse) {
        commit("setLoading", false);
      }
      clearTimeout(calculationsTimer);
      calculationsTimer = undefined;
      dispatch('_cancelLoadingCalculations');
      // dispatch("productsCalcs/stopLoadingCalculations", undefined, { root: true });
    },

    async _loadCalcs({state, commit, dispatch, rootState}, carId) {
      if(carId) {
        let queryParams = {};
        queryParams.utmMarketing = rootState.app.utmMarketing;
        const calculations = await api.get(`/partner/car/${carId}/calculations`, queryParams, undefined, {
          cancelToken: new CancelToken(function executor(c) {
            cancelLoadingCalculations.push(c);
          })
        });
        if (api.isSuccess(calculations)) {
          const gotNewCalcs = calculations.data.calculations.length !== state.calculations.length;
          commit("pushCalculations", calculations.data.calculations);
          if(calculations.data.isCompleted) {
            statService.logEvent('[Ситуация] Получен флаг isCompleted в расчетах');
            dispatch("stopLoadingCalculations");
          }
          if (gotNewCalcs) {
            await dispatch("form/updateKbmInfo", undefined, { root: true });
          }
        }
      } else {
        throw "{carId} not passed";
      }
    },

    _cancelLoadingCalculations() {
      cancelLoadingCalculations.forEach((item) => {
        item("due to cancelLoadingCalculations called");
      });
      cancelLoadingCalculations = [];
    },
  }
}

export default calculations;
