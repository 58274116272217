<template>
  <main class="component__choose_car">
    <page-header
      title="Авто / Мото"
      :hide-v-p="true"
    >
      <template #right>
        <span class="toggle-mode-enabler d-lg-none">
          <span
            v-if="toggleModeEnabled"
            class="car-toggler"
            @click="disableCarToggle()"
          >
            Отменить
          </span>
          <span
            v-else
            class="car-toggler"
            @click="enableCarToggle()"
          >
            Выбрать
          </span>
        </span>
      </template>
    </page-header>

    <section class="b_cars">
      <div
        v-if="cars && cars.length"
        class="container container_adaptive"
      >
        <div class="b_cars_list clearfix row">
          <div
            v-for="(car, index) in cars"
            :key="index"
            class="col-12 col-lg-4"
          >

            <div class="b_car_card_wrapper">
              <div
                v-if="car.status === 1"
                class="delete-wrapper"
                :class="{
                  'd-block': toggleModeEnabled,
                }"
              >
                <i
                  class="ico-delete d-none d-lg-block"
                  title="Удалить автомобиль"
                  @click="removeCarWithConfirm(car)"
                />
                <app-checkbox
                  v-show="toggleModeEnabled"
                  :id="`deleteMarks-${car.id}`"
                  v-model="deleteMarks[car.id]"
                  class="bg-white"
                />
              </div>

              <car-card
                :car="car"
                @click="onClickCar(car)"
                @change-vehicle-plate="changeVehiclePlate"
              />

            </div>
          </div>
        </div>
        <section
          v-if="isAddCar"
          class="col-lg-4 pl-lg-0"
        >
          <div class="container container_adaptive add-car">
            <VeeForm
              ref="validationObserver"
              v-slot="{handleSubmit}"
            >
              <form
                as="form"
                @submit="handleSubmit($event, onClickPlateNext)"
              >

                <data-steps v-if="isLegalEntityEnabled" title="Тип транспортного средства" step="1" />
                <TabGroup>
                  <TabList
                    as="div"
                    class="vehicle-type_tab-list"
                  >
                    <Tab
                      v-slot="{ selected }"
                      as="template"
                    >
                      <button
                        :class="{
                          'vehicle-type_tab_selected': selected,
                          'vehicle-type_tab vehicle-type_tab_mobile': true
                        }"
                        @click="changeCategory('')"
                      >
                        Автомобиль
                      </button>
                    </Tab>
                    <Tab
                      v-slot="{ selected }"
                      as="template"
                    >
                      <button
                        :class="{
                          'vehicle-type_tab_selected': selected,
                          'vehicle-type_tab vehicle-type_tab_mobile': true
                        }"
                        @click="changeCategory('A')"
                      >
                        Мотоцикл
                      </button>
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <AppInput
                        id="vehiclePlate"
                        ref="vehiclePlate"
                        v-model="vehiclePlate"
                        label="Госномер"
                        rules="required|vehiclePlateLength|vehiclePlate"
                        mask="vehiclePlate"
                        uppercase="true"
                      />

                      <div class="inform_text_notice">
                        <div class="el__content">
                          <p class="el_text">
                            Например, А000АА00
                          </p>
                        </div>
                        <div class="d-block pb-3" />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <AppInput
                        id="vehiclePlateMoto"
                        ref="vehiclePlateMoto"
                        v-model="vehiclePlate"
                        label="Госномер"
                        rules="required|vehiclePlateLength|vehiclePlateMoto"
                        mask="vehiclePlateMoto"
                        uppercase="true"
                      />

                      <div class="inform_text_notice">
                        <div class="el__content">
                          <p class="el_text">
                            Например, 0000АА00
                          </p>
                        </div>
                        <div class="d-block pb-3" />
                      </div>
                    </TabPanel>
                  </TabPanels>
                </TabGroup>

                <data-steps v-if="isLegalEntityEnabled" title="Кто страхователь?" step="2" />

                <TabGroup
                  v-if="isLegalEntityEnabled"
                  :selected-index="selectedPersonTypeIndex"
                  @change="setPersonType"
                >
                  <TabList
                    as="div"
                    class="vehicle-type_tab-list"
                  >
                    <Tab
                      v-slot="{ selected }"
                      as="template"
                    >
                      <button
                        :class="{
                          'vehicle-type_tab_selected': selected,
                          'vehicle-type_tab vehicle-type_tab_mobile': true
                        }"
                      >
                        Физ.лицо
                      </button>
                    </Tab>
                    <Tab
                      v-slot="{ selected }"
                      as="template"
                      :disabled="category === 'A'"
                    >
                      <button
                        :class="{
                          'vehicle-type_tab_selected': selected,
                          'vehicle-type_tab vehicle-type_tab_mobile': true,
                          'vehicle-type_tab vehicle-type_tab_mobile vehicle-type_tab_disabled': category === 'A'
                        }"
                      >
                        Юр.лицо
                      </button>
                    </Tab>
                  </TabList>
                </TabGroup>

                <button
                  class="c_btn_full btn-primary_new font-17 font-weight-semi"
                  type="submit"
                  :disabled="loading"
                >
                  Добавить
                </button>

                <div class="mt-3">
                  <button
                    class="c_btn_full btn-secondary font-17 font-weight-semi"
                    type="button"
                    :disabled="loading"
                    @click="showNoPlate"
                  >
                    Нет номера
                  </button>
                </div>
              </form>
            </VeeForm>
          </div>
        </section>
        <div
          v-else
          class="b_buttons col-lg-4 pl-lg-0"
        >
          <button
            class="c_btn_full b_gray btn-plus el_btn_add_car"
            @click="onClickAdd()"
          >
            Добавить ТС
          </button>
        </div>
      </div>
    </section>

    <div
      v-if="hasMarkedToDelete"
      class="xs-delete-btn"
    >
      <div class="fly-btn">
        <button
          class="c_btn_full m_white_gray"
          @click="batchRemove()"
        >
          <i
            class="ico-delete-dark"
          />
          Удалить
        </button>
      </div>
    </div>

    <div class="recent-policies-wrapper">
      <funnel-list />
    </div>

    <faq-component page-name="carData" />

    <loading
      v-if="fullScreenLoading"
      :active="true"
    />
  </main>
  <app-modal
    id="modal-remove-car"
    :title="hasMarkedToDelete > 1 ? 'Удаление автомобилей' : 'Удаление автомобиля'"
  >
    <template #modalContent>
      <div class="popup-content-block">
        Больше не будем присылать уведомления об окончании ОСАГО
      </div>
      <div>
        <v-button
          @click="confirmRemoving"
        >
          Удалить
        </v-button>
      </div>
    </template>
  </app-modal>

  <app-modal
    id="modal-remove"
    title="Удалить автомобиль?"
    bottom-button-class="btn-warn"
    bottom-button-title="Удалить"
    @close="rejectRemoving"
    @clickButton="confirmRemoving"
  >
    <p>
      Введён некорректный госномер.<br>
      Мы не сможем оформить ОСАГО на этот автомобиль.
    </p>
  </app-modal>

  <app-modal
    id="modal-vin"
    title="Оформление без номера"
  >
    <template #fullCustom>
      <VeeForm
        ref="byVinValidationObserver"
        v-slot="{handleSubmit}"
        class="popup-content-block content-slot"
      >
        <form
          id="noVinForm"
          @submit="handleSubmit($event, onClickVinNext)"
        >
          <div>
            <p>
              Если у вашего автомобиля нет номера, или вы планируете его сменить — продолжайте оформление без номера
            </p>
            <info-block
              no-margin
              title="Подойдет для учета"
              desc="В ГИБДД можно поставить авто на учёт с распечатанным полисом даже без вписанного номера. Номер можно будет вписать в полис после постановки на учет, либо при следующем продлении полиса ОСАГО"
            />
            <div class="container px-0">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <vin-input
                    screen="Добавление автомобиля"
                    :car-data="carData"
                    :show-pills="false"
                    no-gaps
                    @change-vin="onVinChange"
                    @change-body-number="onBodyNumChange"
                    @on-change-no-vin="onChangeNoVin"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </VeeForm>
      <div class="popup-control-block">
        <button
          type="submit"
          class="c_btn_full btn-primary_new font-17 font-weight-semi"
          form="noVinForm"
        >
          Продолжить без номера
        </button>
        <button
          type="button"
          class="c_btn_full btn-white_new font-17 font-weight-semi"
          @click="closeVinModal"
        >
          Ввести номер
        </button>
      </div>
    </template>
  </app-modal>

  <app-modal
    id="modal-question-vin"
    title="VIN номер"
  >
    <p>
      Находится в верхней части свидетельства&nbsp;о&nbsp;регистрации<br>
      Ввод&nbsp;доступен <span class="font-weight-bold">только латинскими буквами и&nbsp;цифрами</span>
    </p>
    <img
      alt="Свидетельство о регистрации транспортного средства, VIN номер"
      class="img-fluid mx-auto d-block"
      src="@/assets/img/vin.png"
    >
  </app-modal>

  <app-modal
    id="modal-question-body-number"
    title="Номер кузова"
  >
    <p>
      Вводите номер кузова только,  если у вас нет VIN-номера.<br>
      Ввод&nbsp;доступен только <span class="font-weight-bold">латинскими буквами и&nbsp;цифрами</span>
    </p>
    <img
      alt="Номер кузова"
      class="img-fluid mx-auto d-block"
      src="@/assets/img/body-number.png"
    >
  </app-modal>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { Form as VeeForm } from "vee-validate";

import {statService} from "@/services/statService";
import statEventsHelper from "@/util/helpers/statEvents.helper";
import swalHelper from "@/util/helpers/swal.helper";
import PageHeader from "@/components/layout/PageHeader";
import {mapState, mapGetters, mapActions} from "vuex";
import FunnelList from '@/components/blocks/RecentPolicies/FunnelList';
import VinInput from "@/components/fields/VinInput.vue";
import InfoBlock from "@/components/blocks/misc/InfoBlock.vue";
import FaqComponent from "@/components/faq/FaqComponent.vue";
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from "@headlessui/vue";
import DataSteps from "@/components/blocks/misc/DataSteps.vue";
import {BUSINESS_TYPES_SELECTOR, PERSON_TYPES} from "@/constants/personTypes";
import CarCard from '@/components/blocks/CarCard';

export default {
  name: 'ChooseCar',
  components: {
    CarCard,
    DataSteps,
    TabPanel,
    TabPanels,
    Tab,
    TabList,
    TabGroup,
    FaqComponent,
    InfoBlock,
    VinInput,
    FunnelList,
    Loading,
    PageHeader,
    VeeForm,
  },
  props: {},

  data() {
    return {
      loading: false,
      vehiclePlate: '',
      category: '',
      isAddCar: false,
      toggleModeEnabled: false,
      deleteMarks: {},

      isLeftHandDrive: true,

      resolveConfirmation: false,
      rejectConfirmation: false,

      personType: PERSON_TYPES.INDIVIDUAL,
      selectedPersonTypeIndex: 0,

      carData: {
        vin: '',
        noVin: false,
        bodyNumber: '',
      },
    };
  },

  computed: {
    PERSON_TYPES() {
      return PERSON_TYPES
    },
    ...mapState({
      carsListLoaded: state => state.cars.carsListLoaded,
    }),
    ...mapGetters({
      cars: "cars/getCarsList",
      getCarStatus: "cars/getCarStatus",
      isLegalEntityEnabled: "GBFeatures/isLegalEntityEnabled",
    }),
    fullScreenLoading() {
      return !this.carsListLoaded || this.loading;
    },
    hasMarkedToDelete() {
      return Object.values(this.deleteMarks).filter((i) => (i)).length;
    },
  },
  watch: {
    isAddCar(val){
      if (val) {
        this.$nextTick(() => {
          window.scrollTo(0, document.body.clientHeight);
          this.$refs.vehiclePlate.focus();
        });
      }
    },
    category() {
      this.$nextTick(() => {
        this.vehiclePlate = null;
      })
    },
    carsListLoaded(val) {
      if (val) {
        this.checkIfHasCars();
        this.fillDeleteMarks()
        if (this.cars.length > 0) {
          this.loadRecentPolicies({
            carId: this.cars[0].id,
            filter: 'byCar',
          });
        }
      }
    }
  },
  mounted() {
    statEventsHelper.sendEventFromSmsOrPush("Выбор автомобиля");
    let eventData = {};
    statService.setStatServiceDataParam(this.$route, eventData);
    statService.logEvent('[Экран] Выбор автомобиля', eventData);
    this.checkIfHasCars();
    window.addEventListener("resize", this.onWindowResize);

  },
  unmounted() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    ...mapActions({
      addCar: "cars/addCar",
      chooseCar: "form/chooseCar",
      deleteCar: "cars/deleteCar",
      reloadCarsInfo: "cars/loadCarsInfo",
      loadRecentPolicies: "recentPolicies/loadRecentPolicies",
      addVehiclePlate: "cars/addVehiclePlate",
      setEditableCarId: "form/setEditableCarId",
      setEditableVehicleType: "form/setEditableVehicleType",
    }),

    changeCategory(category) {
      this.category = category;
      this.setPersonType(0);
    },

    onVinChange(value) {
      this.carData.vin = value;
    },
    onBodyNumChange(value) {
      this.carData.bodyNumber = value;
    },
    closeVinModal() {
      this.$hideModal("modal-vin");
      this.$refs.vehiclePlate.focus();
    },
    changeVehiclePlate(carId, vehicleType) {
      this.setEditableCarId(carId);
      this.setEditableVehicleType(vehicleType);
      this.$showModal("modal-add-vehicle-plate");
    },

    async onClickCar(car) {
      if (this.toggleModeEnabled) {
        this.deleteMarks[car.id] = !this.deleteMarks[car.id];
      } else {
        const carStatus = this.getCarStatus(car.id);
        switch (carStatus) {
          case 2:
            await this.removeCarWithConfirm(car);
            break;
          default:
            await this.onChooseCar(car);
        }
      }
    },

    async removeCarWithConfirm(car) {
      const confirmation = new Promise((resolve, reject) => {
        this.resolveConfirmation = resolve;
        this.rejectConfirmation = reject;
      });
      if(car.status === 2) {
        this.$showModal("modal-remove")
      } else {
        this.$showModal("modal-remove-car");
      }
      await confirmation.then(async () => {
        this.loading = true;
        let eventData = {};
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent('[Действие] Подтвердил удаление автомобиля', eventData);
        await this.deleteCar({carId: car.id}).catch((error) => {
          swalHelper.showResponseError(error);
        });
        await this.reloadCarsInfo({loadActiveCar: false});
        if (this.cars.length === 0) {
          await this.$router.push({name: "startNumber"})
        }
        this.$hideModal("modal-remove");
        this.$hideModal("modal-remove-car");
        this.loading = false;
      }).catch(() => {});
    },

    async batchRemove() {
      const confirmation = new Promise((resolve, reject) => {
        this.resolveConfirmation = resolve;
        this.rejectConfirmation = reject;
      });
      this.$showModal("modal-remove-car");
      await confirmation.then(async () => {
        this.loading = true;
        let eventData = {};
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent('[Действие] Подтвердил удаление автомобиля', eventData);
        for (const i in this.deleteMarks) {
          if (this.deleteMarks[i]) {
            await this.deleteCar({ carId: i }).catch((error) => {
              swalHelper.showResponseError(error);
            });
          }
        }
        await this.reloadCarsInfo({loadActiveCar: false});
        this.fillDeleteMarks();
        if (this.cars.length === 0) {
          await this.$router.push({name: "startNumber"})
        } else {
          this.disableCarToggle();
        }
        this.loading = false;
        this.$hideModal("modal-remove");
        this.$hideModal("modal-remove-car");
      }).catch(() => {});
    },

    confirmRemoving() {
      if (this.resolveConfirmation) {
        this.resolveConfirmation();
      }
    },

    rejectRemoving() {
      if (this.resolveConfirmation) {
        this.rejectConfirmation();
      }
    },

    async onChooseCar(car) {
      this.loading = true;

      let eventData = {};
      statService.setStatServiceDataParam({query: {}}, eventData);
      statService.logEvent('[Действие] Выбрал авто', eventData);

      await this.chooseCar(car.id);
      await this.$router.push({name: 'formContinue'});
    },

    onClickAdd() {
      let eventData = {};
      statService.setStatServiceDataParam({query: {}}, eventData);
      statService.logEvent('[Действие] Нажал на добавление нового авто', eventData);

      this.isAddCar = true;
    },

    onClickVinNext() {
      this.onClickNext(true);
    },
    onClickPlateNext() {
      this.onClickNext(false);
    },

    async onClickNext(isVin) {
      let validate = null;

      this.loading = true;
      let eventData = {};
      statService.setStatServiceDataParam({query: {}}, eventData);
      if (isVin) {
        this.$hideModal("modal-vin");
        validate = await this.$refs.byVinValidationObserver.validate();
        statService.logEvent('[Действие] Нажал на далее, после ввода VIN в модалке', eventData);
      } else {
        validate = await this.$refs.validationObserver.validate();
        statService.logEvent('[Действие] Нажал на далее, после ввода нового номера', eventData);
      }

      if(validate && validate.valid) {
        const res = await this.addCar({
          restore: true,
          vehiclePlate: isVin ? null : String(this.vehiclePlate).toUpperCase(),
          category: this.category,
          vin: isVin ? this.carData.vin.toUpperCase() : null,
          isLeftHandDrive: this.isLeftHandDrive,
          personType: this.personType
        }).catch((e) => {
          swalHelper.showResponseError(e);
          if (e && e.data && e.data.fields && e.data.fields.vehiclePlate && e.data.fields.vehiclePlate[0]) {
            this.$refs.vehiclePlate.setError(e.data.fields.vehiclePlate[0]);
          }
        });
        if (res) {
          await this.$router.push({name: 'formContinue'});
        }
      }
      this.loading = false;
    },

    checkIfHasCars() {
      if (this.carsListLoaded) {
        if (this.cars.length === 0) {
          this.$router.push({ name: 'startNumber' });
        }
      }
    },
    disableCarToggle() {
      this.toggleModeEnabled = false;
    },
    enableCarToggle() {
      this.fillDeleteMarks();
      this.toggleModeEnabled = true;
    },
    fillDeleteMarks() {
      this.deleteMarks = {};
      for (let i in this.cars) {
        this.deleteMarks[this.cars[i].id] = false;
      }
    },
    onWindowResize() {
      if (window.innerWidth >= 992 && this.toggleModeEnabled) {
        this.toggleModeEnabled = false;
      }
    },
    showNoPlate() {
      this.$showModal("modal-vin")
    },
    onChangeNoVin(value) {
      this.carData.noVin = value;
    },
    setPersonType(idx) {
      this.personType = BUSINESS_TYPES_SELECTOR[idx];
      this.selectedPersonTypeIndex = idx;
      statService.logEvent(`[Действие] Выбрал тип анкеты "${this.personType === PERSON_TYPES.INDIVIDUAL ? "Физ. Лицо" : "Юр. Лицо"} на странице выбора авто"`, {});
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.recent-policies-wrapper {
  margin: 24px 0 0;
  background: #FFF;
  padding: 16px 8px;
  border-radius: 16px;
  @include view-lg {
    margin: 40px 0 0;
    background: none;
    padding: 0;
  }
}

.popup-control-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 8px;
  margin: 0 0-16px 0-16px;
  padding: 16px;
  border-radius: 16px 16px 0 0;
  background: $color-white;
  box-shadow: 0 0 16px 0 rgba(32, 39, 61, 0.15);
  @include view-lg {
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    flex-wrap: nowrap;
  }
}

.inform_text_notice {
  margin: -12px 0 -2px;
  @include view-lg {
    margin: -12px 0 -2px;
  }
  p {
    margin-bottom: 0;
  }
}

</style>
