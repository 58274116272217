import api from "@rosfines/vue-common/common/api/osagoApi";

export default {
    tracking(eventId, experimentId, cohortId, source, mod) {
      let data = { eventId, source, mod };
      if (cohortId) {
        data.cohortId = cohortId;
      }
      if (experimentId) {
        data.experimentId = experimentId;
      }

      sendToYaMetrika(eventId, data);

      return api.post(`/analytics/track`, data);
    },
}

function sendToYaMetrika (eventId, eventProperties) {
  if (process.env.NODE_ENV === 'production') {
    setTimeout(() => {
      window.ym(56687083, 'reachGoal', eventId, eventProperties);
    }, 4000);
  }
}
