import moment from "moment";
import {numberFormat} from "@/util/common.helper";
import {FRONT_DATE_FORMAT, BACK_DATE_FORMAT} from '@/constants/dateFormat';

export default {
  lower: (s) => {
    if (typeof s !== 'string'){
      return ''
    }
    return s.toLowerCase();
  },
  zeroNumber: (number) => {
    if (String(number).length === 1) {
      return "0" + number;
    } else {
      return number;
    }
  },
  round: (s) => {
    return Math.floor(s);
  },
  pluralize: (count, words) => {
    let cases = [2, 0, 1, 1, 1, 2];
    let mod = count % 100;
    return words[(mod > 4 && mod < 20) ? 2 : cases[Math.min(count % 10, 5)]];
  },
  formatDate: (date) => {
    const frontFormat = moment(date, FRONT_DATE_FORMAT);
    if (frontFormat.isValid()) {
      return moment(date, FRONT_DATE_FORMAT).format(FRONT_DATE_FORMAT)
    }
    return moment(date, BACK_DATE_FORMAT).format(FRONT_DATE_FORMAT);
  },
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  price(price) {
    return numberFormat(Math.ceil(price)) + " ₽"
  },
  addInsSubs(value, {
    policyStartDate,
  }) {
    return value?.replace("{date}", moment(policyStartDate, FRONT_DATE_FORMAT).format(FRONT_DATE_FORMAT)) || '';
  },
  filterPlate: (vehiclePlate) => {
    return vehiclePlate || 'Без номера';
  },
  formatLicenceNumber: (licenceNumber) => {
    if (licenceNumber) {
      return licenceNumber.replace(/^(.{4})/, "$1 ");
    }
    return licenceNumber;
  }
}
