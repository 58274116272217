export const SORT_TYPES = {
  PRICE_ASC: "priceAsc",
  STANDART_ASC: "standartAsc",
};

export const SORT_FUNCS = {
  standartAsc: (a,b) => {
    if (a.priority && b.priority) {
      return SORT_FUNCS.prioityAsc(a,b);
    }
    return SORT_FUNCS.priceAsc(a,b);
  },
  prioityAsc: (a,b) => {
    if (a.priority < b.priority) {
      return -1;
    } else if (a.priority > b.priority) {
      return 1;
    } else {
      return 0;
    }
  },
  priceAsc: (a, b) => {
    const priceA = parseInt(a.price, 10);
    const priceB = parseInt(b.price, 10);
    if (priceA < priceB) {
      return -1;
    } else if (priceA > priceB) {
      return 1;
    } else {
      return 0
    }
  }
}
