/**
 * IMask and vue-imask based masks
 * https://imask.js.org/guide.html
 */

import vehiclePlate from "./masks/MaskVehiclePlate";
import vehiclePlateMoto from "./masks/MaskVehiclePlateMoto";
import vehiclePlateAny from "./masks/MaskVehiclePlateAny";
import VPNumberMoto from "./masks/MaskVPNumberMoto";
import VPNumber from "./masks/MaskVPNumber";
import VPRegion from "./masks/MaskVPRegion";
import phone from "./masks/MaskPhone";
import date from "./masks/MaskDate";
import dateNext from "./masks/MaskDateNext";
import year from "./masks/MaskYear";
import stsNumber from "./masks/MaskStsNumber";
import eptsNumber from "./masks/MaskEptsNumber";
import vinNumber from "./masks/MaskVinNumber";
import bodyNumber from "./masks/MaskBodyNumber";
import passport from "./masks/MaskPassport";
import power from "./masks/MaskPower";
import policyNumber from "./masks/MaskPolicyNumber";
import name from "./masks/MaskName";
import email from "./masks/MaskEmail";
import ogrn from "./masks/MaskOgrnNumber";
import inn from "./masks/MaskInnNumber";
import kpp from "./masks/MaskKppNumber";
import certificate from "./masks/MaskCertificateNumber";
import egrul from "./masks/MaskEgrulNumber";

export default {
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      maskedValue: {
        value: null,
        unmaskedValue: null,
      },
      OMasks: {
        vehiclePlate,
        vehiclePlateMoto,
        vehiclePlateAny,
        VPNumber,
        VPNumberMoto,
        VPRegion,
        phone,
        date,
        dateNext,
        year,
        stsNumber,
        eptsNumber,
        vinNumber,
        bodyNumber,
        passport,
        power,
        policyNumber,
        name,
        email,
        ogrn,
        inn,
        kpp,
        certificate,
        egrul,
      }
    }
  },
  methods: {
    onAccept (e) {
      this.onChangeInput(e);
    },
    onComplete (e) {
      this.onChangeInput(e);
      this.$emit('update:modelValue', this.maskedValue.normalizedValue || this.maskedValue.unmaskedValue);
    },
    onChangeInput(e) {
      const maskRef = e.detail;
      this.maskedValue = {
        value: maskRef.value,
        unmaskedValue: maskRef.unmaskedValue,
      }
      if (this.OMasks[this.mask].formatOut) {
        this.maskedValue.normalizedValue = this.OMasks[this.mask].formatOut(maskRef) || undefined;
      }
    },
    maskBlur() {
      if (this.mask) {
        this.$emit('update:modelValue', this.maskedValue.normalizedValue || this.maskedValue.unmaskedValue);
      }
    }
  },
  computed: {
    formattedValue() {
      if(this.mask && this.OMasks[this.mask].formatIn) {
        return this.OMasks[this.mask].formatIn(this.modelValue);
      } else {
        return this.modelValue;
      }
    }
  }
}
