import api from '@rosfines/vue-common/common/api/osagoApi';

const recentPolicies = {
  namespaced: true,
  state () {
    return {
      list: [{}],
      loading: true,
    }
  },
  mutations: {
    setList(state, list) {
      if (list.length) {
        state.list = list;
      } else {
        state.list = [{}];
      }
    },
    setLoading(state, status) {
      const val = !!status;
      state.loading = val;
      if(val){
        state.list = [
          {},{},{},{},{},{},{},{},{},{},{},{}
        ];
      }
    },
  },
  actions: {
    async loadRecentPolicies({commit, rootState},
      {
        filter,
        carId,
        markId,
        modelId,
        countDrivers,
        address,
      } = {}) {
      commit("setLoading", true);
      let data = {};
      if (filter) {
        data[filter] = 1
      }
      const activeCarId = carId === false ? undefined : (carId || rootState.form.activeCarId);
      data.carId = parseInt(activeCarId);
      data.carId = isNaN(data.carId) ? 0 : data.carId;
      markId ? (data.markId = markId) : 0;
      modelId ? (data.modelId = modelId) : 0;
      countDrivers ? (data.countDrivers = countDrivers) : 0;
      address ? (data.address = address) : '';

      data = Object.keys(data).map((key) => {return encodeURIComponent(key) + "=" + encodeURIComponent(data[key])}).join("&");

      const response = await api.get(`/latest-bought-policy/list${data ? "?"+data : ""}`).catch((e) => {
        throw new Error(e);
      });
      if (api.isSuccess(response)) {
        commit("setList", response.data);
        commit("setLoading", false);
        //если не удалось загрузить - вечный лоадер в блоке
      }
    },
  },
}
export default recentPolicies;

