<template>
  <section
    :class="wrapperClass"
    :data-field="id"
  >
    <Field
      ref="validationField"
      v-slot="{errorMessage}"
      :name="id"
      :rules="rules"
      :value="formattedValue || modelValue"
    >
      <div
        :class="{'fly-label': flyLabel}"
        :style="{height:inputBackgroundHeight, marginBottom: noMargin ? 0 : null}"
      >
        <input
          :id="id"
          ref="input"
          v-imask="OMasks[mask]"
          :type="type"
          :name="name || id"
          :placeholder="placeholder"
          :maxlength="maxlength"
          :readonly="readonly || hiddenReadonly"
          :disabled="disabled"
          :uppercase="uppercase"
          autocomplete="off"
          :aria-label="label"
          :class="Object.assign(classes,{
            'textfield-shifted': inputShifted && inputShiftEnabled,
            'textfield-hidden': !inputShifted && inputShiftEnabled,
            'is-modal': modal,
            'hidden-readonly' : hiddenReadonly,
            failed: !!errorMessage || !!markError,
          })"
          class="form-control form-control-lg"
          @blur="handleBlur"
          @focus="onFocus"
          @change="onChange"
          @input="handleInput"
          @accept="onAccept"
          @complete="onComplete"
          @paste="onPaste"
        >
        <div
          v-if="isShowDatePicker"
          class="datepicker-ico"
          :class="{
            'datepicker-ico_with-modal' : modal
          }"
          @click="openDatePicker"
        >
          <VueDatePicker
            v-model="pickerValue"
            ref="datepicker"
            :enable-time-picker="false"
            locale="ru"
            select-text="Выбрать"
            cancel-text="Отмена"
            format="dd.MM.yyyy"
            model-type="format"
            :clearable="false"
            :position="datepickerProps.position"
            :year-range="datepickerProps.yearRange"
            :flow="datepickerProps.flow"
            :max-date="datepickerProps.maxDate"
            :min-date="datepickerProps.minDate"
            hide-input-icon
            :month-change-on-scroll="false"
            :text-input-options="{
              format: 'dd.MM.yyyy'
            }"
            @open="onDatepickerOpen"
            @update:model-value="datepickerProps.onUpdateModelValue"
            @closed="datepickerProps.closed"
            :on-click-outside="datepickerProps.onClickOutside"
          />
        </div>
        <a
          v-if="modal"
          class="link-input-icon"
          href="javascript:;"
          @click="$showModal(modal)"
        >
          <i class="svgimg svg-help" />
        </a>

        <label
          v-if="label"
          ref="label"
          :for="id"
          class="required placeholder-label"
          :class="{
            'placeholder-shifted': inputShifted
          }"
        >
          {{ label }}
        </label>
      </div>
      <div
        v-if="serverValidation && !wasFocused"
        class="invalid-feedback--vue js_invalid_feedback"
      >
        {{ serverValidation && serverValidation[0] || serverValidation }}
      </div>
      <div
        v-else-if="markError"
        class="invalid-feedback--vue js_invalid_feedback"
      >
        {{ markError }}
      </div>
      <ErrorMessage
        v-if="!hideErrors"
        as="div"
        :name="id"
        class="invalid-feedback--vue js_invalid_feedback"
      />
    </Field>
  </section>
</template>

<script>
import { Field, ErrorMessage, useField } from "vee-validate";
import appField from "@/mixins/appField";
import masksMixin from "@/mixins/masks";
import {statService} from "@/services/statService";
import {IMaskDirective} from "vue-imask";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {
  directives: {
    imask: IMaskDirective
  },
  components: {
    Field,
    ErrorMessage,
    VueDatePicker,
  },
  mixins: [
    appField,
    masksMixin,
  ],
  props: {
    classes: {
      type: Object,
      default: () => {return {}},
    },
    hideErrors: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    modelValueFrom: {
      type: String,
      default: "",
    },
    /** @property {String} max_length Этот атрибут можно использовать вместо стандартного maxlength, потому что использование стандартного на данный момент блокирует ввод (N знаков _ в инпуте) */
    maxLength: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
    flyLabel: {
      type: Boolean,
      default: true,
    },
    inputShiftEnabled: {
      type: Boolean,
      default: true,
    },
    serverValidation: {
      type: Array,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    hiddenReadonly: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    datepickerProps: {
      type: Object,
      default: () => ({
        flow:['year', 'month', 'calendar'],
        yearRange: [1950, new Date().getFullYear()],
        maxDate: new Date(),
        minDate: null,
        onUpdateModelValue: () => {},
        position: "center",
        openOnFocus: false,
        showOpened: false,
        onClickOutside: null,
      }),
    },
    showDatepicker: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'paste',
    'change',
    'focus',
    'input',
    'update:modelValue'
  ],
  data() {
    return {
      isFocused: false,
      wasFocused: false,
      errorMessage: null,
      markError: false,

      /** @property {String} invalidSelector css класс, который применяется для придания полю вида "Заполнено с ошибкой". */
      invalidSelector: 'is-invalid--vue',

      /** @property {String} tex высота фона input (он прозрачный и с абсолютным позиционированием, поэтому ресайзим динамически) */
      inputBackgroundHeight: 'auto',

      labelSelectors: 'required placeholder-label',

      pickerValue: null,
    }
  },
  computed: {
    inputShifted() {
      return (!this.readonly && this.isFocused) || this.modelValue || this.placeholder;
    },
    isShowDatePicker() {
      return this.showDatepicker || this.mask === "date";
    }
  },
  watch: {
    errorMessage(newVal) {
      if (newVal) {
        let eventData = {
          fieldValue: this.$refs.input.value,
        };
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent(`[Ситуация] Показана ошибка валидации. Поле [${this.id}]`, eventData);
      }
    },
    pickerValue(newVal) {
      this.$refs.input.value = newVal;
      this.$emit('update:modelValue', newVal);
    },
    modelValue(val) {
      this.$refs.input.value = val;
      this.inputValue = val;
    },
  },

  mounted() {
    if (!this.mask || !this.OMasks[this.mask] && !this.OMasks[this.mask].formatIn) {
      this.$refs.input.value = this.modelValue || "";
    } else {
      if (this.OMasks[this.mask].formatIn) {
        this.$refs.input.value = this.OMasks[this.mask].formatIn(this.modelValue) || "";
      } else {
        this.$refs.input.value = this.modelValue || "";
      }
    }
    this.errorMessage = useField(this.id).errorMessage;
    this.$nextTick(() => {
      if (this.isShowDatePicker && this.datepickerProps.showOpened) {
        this.openDatePicker();
      }
    })
  },

  methods: {
    changeModelValue(val) {
      this.$emit('update:modelValue', this.modelValueFrom && this.maskedValue[this.modelValueFrom] || val);
    },
    getValue(event) {
      const value = event.target.value;
      return this.keyToLang(value);
    },

    onFocus() {
      if (this.datepickerProps.openOnFocus && this.isShowDatePicker) {
        this.openDatePicker();
      }
      this.clearError();
      if (this.label) {
        let eventData = {};
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent(`[Действие] Кликнул в input ${this.label}`, eventData);
      }
      this.isFocused = true;
      this.wasFocused = true;
      this.markError = false;
      if (this.mask === 'date' && this.maskedValue.value === null) {
        this.$refs.input.setSelectionRange(0, 0);
      }
      this.$emit('focus');
    },

    clearError() {
      window.$(this.$el).find('.js_invalid_feedback').css('display', 'none');
      window.$(this.$el).find('.js_invalid_feedback').css('visibility', 'hidden');
    },

    handleBlur(e) {
      this.maskBlur(e);
      if (window.$(this.$el).find('.js_invalid_feedback').first()[0]) {
        this.isErrorField = true;
        window.$(this.$el).find('.js_invalid_feedback').first()[0].style.display = null;
        window.$(this.$el).find('.js_invalid_feedback').first()[0].style.visibility = null;
        this.isErrorField = true;
      } else {
        this.isErrorField = false;
      }
      if (this.label) {
        let eventData = {};
        statService.setStatServiceDataParam({query: {}}, eventData);
        statService.logEvent(`[Действие] Ушел с input ${this.label}`, eventData);
      }
      this.isFocused = false;
    },

    handleInput(event) {
      const value = this.getValue(event);
      if (typeof value !== 'undefined') {
        this.inputValue = value;
        if (!this.mask) {
          this.$emit('update:modelValue', this.keyToLang(value));
        }
        this.$emit('input', this.keyToLang(value));
      }
      this.clearError();
    },

    onChange(event) {
      if (this.$refs && this.$refs.validationField && this.$refs.validationField.handleChange) {
        this.$refs.validationField.handleChange(event);
        const value = this.getValue(event);
        this.$emit('change', this.modelValueFrom && this.maskedValue[this.modelValueFrom] || value);
        this.changeModelValue(value);
      }
    },

    focus(setCursorToLastSymbol = false) {
      this.$refs.input.focus();
      if (setCursorToLastSymbol) {
        const valLength = this.$refs.input.value.length;
        this.$refs.input.setSelectionRange(valLength, valLength);
        const e = document.createEvent("HTMLEvents");
        e.initEvent("change", false, true);
        this.$refs.input.dispatchEvent(e);
      }
    },
    onPaste($event) {
      this.$emit("paste", $event);
    },
    setValue(val, setFocus = true) {
      if (setFocus) {
        this.$refs.input.focus();
      }
      this.$refs.input.value = val;
      if (setFocus) {
        const valLength = this.$refs.input.value.length;
        this.$refs.input.setSelectionRange(valLength, valLength);
        const e = document.createEvent("HTMLEvents");
        e.initEvent("change", false, true);
        this.$refs.input.dispatchEvent(e);
      }
    },
    setError(text) {
      this.markError = text;
    },
    openDatePicker() {
      this.$refs.datepicker.openMenu();
    },
    onDatepickerOpen() {
      if (this.$refs.input.value.includes('__.__.____')) {
        this.pickerValue = null;
        return
      }
      // при ручном вводе даты и последующим открытии календаря - выставляется корректная дата внутрь компонента
      this.pickerValue = this.$refs.input.value;
    }
  }, //end methods
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins";
@import "@/assets/styles/_vars";

.input_error {
  border: 1px solid $color-red;
}

.input_success {
  border: 1px solid $color-green;
}

.placeholder-label {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.textfield-hidden {
  color: transparent;
}

.transparent-inputtext input[type=text] {
  background-color: rgba(255, 255, 255, 0) !important;
  position: absolute !important;
  z-index: 5 !important;
  top: 0;
  left: 0;
  resize: none;
}

.transparent-inputtext {
  position: relative;
  border-radius: 0.75rem;
  background-color: #f2f4f6;
}
.datepicker-ico {
  width: 24px;
  height: 24px;
  background: url(@/assets/img/icons/datepicker.svg) center center no-repeat;
  position: absolute;
  top: 13px;
  right: 12px;
  min-height: 18px;
  min-width: 18px;
  cursor: pointer;
  &_with-modal {
    right: 44px;
  }
  &::v-deep {
    .dp__main {
      position: absolute;
      left: -108px;
      @include view-lg {
        position: relative;
        left: auto;
      }
    }
    .dp__input {
      width: 24px;
      height: 24px;
      padding: 0;
    }
    .dp__input_wrap {
      opacity: 0;
      width: 24px;
      height: 24px;
      overflow: hidden;
    }
    .dp__action_button {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

</style>
