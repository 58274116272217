const LANDING_QUESTIONS = [
  {
    question: 'Почему цены в страховых компаниях отличаются? Не должны ли они быть одинаковыми?',
    answer: 'С сентября 2022 страховые компании могут устанавливать базовые тарифы учитывая большое количество факторов влияющих на стоимость полисов. Базовые ставки на сайте страховщика могут отличаться в диапазоне от 1646 руб. до 7535 руб. Заполнив анкету на нашем сайте, можно удобно сравнить цены во всех страховых компаниях и выбрать наиболее оптимальную для вас.',
  },
  {
    question: 'Я получу подлинный полис? Как в этом убедиться?',
    answer: 'Оплата полиса происходит на сайте выбранной вами страховой компании. После покупки страховой полис ОСАГО сразу попадает в базу российского союза автостраховщиков (РСА). Именно через эту базу сотрудники ГИБДД проверяют подлинность полиса.',
  },
  {
    question: 'В каком виде я получу страховой полис ОСАГО?',
    answer: 'Сразу после покупки мы отправим страховой полис на вашу электронную почту и в смс. В случае проверки со стороны сотрудников ГИБДД достаточно показать электронную версию полиса с экрана вашего телефона. Для удобного хранения полиса можно воспользоваться нашим мобильным приложением.',
  },
  {
    question: 'Вы накручиваете цены на полис?',
    answer: 'Нет, закон исключает возможность накрутки цен на полис ОСАГО. Цены на нашем сайте точь в точь соответствуют ценам на сайте страховых компаний. Мы предоставляем удобный сервис сравнения цен в разных компаниях в одном месте.',
  },
  {
    question: 'Не опасно ли заполнять данные?',
    answer: 'Ваши данные нужны исключительно для расчета стоимости страхового полиса ОСАГО со стороны страховой компании.',
  },
  {
    question: 'Учитываете ли вы историю вождения (КБМ)?',
    answer: 'Да, мы учитываем историю вождения (КБМ) водителей при расчете стоимости страхового полиса ОСАГО. Благодаря этому цены на нашем сайте не отличаются от цен на сайтах страховых компаний. Если вы недавно поменяли права — обязательно укажите это в анкете, чтобы сохранить скидку за безаварийное вождение.',
  },
  {
    question: 'За прошлый год не было аварий, почему полис стал стоить дороже?',
    answer: 'Скорее всего ваша страховая компания увеличила базовый тариф за этот год в вашем регионе. Страховые компании могут так делать даже несколько раз в год. Благодаря нашему сервису — вы можете сравнить цены в разных страховых компаниях и выбрать другую, более оптимальную для вас.',
  },
  {
    question: 'Есть ли отличия между электронным и бумажным полисом?',
    answer: 'Нет, данные форматы страхового полиса ОСАГО равнозначны. Данное правило было введено еще в 2016 году. Сотрудники ГИБДД принимают электронный полис. Главными преимуществами электронного полиса ОСАГО являются — быстрое оформление и невозможность его потерять.',
  },
  {
    question: 'Если выбрать другую страховую компанию — скидка за безаварийное вождение сохранится?',
    answer: 'Да, вы можете выбрать абсолютно любую страховую компанию и скидка сохранится. История вашей безаварийности хранится в базе РСА.',
  },
  {
    question: 'На какой срок оформляется страховой полис ОСАГО?',
    answer: 'На нашем сайте вы можете оформить полис ОСАГО сроком на 1 год.',
  },
  {
    question: 'Зачем мне вообще полис ОСАГО?',
    answer: 'Страховой полис ОСАГО нужен для того, чтобы не платить из своего кармана в случае ДТП не по вашей вине. Страховая компания выплатит деньги потерпевшим на ремонт автомобиля и в случае, если пострадали люди. Существуют максимально установленные законом суммы выплат: 400 тыс.руб на ремонт автомобиля и 500 тыс. руб в случае, если пострадали люди.',
  },
  {
    question: 'Почему мне выгодно оформлять полис ОСАГО именно у вас?',
    answer: 'Мы предоставляем прозрачный и удобный сервис страхования ОСАГО, который позволяет сравнить цены в 20 страховых компаниях и оформить его за 5 минут.',
  },
];

export const FAQ_QUESTIONS = {
  'landingForDisabledEntryPageUsers': {
    sectionName: 'Частые вопросы при расчете стоимости страхового полиса ОСАГО онлайн',
    questions: [
      {
        question: 'Кого можем застраховать?',
        answer: 'Для физических лиц — автомобили категорий A,B,C,D, такси, мотоциклы. Водители только граждане РФ. Для юридических лиц — автомобили категории B. Водители только граждане РФ.',
      },
      ...LANDING_QUESTIONS,
    ]
  },
  'landing': {
    sectionName: 'Частые вопросы при расчете стоимости страхового полиса ОСАГО онлайн',
    questions: LANDING_QUESTIONS,
  },
  'carData': {
    sectionName: 'Частые вопросы при заполнении данных автомобиля для расчета стоимости полиса ОСАГО',
    questions: [
      {
        question: 'Не опасно ли заполнять данные?',
        answer: 'Ваши данные нужны исключительно для расчета стоимости страхового полиса ОСАГО со стороны страховой компании.'
      },
      {
        question: 'Где мне найти VIN номер автомобиля?',
        answer: 'VIN номер можно найти в верхней части свидетельства о регистрации транспортного средства. Обратите внимание, что заполнять его нужно только латинскими буквами и цифрами.'
      },
      {
        question: 'Что делать, если у меня нет VIN номера?',
        answer: 'В таком случае вы можете ввести номер кузова, который находится в свидетельстве о регистрации транспортного средства.'
      },
      {
        question: 'Где найти номер кузова?',
        answer: 'Номер кузова можно найти в свидетельстве о регистрации транспортного средства. Обратите внимание, что заполнять его нужно только латинскими буквами и цифрами.'
      },
      {
        question: 'Где найти серию и номер СТС?',
        answer: 'Серия и номер СТС находятся в нижней части документа.'
      },
      {
        question: 'Где найти дату выдачи СТС?',
        answer: 'Дата выдачи свидетельства о регистрации транспортного средства находится в самом документе.'
      },
      {
        question: 'Где найти серию и номер ПТС?',
        answer: 'Серия и номер указаны в верхней части самого документа.'
      },
      {
        question: 'Где найти номер эПТС',
        answer: 'Номер эПТС находится в верхней части самого документа. '
      },
      {
        question: 'У меня нет номера у автомобиля, могу ли я оформить без него?',
        answer: 'Да, в ГИБДД можно поставить авто на учёт с распечатанным полисом даже без вписанного номера. Номер можно будет вписать в полис после постановки на учет, либо при следующем продлении полиса ОСАГО'
      },
      {
        question: 'Можно ли оформить полис с отметкой о том, что автомобиль используется с прицепом?',
        answer: 'Да, для этого необходимо поставить галочку у соответствующей опции'
      },
      {
        question: 'Вы накручиваете цены на полис?',
        answer: 'Нет, закон исключает возможность накрутки цен на полис ОСАГО. Цены на нашем сайте точь в точь соответствуют ценам на сайте страховых компаний. Мы предоставляем удобный сервис сравнения цен в разных компаниях в одном месте.'
      },
      {
        question: 'Зачем мне вообще полис ОСАГО?',
        answer: 'Страховой полис ОСАГО нужен для того, чтобы не платить из своего кармана в случае ДТП не по вашей вине. Страховая компания выплатит деньги потерпевшим на ремонт автомобиля и в случае, если пострадали люди. Существуют максимально установленные законом суммы выплат: 400 тыс.руб на ремонт автомобиля и 500 тыс. руб в случае, если пострадали люди.'
      },
      {
        question: 'Почему мне выгодно оформлять полис ОСАГО именно у вас?',
        answer: 'Мы предоставляем прозрачный и удобный сервис страхования ОСАГО, который позволяет сравнить цены в 20 страховых компаниях и оформить его за 5 минут.'
      },
    ]
  },
  'drivers': {
    sectionName: 'Частые вопросы при заполнении данных водителей для расчета стоимости полиса ОСАГО',
    questions: [
      {
        question: 'Не опасно ли заполнять данные?',
        answer: 'Ваши данные нужны исключительно для расчета стоимости страхового полиса ОСАГО со стороны страховой компании.'
      },
      {
        question: 'Учитываете ли вы историю вождения (КБМ)?',
        answer: 'Да, мы учитываем историю вождения (КБМ) водителей при расчете стоимости страхового полиса ОСАГО. Благодаря этому цены на нашем сайте не отличаются от цен на сайтах страховых компаний. Если вы недавно поменяли права — обязательно укажите это в анкете, чтобы сохранить скидку за безаварийное вождение.'
      },
      {
        question: 'Могу ли я добавить другого водителя уже после оформления полиса ОСАГО?',
        answer: 'Да, но в таком случае придется обратиться в офис страховой компании,  в которой вы оформите полис. В случае, если коэффициент безаварийного вождения (КБМ) нового водителя окажется хуже — нужно будет явиться в офис страховой компании. А если коэффициент окажется лучше, то изменения внесут дистанционно и вы получите обновленный документ на вашу электронную почту.'
      },
      {
        question: 'Что такое «Страховка без ограничений»?',
        answer: 'С этой опцией авто сможет управлять любой водитель, но цена за полис ОСАГО будет значительно выше, а накопленная скидка за безаварийное вождение не применится (КБМ)'
      },
      {
        question: 'Где найти серию и номер водительского удостоверения?',
        answer: 'Серия и номер водительского удостоверения находятся на его обратной стороне.'
      },
      {
        question: 'Лучше указывать полную дату выдачи первых прав или только год?',
        answer: 'Если вы помните точную дату выдачи первых прав — укажите ее, так как суммарный стаж вождения получится больше, а цена за страховой полис ОСАГО ниже.'
      },
      {
        question: 'Вы накручиваете цены на полис?',
        answer: 'Нет, закон исключает возможность накрутки цен на полис ОСАГО. Цены на нашем сайте точь в точь соответствуют ценам на сайте страховых компаний. Мы предоставляем удобный сервис сравнения цен в разных компаниях в одном месте. '
      },
      {
        question: 'Зачем мне вообще полис ОСАГО?',
        answer: 'Страховой полис ОСАГО нужен для того, чтобы не платить из своего кармана в случае ДТП не по вашей вине. Страховая компания выплатит деньги потерпевшим на ремонт автомобиля и в случае, если пострадали люди. Существуют максимально установленные законом суммы выплат: 400 тыс.руб на ремонт автомобиля и 500 тыс. руб в случае,  если пострадали люди.'
      },
      {
        question: 'Почему мне выгодно оформлять полис ОСАГО именно у вас?',
        answer: 'Мы предоставляем прозрачный и удобный сервис страхования ОСАГО,  который позволяет сравнить цены в 20 страховых компаниях и оформить его за 5 минут.'
      },
    ]
  },
  'owner': {
    sectionName: 'Частые вопросы при заполнении данных владельца и страхователя для расчета стоимости полиса ОСАГО',
    questions: [
      {
        question: 'Не опасно ли заполнять данные?',
        answer: 'Ваши данные нужны исключительно для расчета стоимости страхового полиса ОСАГО со стороны страховой компании.'
      },
      {
        question: 'Чем отличается владелец от страхователя?',
        answer: 'Страхователь — это человек, который оформляет страховой полис и в будущем получает доступ к внесению изменений, в том числе к расторжению полиса. Владелец — законный владелец машины. Он вписан в СТС и ПТС и вправе распоряжаться автомобилем по личному усмотрению. Владелец может быть страхователем.'
      },
      {
        question: 'Как заполнить адрес регистрации?',
        answer: 'Начните заполнять поле с населенного пункта. Далее введите улицу, дом и квартиру. Мы покажем список адресов, важно выбрать ваш адрес из этого списка, используя кнопку «Выбрать». В случае,  если вы проживаете в частном доме — поставьте галочку «Номер квартиры отсутствует»'
      },
      {
        question: 'Зачем указывать номер телефона?',
        answer: 'Нужен для доступа в личный кабинет страховой компании, если вам потребуется внести изменения в полис или оформить возврат.'
      },
      {
        question: 'Зачем указывать электронную почту?',
        answer: 'Именно на эту почту мы пришлем страховой полис ОСАГО, поэтому важно указывать достоверные данные.'
      },
      {
        question: 'Вы накручиваете цены на полис?',
        answer: 'Нет, закон исключает возможность накрутки цен на полис ОСАГО. Цены на нашем сайте точь в точь соответствуют ценам на сайте страховых компаний. Мы предоставляем удобный сервис сравнения цен в разных компаниях в одном месте. '
      },
      {
        question: 'Зачем мне вообще полис ОСАГО?',
        answer: 'Страховой полис ОСАГО нужен для того, чтобы не платить из своего кармана в случае ДТП не по вашей вине. Страховая компания выплатит деньги потерпевшим на ремонт автомобиля и в случае, если пострадали люди. Существуют максимально установленные законом суммы выплат: 400 тыс.руб на ремонт автомобиля и 500 тыс. руб в случае,  если пострадали люди.'
      },
      {
        question: 'Почему мне выгодно оформлять полис ОСАГО именно у вас?',
        answer: 'Мы предоставляем прозрачный и удобный сервис страхования ОСАГО,  который позволяет сравнить цены в 20 страховых компаниях и оформить его за 5 минут.'
      },
    ]
  },
  'calculations': {
    sectionName: 'Частые вопросы при расчете стоимости страхового полиса ОСАГО онлайн',
    questions: [
      {
        question: 'Почему цены в страховых компаниях отличаются? Не должны ли они быть одинаковыми?',
        answer: 'С сентября 2022 страховые компании могут устанавливать базовые тарифы учитывая большое количество факторов влияющих на стоимость полисов. Базовые ставки на сайте страховщика могут отличаться в диапазоне от 1646 руб. до 7535 руб. Заполнив анкету на нашем сайте, можно удобно сравнить цены во всех страховых компаниях и выбрать наиболее оптимальную для вас.',
      },
      {
        question: 'Вы накручиваете цены на полис?',
        answer: 'Нет, закон исключает возможность накрутки цен на полис ОСАГО. Цены на нашем сайте точь в точь соответствуют ценам на сайте страховых компаний. Мы предоставляем удобный сервис сравнения цен в разных компаниях в одном месте. ',
      },
      {
        question: 'Я получу подлинный полис? Как в этом убедиться?',
        answer: 'Оплата полиса происходит на сайте выбранной вами страховой компании. После покупки страховой полис ОСАГО сразу попадает в базу российского союза автостраховщиков (РСА). Именно через эту базу сотрудники ГИБДД проверяют подлинность полиса.',
      },
      {
        question: 'В каком виде я получу страховой полис ОСАГО?',
        answer: 'Сразу после покупки мы отправим страховой полис на вашу электронную почту и в смс. В случае проверки со стороны сотрудников ГИБДД достаточно показать электронную версию полиса с экрана вашего телефона. Для удобного хранения полиса можно воспользоваться нашим мобильным приложением.',
      },
      {
        question: 'За прошлый год не было аварий, почему полис стал стоить дороже?',
        answer: 'Скорее всего ваша страховая компания увеличила базовый тариф за этот год в вашем регионе. Страховые компании могут так делать даже несколько раз в год. Благодаря нашему сервису — вы можете сравнить цены в разных страховых компаниях и выбрать другую, более оптимальную для вас.',
      },
      {
        question: 'Есть ли отличия между электронным и бумажным полисом?',
        answer: 'Нет, данные форматы страхового полиса ОСАГО равнозначны. Данное правило было введено еще в 2016 году. Сотрудники ГИБДД принимают электронный полис. Главными преимуществами электронного полиса ОСАГО являются — быстрое оформление и невозможность его потерять.',
      },
      {
        question: 'Почему мне выгодно оформлять полис ОСАГО именно у вас?',
        answer: 'Мы предоставляем прозрачный и удобный сервис страхования ОСАГО,  который позволяет сравнить цены в 20 страховых компаниях и оформить его за 5 минут.',
      },
    ],
  },
  'offers': {
    sectionName: 'Частые вопросы при расчете стоимости страхового полиса ОСАГО онлайн',
    questions: [
      {
        question: 'Я получу подлинный полис? Как в этом убедиться?',
        answer: 'Оплата полиса происходит на сайте выбранной вами страховой компании. После покупки страховой полис ОСАГО сразу попадает в базу российского союза автостраховщиков (РСА). Именно через эту базу сотрудники ГИБДД проверяют подлинность полиса.',
      },
      {
        question: 'В каком виде я получу страховой полис ОСАГО?',
        answer: 'Сразу после покупки мы отправим страховой полис на вашу электронную почту и в смс. В случае проверки со стороны сотрудников ГИБДД достаточно показать электронную версию полиса с экрана вашего телефона. Для удобного хранения полиса можно воспользоваться нашим мобильным приложением.',
      },
      {
        question: 'За прошлый год не было аварий, почему полис стал стоить дороже?',
        answer: 'Скорее всего ваша страховая компания увеличила базовый тариф за этот год в вашем регионе. Страховые компании могут так делать даже несколько раз в год. Благодаря нашему сервису — вы можете сравнить цены в разных страховых компаниях и выбрать другую, более оптимальную для вас.',
      },
      {
        question: 'Есть ли отличия между электронным и бумажным полисом?',
        answer: 'Нет, данные форматы страхового полиса ОСАГО равнозначны. Данное правило было введено еще в 2016 году. Сотрудники ГИБДД принимают электронный полис. Главными преимуществами электронного полиса ОСАГО являются — быстрое оформление и невозможность его потерять.',
      },
      {
        question: 'Почему мне выгодно оформлять полис ОСАГО именно у вас?',
        answer: 'Мы предоставляем прозрачный и удобный сервис страхования ОСАГО,  который позволяет сравнить цены в 20 страховых компаниях и оформить его за 5 минут.',
      },
      {
        question: 'На какой срок оформляется страховой полис ОСАГО?',
        answer: 'На нашем сайте вы можете оформить полис ОСАГО сроком на 1 год.',
      },
    ]
  },
  'failedOffer': {
    sectionName: 'Частые вопросы при расчете стоимости страхового полиса ОСАГО онлайн',
    questions: [
      {
        question: 'Почему предложение недоступно, что делать?',
        answer: 'Пожалуйста, проверьте заполненные данные в анкете, всё ли было указано корректно. В случае, если данные указаны верно, оформить предложение в данной страховой, к сожалению, не получится, но вы можете выбрать другую страховую компанию.',
      },
      {
        question: 'Я получу подлинный полис? Как в этом убедиться?',
        answer: 'Оплата полиса происходит на сайте выбранной вами страховой компании. После покупки страховой полис ОСАГО сразу попадает в базу российского союза автостраховщиков (РСА). Именно через эту базу сотрудники ГИБДД проверяют подлинность полиса.',
      },
      {
        question: 'В каком виде я получу страховой полис ОСАГО?',
        answer: 'Сразу после покупки мы отправим страховой полис на вашу электронную почту и в смс. В случае проверки со стороны сотрудников ГИБДД достаточно показать электронную версию полиса с экрана вашего телефона. Для удобного хранения полиса можно воспользоваться нашим мобильным приложением.',
      },
      {
        question: 'За прошлый год не было аварий, почему полис стал стоить дороже?',
        answer: 'Скорее всего ваша страховая компания увеличила базовый тариф за этот год в вашем регионе. Страховые компании могут так делать даже несколько раз в год. Благодаря нашему сервису — вы можете сравнить цены в разных страховых компаниях и выбрать другую, более оптимальную для вас.',
      },
      {
        question: 'Есть ли отличия между электронным и бумажным полисом?',
        answer: 'Нет, данные форматы страхового полиса ОСАГО равнозначны. Данное правило было введено еще в 2016 году. Сотрудники ГИБДД принимают электронный полис. Главными преимуществами электронного полиса ОСАГО являются — быстрое оформление и невозможность его потерять.',
      },
      {
        question: 'Почему мне выгодно оформлять полис ОСАГО именно у вас?',
        answer: 'Мы предоставляем прозрачный и удобный сервис страхования ОСАГО,  который позволяет сравнить цены в 20 страховых компаниях и оформить его за 5 минут.',
      },
      {
        question: 'На какой срок оформляется страховой полис ОСАГО?',
        answer: 'На нашем сайте вы можете оформить полис ОСАГО сроком на 1 год.',
      },
    ]
  }
}
