<template>
  <section
    :data-field="id"
  >
    <Field
      ref="validationField"
      :name="id"
      :rules="rules"
      :value="modelValue"
    >
      <div
        class="form-switch"
        :class="{
          'form-switch_loading': loading,
          'form-switch_left-switcher': leftSwitcher,
          'form-switch_bold-label': boldLabel,
        }"
        :data-field="id"
        @focusin="onFocus"
      >
        <input
          :id="id"
          class="form-switch-input"
          type="checkbox"
          :checked="modelValue"
          @change="onChange"
        >
        <label
          :for="id"
          class="form-switch-label"
        >
          <template v-if="label">
            {{ label }}
          </template>
          <template v-else>
            <slot />
          </template>
          <span class="form-switch-control"></span>
        </label>
      </div>
      <div v-if="serverValidation && !wasFocused" class="invalid-feedback--vue js_invalid_feedback">{{serverValidation && serverValidation[0] || serverValidation}}</div>
      <ErrorMessage
        v-if="!hideErrors"
        as="div"
        :name="id"
        class="invalid-feedback--vue js_invalid_feedback"
      />
    </Field>
  </section>
</template>

<script>
import {ErrorMessage, Field, useField} from "vee-validate";

export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    value: {
      require: true,
      type: Boolean,
      default: false
    },
    hideErrors: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      require: true,
      default: null,
    },
    label: {
      type: String,
      require: false,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    leftSwitcher: {
      type: Boolean,
      default: false,
    },
    boldLabel: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean
    },
    serverValidation: {
      type: Array,
      default: null,
    },

  },
  emits: [
    'change',
    'update:modelValue'
  ],
  data() {
    return {
      wasFocused: false,
      errorMessage: null,
    }
  },
  mounted() {
    this.errorMessage = useField(this.id).errorMessage;
  },
  methods: {
    onFocus() {
      this.wasFocused = true;
    },
    onChange(event) {
      let checked = event.target.checked;
      this.$emit('update:modelValue', checked);
      this.$emit('change', checked);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.form-switch {
  .form-switch-input {
    display: none;
    &:checked + .form-switch-label {
      .form-switch-control {
        background: $color-main;
        &:after {
          transition: none;
          left: calc(100% - 2px - 27px);
        }
      }
    }
  }
  .form-switch-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
    cursor: pointer;
    .form-switch-control {
      position: relative;
      display: block;
      width: 51px;
      min-width: 51px;
      height: 31px;
      background: $color-gray-white-200;
      border-radius: 16px;
      transition: background 0.3s;
      &:after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 100%;
        width: 27px;
        height: 27px;
        background: $color-white;
        top: 2px;
        left: 2px;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06);
        transition: left 0.3s;
      }
    }
  }
  &_loading {
    .form-switch-label {
      .form-switch-control {
        background: $color-gray-white-200 !important;
        &:after {
          background: url('~@/assets/img/loader.svg') center center / contain;
          box-shadow: none;
          left: 50% !important;
          transform: translateX(-50%);
        }
      }
    }
  }
  &_bold-label {
    .form-switch-label {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.24px;
    }
  }
  &_left-switcher {
    .form-switch-label {
      justify-content: flex-start;
      gap: 12px;
      .form-switch-control {
        order: -1;
      }
    }
  }
}
</style>
